import API from './api'
import {isArray, sortBy} from 'lodash'
import {OptionListPagination} from "@biron-data/react-bqconf"
import {FilterOption, View, DatamodelDto} from "@biron-data/bqconf"

export const getViewsAndDatamodels = (environmentId: number) => new Promise<{
  views: View[],
  datamodels: DatamodelDto[]
}>((resolve, reject) => {
  API.instanceData.get<void, {
    views: View[],
    datamodels: DatamodelDto[]
  } | undefined>(`/metamodel/${environmentId}`)
    .then(result => {
      if (!result || !result.views) {
        resolve({
          views: [],
          datamodels: []
        })
        return
      }
      result.views.sort((a, b) => a.alias.toLocaleLowerCase().localeCompare(b.alias.toLocaleLowerCase()))
      resolve({
        views: result.views.map(view => {
          view.dimensions.sort((a, b) => a.alias.toLocaleLowerCase().localeCompare(b.alias.toLocaleLowerCase()))
          view.metrics.sort((a, b) => a.alias.toLocaleLowerCase().localeCompare(b.alias.toLocaleLowerCase()))
          return view
        }),
        datamodels: result.datamodels
      })
    }, reject)
})

export interface ConsolidatedEntryValue {
  label: string,
  value: string,
}

export const loadDictionaryEntries = (environmentId: number | undefined, dimensionCode: string, datamodelCodes: string[], search?: string, pagination?: OptionListPagination): Promise<Omit<FilterOption, "type">[]> => new Promise((resolve, reject) => {
  if (environmentId) {
    API.instanceData.get<void, string[]>(`/metamodel/${environmentId}/dimension/${dimensionCode}/values`, {
        params: {
          fulltextFilter: search ?? '',
          offset: pagination?.offset,
          pageSize: pagination?.pageSize,
          datamodelCodes
        },
      paramsSerializer: (params) => {
        return Object.entries(params).flatMap(([key, v]) => {
          if (isArray(v)) {
            return v.map(el => `${key}=${el}`)
          }
          return `${key}=${v}`
        }).join('&')
      },
      })
      .then(options => {
        sortBy(options, entry => entry && entry.toLocaleLowerCase())
        resolve(options.map((option) => ({
          label: option,
          value: option,
          selected: false,
        })))
      }, reject)
  } else {
    reject(new Error('no environmentId'))
  }
})
