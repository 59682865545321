import React, {ReactNode, useCallback, useMemo} from 'react'
import DashboardTitle from './DashboardTitle'
import DashboardFilterableSettings from '././filterableSettings/DashboardFilterableSettings'
import styled from "styled-components"
import {
  ConsolidatedDimension,
  DatamodelDto,
  DataSelection,
  DimensionFilterDtoDetail,
  MetaModel
} from "@biron-data/bqconf"
import DashboardAddWidgetButton from "components/dashboard/header/addWidgetButton/DashboardAddWidgetButton"
import FormTriggerable from "components/forms/Form.Triggerable"
import {Button, Layout} from "antd"
import Language from "language"
import {CogIcon} from "@heroicons/react/outline"
import {IconContainer} from "@biron-data/react-components"
import {FormKeys} from "components/forms/Form.types"
import {UseGetChartLayoutForAddType} from "hooks/useGetLayoutForAddChart"
import {ChartDtoDetail} from "types/charts"
import {useHandleChartAdd} from "hooks/useHandleAddChart"
import useDispatch from "hooks/useDispatch"
import {useHandleAddChartFromClipboard} from "hooks/useHandleAddChartFromClipboard"
import {DashboardWithoutChartsTypes, NormalizedPersonalDashboard, NormalizedWorkspaceDashboard} from "schemas/dashboard"
import {useMediaDesktop} from "@biron-data/react-hooks"
import {DashboardTypes} from "types/dashboards"
import {useNavigate} from "react-router-dom"
import {useDuplicateToPersonalButton} from "components/dashboard/header/DashboardHeader.hooks"
import {PermissionsEnum} from "redux/models/appEnvironment"
import {useSelector} from "react-redux"
import {getPermission} from "redux/appEnvironment.selector"

interface Props {
  environmentId: number
  datamodel: DatamodelDto
  metaModel: MetaModel
  availableDimensions: ConsolidatedDimension[]
  dashboardTitlePrefix?: ReactNode
  dashboard: DashboardWithoutChartsTypes
  currentSelection: DataSelection,
  editMode: boolean,
  canAccessDimensionValues: boolean
  onConfChange: (data: Omit<NormalizedWorkspaceDashboard, 'charts'> | Omit<NormalizedPersonalDashboard, 'charts'>) => Promise<void>
  filters: DimensionFilterDtoDetail[]
  onTemporaryFiltersChange: (filters: DimensionFilterDtoDetail[]) => void
  copiedChart: ChartDtoDetail
  scrollToChart?: (chartId: number) => void
  getChartLayoutForAdd: ReturnType<UseGetChartLayoutForAddType>
  onChartAdded: (id: number) => void
  canEditDashboardProperties: boolean
}

const DashboardHeader: (props: Props) => JSX.Element = ({
                                                          environmentId,
                                                          datamodel,
                                                          metaModel,
                                                          availableDimensions,
                                                          dashboardTitlePrefix,
                                                          dashboard,
                                                          currentSelection,
                                                          editMode,
                                                          canAccessDimensionValues,
                                                          onConfChange,
                                                          filters,
                                                          onTemporaryFiltersChange,
                                                          copiedChart,
                                                          scrollToChart,
                                                          getChartLayoutForAdd,
                                                          canEditDashboardProperties,
                                                          onChartAdded,
                                                        }) => {
  const dispatch = useDispatch()
  const isMediaDesktop = useMediaDesktop()
  const navigate = useNavigate()
  const withDashboardP = useSelector(getPermission)(PermissionsEnum.withDashboardP)

  const confForm = useMemo(() => <FormTriggerable key={"conf"} {...{
      isDashboard: true,
      trigger: <FlexButton type="text"><IconContainer margin={10}><CogIcon/></IconContainer>{Language.get('dashboard-edit')}</FlexButton>,
      formType: {
        type: FormKeys.DASHBOARD_CONF,
      },
      value: dashboard.type === DashboardTypes.workspace ? {
        id: dashboard.id,
        title: dashboard.title,
        type: dashboard.type,
        period: dashboard.period,
        filters: dashboard.filters,
        protected: dashboard.protected,
        filtersLocked: dashboard.filtersLocked,
        relativeId: dashboard.relativeId,
        updatedAt: dashboard.updatedAt,
        uri: dashboard.uri,
        datamodelCode: dashboard.datamodelCode,
      } : {
        id: dashboard.id,
        title: dashboard.title,
        type: dashboard.type,
        period: dashboard.period,
        filters: dashboard.filters,
        updatedAt: dashboard.updatedAt,
        format: dashboard.format,
        uid: dashboard.uid,
        folder: dashboard.folder,
        datamodelCode: dashboard.datamodelCode,
      },
      metaModel,
      onChange: onConfChange,
      name: dashboard.title,
      dashboardId: dashboard.id,
      environmentId,
      title: Language.get('navigation-tools-edit-dashboard'),
    }}/>,
    [dashboard, metaModel, onConfChange, environmentId],
  )

  const handleChartAdd = useHandleChartAdd(
    dispatch.currentDashboard.chartAdd,
    getChartLayoutForAdd,
    onChartAdded,
  )

  const addChart = useCallback(async (data: Partial<ChartDtoDetail>) => {
    await handleChartAdd(data)
  }, [handleChartAdd])

  const handleChartAddFromClipboard = useHandleAddChartFromClipboard(
    copiedChart,
    handleChartAdd,
  )
  const getDuplicateToPersonalButton = useDuplicateToPersonalButton(
    environmentId,
    navigate,
    dispatch.personalDashboards.cloneDashboard,
  )

  return <StyledContainer>
    <DashboardTitle {...{
      dashboardTitlePrefix,
      dashboard,
      canEditDashboardProperties,
      editMode,
      onConfChange
    }} />
    {withDashboardP && dashboard.type === DashboardTypes.workspace && getDuplicateToPersonalButton(dashboard)}
    {editMode && isMediaDesktop && canEditDashboardProperties && confForm}
    {editMode && <DashboardAddWidgetButton {...{
      GASource: "dashboardHeader",
      environmentId,
      datamodel,
      metaModel,
      dashboard,
      dashboardSelection: currentSelection,
      copiedChart,
      scrollToChart,
      handleChartAdd: addChart,
      handleChartAddFromClipboard,
    }}/>}
    <StyledDashboardFilterableSettings {...{
      filters,
      availableDimensions,
      currentSelection,
      canAccessDimensionValues,
      onTemporaryFiltersChange,
    }}/>
  </StyledContainer>
}

export default DashboardHeader

const StyledContainer = styled(Layout.Header)`
  display: flex;
  flex-direction: row;
  gap: 1%;
  
  // override antd default top padding, because we are using two header
    padding-top: 0;
  
  border-bottom: 1px solid var(--light-grey-2);
`

const StyledDashboardFilterableSettings = styled(DashboardFilterableSettings)`
  height: 40px;
  max-height: 40px;
`

const FlexButton = styled(Button)`
  display: flex;
  &:hover {
   color:var(--primary);
  }
`
