import {IconContainer, TableLink, TooltipPopover} from "@biron-data/react-components"
import React, {memo, PropsWithChildren, useCallback, useEffect, useRef, useState} from "react"
import {useSelector} from "react-redux"
import {getPermission} from "redux/appEnvironment.selector"
import styled from "styled-components"
import {Input, Tooltip} from "antd"
import {PencilAltIcon} from "@heroicons/react/outline"
import Language from "language"
import {PermissionsEnum} from "redux/models/appEnvironment"

interface EditableTitleProps extends PropsWithChildren {
  title?: string,
  editable?: boolean
  onConfirm: (newTitle: string) => Promise<any>
  iconColor?: string
  triggerGAEvent: () => void
  fontSize?: number
  iconSize?: number
  width?: string
  link?: string
}

// eslint-disable-next-line react/display-name
const EditableTitle = memo<EditableTitleProps>(({
                                              title: originalTitle = "",
                                              children,
                                              editable,
                                              onConfirm,
                                              iconColor = "var(--light-grey)",
                                              triggerGAEvent,
                                              fontSize = 20,
                                              iconSize = 19,
                                              width = "100%",
                                              link
                                            }) => {
  const canEdit = useSelector(getPermission)(PermissionsEnum.workspaceCanEditContent)
  const [isEditingTitle, setIsEditingTitle] = useState(false)
  const [title, setTitle] = useState(originalTitle)
  const titleInput = useRef<any>()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setTitle(originalTitle)
  }, [originalTitle])

  useEffect(() => {
    if (isEditingTitle) {
      titleInput.current?.focus()
    }
  }, [isEditingTitle])

  const onApply = useCallback(() => {
    setIsEditingTitle(false)
    if (!loading && originalTitle !== title) {
      triggerGAEvent()
      setLoading(true)
      onConfirm(title).then(() => {
        setLoading(false)
      }).catch(() => {
        setLoading(false)
      })
    }
  }, [loading, onConfirm, originalTitle, title, triggerGAEvent])
  const handleFocus = useCallback((event: any) => event.target.select(), [])
  return <FlexContainer width={width}>
    <ActionContainer className="title-actions">
      {canEdit && editable && !isEditingTitle && <TooltipPopover size={'small'} content={Language.get('chart-edit-title')}>
        <div>
          <IconContainer margin={2} color={iconColor} size={iconSize} onClick={() => {
        setIsEditingTitle(old => !old)
          }} clickable={true}><PencilAltIcon/></IconContainer>
        </div>
      </TooltipPopover>}
      {!isEditingTitle && children}
    </ActionContainer>
    <CustomInput ref={titleInput} $fontsize={fontSize} className="title-input" onFocus={handleFocus} $displayed={isEditingTitle ? 1 : 0} {...{
      autoSize: {
        minRows: 1,
        maxRows: 6,
      },
      value: title,
      onChange: (newValue) => {
        setTitle(newValue.target.value)
      },
      onBlur: () => {
        onApply()
      },
      onKeyDown: (event) => {
        if (event.keyCode === 13) {
          onApply()
        }
      },
      disabled: loading,
    }}/>
     {
      !isEditingTitle && <Tooltip {...{
        placement: "top",
        title,
        mouseEnterDelay: 1,
        overlayStyle: {maxWidth: 400},
      }}>
        <TitleSpan $fontsize={fontSize} translate="no">{link ? <TableLink link={link}>{title}</TableLink> : title}</TitleSpan>
      </Tooltip>
     }
  </FlexContainer>
})

export default EditableTitle

const ActionContainer = styled.div`
    display: flex;
    margin-right: 12px;
`

const FlexContainer = styled.div<{width: string}>`
    display: flex;
    position: relative;
    width: ${({width}) => width};
    height: 34px;
`

const CustomInput = styled(Input.TextArea)<{ $displayed: number, $fontsize: number }>`
    &&& {
        ${({$displayed}) => $displayed ? "display: block;" : "display: none;"}
        background-color: white;
        font-family: Poppins;
        font-size: ${({$fontsize}) => $fontsize}px;
        font-weight: 400;
        line-height: 14px !important;
        letter-spacing: 0em;
        text-align: left;
        color: #425FF0;
        position: absolute;
        width: 100%;
        z-index: 1;
        height: 100% !important;
        min-height: 100% !important;
        max-height: 100% !important;
    }
`

const TitleSpan = styled.span<{ $fontsize: number }>`
    text-align: left;
    -webkit-flex-grow: 1;
    flex-grow: 1;
    -webkit-flex-shrink: 1;
    flex-shrink: 1;
    -webkit-flex-basis: auto;
    flex-basis: auto;
    -webkit-align-self: auto;
    align-self: auto;
    font-size: ${({$fontsize}) => $fontsize}px;
    line-height: 32px;
    height: 32px;
    overflow: hidden;
    text-overflow: ellipsis;`

