import React, {FunctionComponent, useMemo, useRef, useState} from "react"
import {Row, TableColumnProps} from 'antd'
import Language from "language"
import {useLocation} from "react-router-dom"
import styled from "styled-components"
import {ChartSource, ObjectType, UsageTypes} from 'components/dataSourceDoc/DataSource.types'
import {Search} from "components/search/Search"
import {TableLink, ColorizedTag, BironTable} from "@biron-data/react-components"
import {
  useGetSourceColor,
  useSearchOnValues,
  useStats,
  useTextWithHighLight,
} from "components/dataSourceDoc/DataSource.hooks"
import {getDashboardLink, getUsageLink} from "components/dataSourceDoc/DataSource.utils"
import {TableContainer} from "components/dataSourceDoc/DataSource.style"
import {useResizeDetector} from "@biron-data/react-hooks"

interface DetailsProps {
  usages: UsageTypes[]
  displaySourceColumn?: boolean
  sources: ChartSource[]
}

export const DetailsUsages: FunctionComponent<DetailsProps> = ({
                                                           usages,
                                                          displaySourceColumn = false,
                                                          sources,
                                                         }) => {
  const location = useLocation()
  const getSourceColor = useGetSourceColor()
  const [size, setSize] = useState({
    width: 0,
  })
  const [search, setSearch] = useState("")
  const [consolidatedData, setConsolidatedData] = useState(usages)

  useSearchOnValues(search, [['dashboard', 'title'], ['object', 'title']], usages, setConsolidatedData)

  const getTextWithHighLight = useTextWithHighLight(search)

  const columnLayout = useMemo(() => {
    return displaySourceColumn ? [12,
      21,
      21,
      31,
      15] : [12,
      23,
      23,
      42]
  }, [displaySourceColumn])

  const usageColumns: TableColumnProps<UsageTypes>[] = useMemo(() => {

    return [
      {
        title: Language.get(`admin.dataDoc.columns.object`),
        dataIndex: ['object', 'type'],
        filters: Object.entries(ObjectType).map(([, type]) => ({
          text: Language.get("admin.dataDoc.details.object", type),
          value: type,
        })),
        onFilter: (value, record) => {
          return record.object.type === value
        },
        width: `${columnLayout[0]}`,
        render: (text: ObjectType) => Language.get("admin.dataDoc.details.object", text),
      },
      {
        title: Language.get(`admin.dataDoc.columns.menuName`),
        dataIndex: ['menu', 'title'],
        render: (text?: string) => getTextWithHighLight(text ?? "-"),
        width: `${columnLayout[1]}`,
      },
      {
        title: Language.get(`admin.dataDoc.columns.dashboardName`),
        dataIndex: ['dashboard', 'title'],
        render: function render(text: string | undefined, record) {
          return <TableLink link={getDashboardLink(record, location)} isTargetBlank={true}>{getTextWithHighLight(text ?? "-")}</TableLink>
        },
        width: `${columnLayout[2]}`,
      },
      {
        title: Language.get(`admin.dataDoc.columns.objectName`),
        dataIndex: ['object', 'title'],
        render: function render(text: string, record) {
          return <TableLink link={getUsageLink(record, location)} isTargetBlank={true}>{getTextWithHighLight(text)}</TableLink>
        },
        defaultSortOrder: 'ascend',
        width: `${columnLayout[3]}`,
      },
      ...(displaySourceColumn ? [{
        title: Language.get(`admin.dataDoc.columns.usage`),
        dataIndex: 'sources',
        className: 'col-with-centered-content',
        render: (_: string, record) => <ColorizedTag
          text={Language.get("admin.dataDoc.details.sources", record.source)}
          color={getSourceColor(record.source)}
        />,
        filters: Object.entries(ChartSource).map(([, source]) => ({
          text: Language.get("admin.dataDoc.details.sources", source),
          value: source,
        })),
        onFilter: (value, record) => {
          return record.source === value.toString()
        },
        width: `${columnLayout[4]}`,
      } as TableColumnProps<UsageTypes>] : []),
    ]
  }, [columnLayout, displaySourceColumn, getSourceColor, getTextWithHighLight, location])

  const stats = useStats(usages, sources)

  const container = useRef<any>()
  useResizeDetector(container, size, (newWidth) => {
    setSize({
      width: newWidth,
    })
  })

  const onSearch = (val: string) => {
    setSearch(val)
  }

  return <DetailsContainer>
    <StatsContainer>
      {stats}
    </StatsContainer>
    <StyledRow>
        <TableContainer>
          <Search
          width={95}
          onChange={onSearch}/>
          <BironTable
            columns={usageColumns}
            dataSource={consolidatedData}
            rowHeight={34}
          />
        </TableContainer>
    </StyledRow>
  </DetailsContainer>
}

const DetailsContainer = styled.div`
  height: 100%;
  min-height: 0;
  display: flex;
  flex-direction: column;
`

const StatsContainer = styled.div`
  display: flex;
  gap: 80px;
`

const StyledRow = styled(Row)`
  min-height: 0;
  height: 100%;
`