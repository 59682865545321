import React, {useMemo} from "react";
import {ACES, EnvironmentRoles, UAC} from "../components/admin/user/UserManager.types";

export enum UserPosition {
  ADMIN = "ADMIN",
  SUPERADMIN = "SUPERADMIN",
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

interface User {
  aces: ACES[]
  bironUser: boolean
  email: string
  firstName: string
  fullName: string
  id: number
  lastName: string
  locale: string
  powerUser: boolean
}

export interface UACWithAces {
  user: User & {
    position: UserPosition;
  }
  active: boolean
  id: number
}

const getUserPosition = (uac: UAC, aces: ACES[]) => {
  if (!uac.active) {
    return UserPosition.INACTIVE
  }

  if (aces.flatMap(a => a.roles).find((role) => role === EnvironmentRoles.ENVIRONMENT_SUPERADMIN)) {
    return UserPosition.SUPERADMIN
  }
  if (aces.flatMap(a => a.roles).find((role) => role === EnvironmentRoles.ENVIRONMENT_ADMIN)) {
    return UserPosition.ADMIN
  }

  return UserPosition.ACTIVE
}

const useUACWithAces = (uacs: UAC[], acesByUserId: { [p: string]: ACES[] }): UACWithAces[] => useMemo(() => uacs.map(({user, ...uac}) => {
  const userAces = acesByUserId[String(user.id)] ?? []
  return {
    ...uac,
      user: {
    ...user,
        position: getUserPosition({user, ...uac}, userAces),
        aces: userAces,
    },
    }
  },
), [uacs, acesByUserId])

export default useUACWithAces
