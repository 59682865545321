import API from "services/api"
import {PersonalDashboardDtoForm, PersonalDashboardsList, RawPersonalDashboardDto} from "types/dashboards"
import {DashboardDto} from "components/workspace/WorkspaceBridge.SiderContainer"

export const getPersonalDashboards = (environmentId: number, datamodelCode: string) => API.instanceData.get<void, PersonalDashboardsList>(`/dashboard/p`, {
  params: {
    environmentId,
    datamodelCode
  },
})

export const getPersonalDashboardByUid = (uid: string, environmentId: number) => API.instanceData.get<void, RawPersonalDashboardDto>(`/dashboard/p/byUid/${uid}`, {
  params: {
    environmentId,
  },
})

export const createPersonalDashboards = (personalDashboard: PersonalDashboardDtoForm, environmentId: number) => API.instanceData.post<void, RawPersonalDashboardDto>(`/dashboard/p`, personalDashboard, {
  params: {
    environmentId,
  },
})

export const updatePersonalDashboard = (personalDashboardId: number, personalDashboard: Omit<RawPersonalDashboardDto, "charts">) => API.instanceData.put<void, RawPersonalDashboardDto>(`/dashboard/p/${personalDashboardId}`, personalDashboard)

export const deletePersonalDashboard = (dashboardId: number) => API.instanceData.delete<void, RawPersonalDashboardDto>(`/dashboard/p/${dashboardId}`)

export const clonePersonalDashboard = (dashboardId: number) =>
  API.instanceData.post<void, RawPersonalDashboardDto>(`/dashboard/p/clone`, null, {
    params: {
      refId: dashboardId,
    },
  })