/* eslint-disable max-lines */
import React, {forwardRef, MutableRefObject, useCallback, useEffect, useMemo} from 'react'
import {ConsolidatedSerie, ELineChartProps, OriginalSerie, SerieType} from "components/charts/line/LineChart.types"
import EChartContainer from "components/charts/Chart.Container"
import ChartBase, {BaseChartRef} from "components/charts/Chart.Base"
import {
  computeTotalAcrossSeries,
  DISTANCE_BETWEEN_CHART_TOP_AND_LEGEND,
  preventInfinite,
} from "components/charts/Chart.constants"
import {
  computeOrderByWithOption, getSerieMinMax,
  MetricDataTreeWithSeries,
  overlaidAxis,
  overlaidBarSeries,
  useAxisFormat,
  useAxisLabel,
  useBarChartColors,
  useBarChartRawParsedMetrics,
  useColors,
  useDefaultSerieFormatter,
  useFillBlanks,
  useGetSeriesLabel,
  useLegend,
  useSeriesMappedToAxis,
  useSumOfTreeSeries, useVirtualMap,
  useXAxis,
} from "components/charts/Chart.utils"
import {percentageFormat} from "commons/format/formatter"
import {captureError} from "services/SentryService"
import {barChartTooltip, formatSeriesFromMetricOnAxisToSlicerOnAxis, FORMATTING_ERROR} from "components/charts/bar/BarChart.utils"
import {sortNodeValuesASC, sortNodeValuesDESC} from "classes/MetricDataNode"
import {standardYAxisOptions} from "components/charts/Chart.options"
import {seriesAsPercentage} from "classes/workflows/query-workflows/aggregatedQueryWorkflow"
import {isArray, omit} from "lodash"
import {GenericEChartProps} from 'components/charts/line/LineChart'
import SeriesLine = echarts.EChartOption.SeriesLine
import SeriesBar = echarts.EChartOption.SeriesBar
import {ChartFormat} from "@biron-data/bqconf"

export interface Hoverdata {
  yAxisIndex?: number
  borderColor?: string
  color?: string
  componentIndex?: number
  componentSubType?: string
  componentType?: string
  data?: number
  dataIndex: number
  dataType?: string
  dimensionNames?: (string | undefined)[]
  encode?: {
    x: number[]
    y: number[]
  }
  name?: string
  seriesId?: string
  seriesIndex?: number
  seriesName?: string
  seriesType?: string
  type?: string
  value?: number
}

interface HighlightData {
  batch?: {
    batch: null
    dataIndex: number
    dataIndexInside: number
    escapeConnect: boolean
    notBlur: boolean
    seriesIndex: number
    type: string
  }[]
  escapeConnect: boolean
  type: "highlight"
}

export interface Axis {
  type: "category" | "value"
  data?: (string | number | {
    value?: string | number | undefined
  })[]
  axisLabel: {
    formatter?: string | ((label: string, i: number) => string | undefined)
    rotate?: number
  }
  axisLine: {
    lineStyle: {
      color: string
    }
  }
  min?: number
  max?: number
}

export interface CategoryAxis extends Axis {
  type: "category",
}

export interface ValuesAxis extends Axis {
  type: "value",
}

// Implement this workaround to have legend for each data point https://stackoverflow.com/questions/52771079/echarts-display-corresponding-legend-for-each-bar
const EBarChartWithLegend = forwardRef<any, ELineChartProps>(function EBarChartWithLegend(props, ref) {
  const {extraConf, effectiveConf} = props.rawChartData.meta

  const [firstLimit, secondLimit] = useMemo(() => extraConf.limits && extraConf.limits?.length > 0 ? extraConf.limits : [], [extraConf.limits])
  const orderBy = effectiveConf.orderBys && effectiveConf.orderBys?.length > 0 ? effectiveConf.orderBys[0] : undefined

  const slicers = useMemo(() => effectiveConf.slicers, [effectiveConf.slicers])
  const metrics = useMemo(() => effectiveConf.metrics, [effectiveConf.metrics])
  const hasSlicer = useMemo(() => slicers.length > 0, [slicers])
  const metricIndex = slicers.length
  const firstSlicerIndex = 0
  const secondSlicerIndex = slicers.length > 0 ? 1 : -1
  const includeLegend = !(metrics.length === 1 && slicers.length === 1)
  const sort = useMemo(() => computeOrderByWithOption(metricIndex, firstSlicerIndex, orderBy, props.selection?.sortSeries), [orderBy, metricIndex, props.selection?.sortSeries])

  const isFirstSlicerOfTypeDate = useMemo(() => slicers.length > 0 && slicers[0].type === "date", [slicers])

  const parsedDataWithBlanksFilled = useFillBlanks(props.rawChartData.parsedData, isFirstSlicerOfTypeDate, hasSlicer, props.rawChartData.expectedDates)

  const isPercentage = useMemo(() => props.selection?.asPercentage, [props.selection?.asPercentage])

  const indexOfSortedMetrics = useMemo(() => [...Array(metrics.length).keys()], [metrics.length])

  const sumOfValues = useSumOfTreeSeries(parsedDataWithBlanksFilled, indexOfSortedMetrics)

  const rawParsedMetrics = useBarChartRawParsedMetrics(props.rawChartData.parsedData, slicers, isFirstSlicerOfTypeDate, hasSlicer, firstLimit, secondLimit, firstSlicerIndex, secondSlicerIndex, metricIndex, sort, sumOfValues, props.rawChartData.expectedDates)
  const parsedMetrics = useMemo(() => metrics.length > 0 && slicers.length === 0 ? rawParsedMetrics.sort(sort?.asc ? sortNodeValuesDESC : sortNodeValuesASC) : rawParsedMetrics, [metrics.length, rawParsedMetrics, slicers.length, sort?.asc])
  const metricsInDisplayOrder = useMemo(() => parsedMetrics.map(p => p.metric), [parsedMetrics])

  const xAxis = useXAxis(hasSlicer, parsedMetrics)

  const defaultFormatter = useDefaultSerieFormatter()

  const isOneMetricOneSlicer = useMemo(() => slicers.length === 1 && metrics.length === 1, [metrics.length, slicers.length])

  const isStacked = useMemo(() => !(props.selection.format === ChartFormat.V_GROUPED
    || props.selection.format === ChartFormat.H_GROUPED) || isOneMetricOneSlicer || isPercentage, [isOneMetricOneSlicer, isPercentage, props.selection.format])

  const seriesFormatter = useCallback((series: OriginalSerie[]) => {
    if (isOneMetricOneSlicer) {
      try {
        if (xAxis) {
          return formatSeriesFromMetricOnAxisToSlicerOnAxis(series, xAxis)
        }
      } catch (e) {
        captureError(FORMATTING_ERROR, {})
        return defaultFormatter(series)
      }
    }
    return defaultFormatter(series)
  }, [defaultFormatter, isOneMetricOneSlicer, xAxis])


  const formattedMetrics: MetricDataTreeWithSeries<SerieType>[] = useMemo(() => parsedMetrics.map(parsedMetric => {
    const series = seriesFormatter(parsedMetric.getSeries())
    switch (series[0].type) {
      case "original":
        return ({
          ...parsedMetric,
          series,
        }) as MetricDataTreeWithSeries<OriginalSerie>
      case "consolidated":
        return ({
          ...parsedMetric,
          series,
        }) as MetricDataTreeWithSeries<ConsolidatedSerie>
      default: {
        // eslint-disable-next-line no-case-declarations
        const exhaustiveCheck: never = series[0]
        return exhaustiveCheck
      }
    }
  }), [parsedMetrics, seriesFormatter])

  const [originalYAxis, serieInfos] = useSeriesMappedToAxis(formattedMetrics)

  const seriesLabel = useMemo(() => slicers.length > 0 ? serieInfos.flatMap(({
                                                                               series,
                                                                             }) => series.map((s) => s.label)) : [], [serieInfos, slicers.length])
  const alternativeDisplays = useMemo(() => isPercentage && !parsedMetrics.find(parsedMetric => parsedMetric.format.asRatio) ? serieInfos.map(serieMappedToAxis => ({
      ...serieMappedToAxis,
      series: seriesAsPercentage(serieMappedToAxis.series),
      format: percentageFormat,
    })) : []
    , [isPercentage, parsedMetrics, serieInfos])

  const initialColors = useColors(Number(slicers.length > 1 || metrics.length > 1), parsedMetrics[0], seriesLabel)
  const alternativeFormats = useMemo(() => alternativeDisplays.flatMap(alternativeDisplay => alternativeDisplay.format), [alternativeDisplays])
  const displayedFormats = useMemo(() => serieInfos.flatMap((ss) => ss.format), [serieInfos])
  const metricColors = useMemo(() => props.rawChartData.parsedData.map(data => data.metric.extraConf?.color), [props.rawChartData.parsedData])

  const colors = useBarChartColors(slicers.length, metrics.length, initialColors, seriesLabel, metricColors)

  const axisLabel = useAxisLabel(xAxis, firstLimit, slicers, props.dimensions.height)

  const categoryAxis: CategoryAxis[] = useMemo(() => ([{
    data: xAxis,
    type: "category",
    axisLabel,
    axisLine: {
      lineStyle: {
        color: "#DCE0E4",
      },
    },
  }]), [axisLabel, xAxis])

  const valuesAxis = useMemo(() => isStacked ? originalYAxis.map(axis => ({
    ...omit(axis, "type"),
    min: standardYAxisOptions().min,
    max: standardYAxisOptions().max,
    type: "value",
  } as ValuesAxis)) : originalYAxis as ValuesAxis[], [isStacked, originalYAxis])

  const isHorizontal = useMemo(() => props.selection.format === ChartFormat.H_GROUPED
    || props.selection.format === ChartFormat.H_STACKED
    || props.selection.format === ChartFormat.PERCENTAGE_H_STACKED, [props.selection.format])

  const yAxis: Axis[] = useMemo(() => (isHorizontal ? categoryAxis : valuesAxis).map(axis => ({
    ...axis,
    inverse: isHorizontal,
  })), [isHorizontal, valuesAxis, categoryAxis])

  const indexOfSecondSlicer = useMemo(() => isHorizontal ? 0 : 1, [isHorizontal])

  const consolidatedXAxis: Axis[] = useMemo(() => isHorizontal ? valuesAxis : categoryAxis, [isHorizontal, valuesAxis, categoryAxis])
  const displayedSeries = useMemo(() => alternativeDisplays.length > 0 ? alternativeDisplays : serieInfos, [alternativeDisplays, serieInfos])
  const getSeriesLabel = useGetSeriesLabel(indexOfSecondSlicer)

  const getXAxisValues = useCallback((displayedSerieIndex: number) => parsedMetrics[displayedSerieIndex].getXAxisAt(0), [parsedMetrics])
  const getXAxisValuesAt = useCallback((index: number, displayedSerieIndex: number) => getXAxisValues(displayedSerieIndex)[index], [getXAxisValues])

  const renderedSeries: (SeriesBar | SeriesLine)[] = useMemo(() => displayedSeries.flatMap(({
                                                                                                                   axisIndex,
                                                                                                                   series,
                                                                                                                      }, displayedSerieIndex) => series.map((serie, idx) => {
    const {min, max} = getSerieMinMax(series)

    return {
      name: serie.label,
      yAxisIndex: isArray(yAxis) && yAxis.length > 1 ? axisIndex : undefined,
      type: "bar",
      barGap: "0",
      stack: isStacked ? "stack" : undefined,
      itemStyle: {
        opacity: 1,
      },
      labelLayout: {
        hideOverlap: true,
        dx: 0,
        dy: 12,
      },
      label: getSeriesLabel(displayedFormats[displayedSerieIndex], alternativeFormats[displayedSerieIndex], props.selection?.displayLabels),
      data: serie.values.map((value, i) => {
          return [
            ...(isHorizontal ? [
              preventInfinite(value, min, max), // dimension 1
              getXAxisValuesAt(i, displayedSerieIndex), // dimension 0
            ] : [
              getXAxisValuesAt(i, displayedSerieIndex), // dimension 0
              preventInfinite(value, min, max), // dimension 1
            ]),
            serie.isOther ? 1 : 0, // dimension 2, to reorder tooltip
            serieInfos[displayedSerieIndex].series[idx]?.values[i], // dimension 3, to display true value in tooltip
            alternativeDisplays[displayedSerieIndex]?.series?.[idx]?.values[i], // dimension 4, originalValue retrieved from second serie that may or may not exist
          ] as any
        },
      ),
    }
  })), [displayedSeries, yAxis, isStacked, getSeriesLabel, displayedFormats, alternativeFormats, props.selection?.displayLabels, isHorizontal, getXAxisValuesAt, serieInfos, alternativeDisplays])

  const tooltip = barChartTooltip(valuesAxis.length, serieInfos, effectiveConf, displayedFormats, alternativeFormats)

  const computeTotal = useCallback((dataIndex?: number) => {
    if (alternativeDisplays.length > 0) {
      return undefined
    } else if (isOneMetricOneSlicer) {
      return computeTotalAcrossSeries(serieInfos.flatMap((series => series.series)))
    } else if (dataIndex === undefined) {
      return undefined
    } else {
      return serieInfos.flatMap((series => series.series.flatMap(s => s.values[dataIndex]))).reduce((accValues, value) => (accValues ?? 0) + (value ?? 0), 0)
    }
  }, [alternativeDisplays.length, isOneMetricOneSlicer, serieInfos])

  const mouseoverCallBack = useCallback((data?: Hoverdata) => {
    if (data) {
      const total = computeTotal(data.dataIndex)
      const echarts = (ref as MutableRefObject<BaseChartRef>)?.current.getEchartsInstance()
      echarts?.setOption({
        ...(serieInfos.length > 1 ? ({
          yAxis: overlaidAxis(data, serieInfos, yAxis),
          series: overlaidBarSeries(renderedSeries, data.yAxisIndex),
        }) : {}),
        tooltip: barChartTooltip(valuesAxis.length, serieInfos, effectiveConf, displayedFormats, alternativeFormats, total, data),
      })
    }
  }, [alternativeFormats, computeTotal, displayedFormats, effectiveConf, ref, renderedSeries, serieInfos, valuesAxis.length, yAxis])

  const highlightCallBack = useCallback((data: HighlightData) => {
    const hoverData = data.batch && data.batch.length > 0 ? data.batch[0] : undefined
    const total = hoverData ? computeTotal(hoverData.dataIndex) : undefined
    const echarts = (ref as MutableRefObject<BaseChartRef>)?.current.getEchartsInstance()
    echarts?.setOption({
      tooltip: barChartTooltip(valuesAxis.length, serieInfos, effectiveConf, displayedFormats, alternativeFormats, total, hoverData),
    })
  }, [alternativeFormats, computeTotal, displayedFormats, effectiveConf, ref, serieInfos, valuesAxis.length])

  const mouseoutCallback = useCallback(() => {
    const echarts = (ref as MutableRefObject<BaseChartRef>)?.current.getEchartsInstance()
    echarts?.setOption({
      yAxis,
      series: renderedSeries,
    })
  }, [ref, renderedSeries, yAxis])

  const legend = useLegend(includeLegend, true, serieInfos)
  const visualMap = useVirtualMap(true, metricsInDisplayOrder, originalYAxis[0].min as number, originalYAxis[0].max as number)

  const options: any = useMemo(() => {
    return ({
      xAxis: consolidatedXAxis,
      legend,
      visualMap,
      yAxis,
      tooltip,
      color: colors,
      grid: {
        top: props.selection?.displayLabels ? DISTANCE_BETWEEN_CHART_TOP_AND_LEGEND : undefined,
      },
      series: renderedSeries,
    })
  }, [consolidatedXAxis, legend, visualMap, yAxis, tooltip, colors, props.selection?.displayLabels, renderedSeries])

  const axisFormats = useAxisFormat(yAxis, serieInfos)

  const consolidatedAxisFormats = useMemo(() => {
    return alternativeFormats.length > 0 ? alternativeFormats : axisFormats
  }, [alternativeFormats, axisFormats])

  return <ChartBase ref={ref}
                    option={options}
                    dimensions={props.dimensions}
                    includeLegend={legend?.show}
                    xAxisFormats={isHorizontal ? consolidatedAxisFormats : undefined}
                    yAxisFormats={isHorizontal ? undefined : consolidatedAxisFormats}
                    events={{
                      mouseover: mouseoverCallBack,
                      mouseout: mouseoutCallback,
                      highlight: highlightCallBack,
                    }}
  />
})

const BarChart = forwardRef<any, GenericEChartProps>(function BarChart(props, ref) {
  return <EChartContainer ref={ref} {...props} chart={EBarChartWithLegend} rawChartData={props.chartData}/>
})

export default BarChart
