import React, {FunctionComponent, useCallback} from 'react'
import {useSelector} from "react-redux"
import {getDashboardEditMode} from "redux/appEnvironment.selector"
import {getCurrentDashboard} from "redux/currentDashboard.selector"
import useDispatch from "hooks/useDispatch"
import {getCurrentWorkspaceExpanded} from "redux/workspace.selector"
import {useNavigate} from "react-router-dom"
import {ExpandedWorkspace} from "redux/models/workspace"
import {DataSelection} from "@biron-data/bqconf"
import DashboardDateSelector from "components/forms/selector/dashboardDate/DashboardDateSelector"
import {DateInterval} from "@biron-data/period-resolver"
import {getCurrentEnvironmentId} from "redux/environment.selector"
import {useAction} from "components/mainLayout/MainLayout.Header.hooks"
import {StyledHeader} from "components/mainLayout/MainLayout.Header.style"

interface Props {
  onDashboardSelectionChange?: (selection: DataSelection) => void,
  onEditModeChange?: (state: boolean) => void
  dashboardSelection: DataSelection,
  editMode?: boolean,
  canEditDashboardContent: boolean,
  canEditDashboardProperties: boolean,
}

const MainLayoutHeader: FunctionComponent<Props> = ({
                                                      onDashboardSelectionChange,
                                                      dashboardSelection,
                                                      onEditModeChange,
                                                      canEditDashboardContent,
                                                      canEditDashboardProperties,
                                                    }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const editMode = useSelector(getDashboardEditMode)
  const currentDashboard = useSelector(getCurrentDashboard)
  const currentEnvironmentId = useSelector(getCurrentEnvironmentId)
  const currentWorkspace = useSelector(getCurrentWorkspaceExpanded) as ExpandedWorkspace

  const handleDashboardCalendarChange = useCallback(
    (date: Omit<DateInterval, 'name'>) => onDashboardSelectionChange?.({
      ...dashboardSelection as DataSelection, // We consider dashboardSelection is filled here
      date,
    }),
    [dashboardSelection, onDashboardSelectionChange],
  )

  const action = useAction(
    currentDashboard,
    currentEnvironmentId,
    currentWorkspace,
    canEditDashboardContent,
    canEditDashboardProperties,
    editMode,
    onEditModeChange,
    dispatch.currentDashboard.confUpdate,
    dispatch.personalDashboards.cloneDashboard,
    dispatch.workspace.cloneDashboard,
    dispatch.personalDashboards.updatePersonalDashboardFolder,
    navigate
  )

  return <StyledHeader>
    {dashboardSelection.date && <div>
      <DashboardDateSelector value={dashboardSelection.date} onChange={handleDashboardCalendarChange}/>
    </div>}
    {action}
  </StyledHeader>
}

export default MainLayoutHeader