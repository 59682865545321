import {getContext, getEnvs} from 'services/EnvService'
import {init, setEnv} from 'services/GoogleAnalyticsService'
import {captureWarn, configureScope, init as initSentry} from 'services/SentryService'
import Language from "language"
import API from "services/api"
import {buildAndBatch, returnOrExecBatch} from 'commons/batch'
import {createModel} from "@rematch/core"
import RootModel from "redux/models/index"
import {StringIndex} from "redux/models/workspace"
import {AuthContextProps} from "react-oidc-context"

export interface ClientWorkspace {
  id: number;
  name: string;
}

interface User {
  fullName: string;
  userName: string;
  isTicketUser: boolean;
  locale?: string;
}

export enum PermissionsEnum {
  userCanManageOnEnvironment = 'userCanManageOnEnvironment',
  userCanAssocToEnvironment = 'userCanAssocToEnvironment',
  userCanManageAdvancedFeature = 'userCanManageAdvancedFeature',
  userCanSetPowerUser = 'userCanSetPowerUser',
  workspaceCanEditContent = 'workspaceCanEditContent',
  datamodelCanExplore = 'datamodelCanExplore',
  chartCanGenerateTitle = 'chartCanGenerateTitle',
  withDashboardP = 'withDashboardP',
  dashboardPCanEditAll= 'dashboardPCanEditAll'
}

export type Permissions = {
  [key in keyof typeof PermissionsEnum]: boolean;
}

interface AppEnvironnement {
  consts: StringIndex<string>
  environment: Partial<{ // partial because it is set to {} in init
    id: number;
    allowedEmailFqdns: string[];
    name: string;
    workspaces: ClientWorkspace[];
  }>
  authToken: string | null
  auth?: AuthContextProps
  dashboardEditMode: boolean
  permissions?: Permissions
  user: User | null;
}

const initialState: AppEnvironnement = {
  consts: {},
  user: null,
  auth: undefined,
  authToken: null,
  permissions: undefined,
  environment: {},
  dashboardEditMode: false,
}

export default createModel<RootModel>()({
  state: initialState,
  reducers: {
    setConsts(state, consts: StringIndex<string>) {
      return {
        ...state,
        consts,
      }
    },
    setUser(state, user?: { firstName: string; lastName: string; email: string; powerUser: boolean; locale?: string }) {
      return {
        ...state,
        user: user ? {
          fullName: `${user.firstName} ${user.lastName}`,
          userName: user.email,
          isTicketUser: user.powerUser,
          locale: user.locale
        } : null,
      }
    },
    setAuthToken(state, {auth, authToken}: { auth: AuthContextProps, authToken: string }) {
      return {
        ...state,
        auth,
        authToken,
      }
    },
    setPermissions(state, permissions) {
      return {
        ...state,
        permissions,
        dashboardEditMode: state.dashboardEditMode && permissions.workspaceCanEditContent,
      }
    },
    setEnvironment(state, environment) {
      return {
        ...state,
        environment,
      }
    },
    setDashboardEditMode(state, dashboardEditMode) {
      return {
        ...state,
        dashboardEditMode,
      }
    },
  },
  effects: (dispatch) => ({
    async loadStatic({forBatch = false, authToken}: { forBatch?: boolean, authToken?: string } = {}) {
      const {consts, user} = await getEnvs()

      initSentry(consts.SENTRY_URL)
      setEnv(consts.GOOELANALYTICS_KEY_4)

      if (user) {
        if (user.locale) {
          Language.set(user.locale)
        }
        init(`${user.id}`)
        configureScope(user.email)
      }

      // @ts-ignore
      window.whoami = () => {
        // eslint-disable-next-line no-console
        console.log(`TOKEN="${authToken}"`)
        return user
      }

      return returnOrExecBatch(forBatch,
        () => dispatch.appEnvironment.setConsts(consts),
        () => dispatch.appEnvironment.setUser(user),
      )
    },
    async loadContext({environmentId, workspaceId}) {
      const {permissions, environment} = await getContext({
        environmentId,
        workspaceId,
      })
      dispatch.appEnvironment.setPermissions(permissions)
      dispatch.appEnvironment.setEnvironment({
          id: environmentId,
          ...environment,
      })
    },
    async initAuth({accessToken, auth}: { accessToken: string, auth: AuthContextProps }) {
      API.onAuthRequired(() => {
        dispatch.appEnvironment.logout({auth})
        // return an unresolvable promise to force client to wait for logout/login process
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        return new Promise(() => {
        })
      })

      const isAuthenticated = auth && auth.isAuthenticated

      if (isAuthenticated) {
        // accessToken can change without login/logout when the previous one expires
        const authToken = accessToken ? `Bearer ${accessToken}` : null
        if (authToken) {
          API.setToken(authToken)
          buildAndBatch([
            dispatch.appEnvironment.loadStatic({
              forBatch: true,
              authToken,
            }),
            dispatch.workspace.init({forBatch: true}),
            () => dispatch.appEnvironment.setAuthToken({
              auth,
              authToken,
            }),
          ])
        } else {
          captureWarn("initAuth error: no accessToken found while user isAuthenticated")
          // authService.login(window.location.path)
          // oktaAuth.signInWithRedirect({originalUri: window.location.})
          dispatch.appEnvironment.logout({auth})
        }
      }
    },
    logout({auth: defaultAuthService}: { auth?: AuthContextProps }, state) {
      const auth = defaultAuthService ?? state.appEnvironment.auth
      auth?.signoutRedirect({
          // https://openid.net/specs/openid-connect-rpinitiated-1_0.html#RPLogout
          // TODO Uncomment once keycloak is in production: allow redirect to a wildcard, so we don't lose current page when we log out
          // post_logout_redirect_uri: window.location.href.includes("/implicit/callback`") ? `${window.location.origin}/` : window.location.href, // redirect to current uri unless logout was triggered from the auth callback page
          // eslint-disable-next-line camelcase
          post_logout_redirect_uri: `${window.location.origin}/`, // redirect to current uri unless logout was triggered from the auth callback page
          // eslint-disable-next-line camelcase
          id_token_hint: auth?.user?.id_token, // prevents logout confirmation screen https://github.com/keycloak/keycloak/discussions/12183
        })
        .catch(() => captureWarn("could not logout"))
    },
  }),
})
