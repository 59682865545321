import React, {memo} from 'react'
import styled from "styled-components"
import {HandleMenuActionParamTypes} from "components/workspace/WorkspaceBridge.SiderContainer"
import {MetaModel} from "@biron-data/bqconf"
import {ExtraAction as ExtraActionType} from "types/navigation"
import MainLayoutExtraAction from "components/mainLayout/MainLayout.ExtraAction"
import {ExpandedWorkspace} from "redux/models/workspace"

interface Props {
  onAction: (params: HandleMenuActionParamTypes) => void
  metaModel: MetaModel
  workspace: ExpandedWorkspace
  confs: ExtraActionType[]
}

const MainLayoutExtraActionsWrapper = memo<Props>(function ExtraActionsWrapper({confs, onAction, metaModel, workspace}) {
  return confs.length > 0
    ? <StyledSpan onClick={e => {
      e.stopPropagation()
    }}>
      {confs.map(conf => <MainLayoutExtraAction key={conf.key} {...{
        conf,
        onAction,
        metaModel,
        workspace,
      }}/>)}
    </StyledSpan>
    : null
})

const StyledSpan = styled.span`
  z-index: 1;
  display: flex;
`

export default MainLayoutExtraActionsWrapper
