import {ClientWorkspace} from "redux/models/appEnvironment"
import React from "react";
import {UACWithAces} from "../../../hooks/useUACWithAces";

export interface Environment {
  allowedEmailFqdns?: string[]
  id?: number
  name?: string
  workspaces?: ClientWorkspace[]
}

export interface User {
  bironUser: boolean
  email: string
  firstName: string
  fullName: string
  id: number
  lastName: string
  locale: string
  powerUser: boolean
}

export interface UAC {
  active: boolean,
  id: number
  user: User
}

export interface ACES {
  roles: Roles[]
  id: number
  target: string
  targetId: string
  targetObject: {
    id: number
    name: string
  } | null
  targetType: string
}

export interface WorkspacesForAce {
  id: number
  name: string
}

export enum DatamodelRoles {
  'DATAMODEL_VIEWER' = 'DATAMODEL_VIEWER',
  'DATAMODEL_EXPLORER' = 'DATAMODEL_EXPLORER',
}

export enum WorkspaceRoles {
  'WORKSPACE_READER' = 'WORKSPACE_READER',
  'WORKSPACE_WRITER' = 'WORKSPACE_WRITER',
  'WORKSPACE_MANAGER' = 'WORKSPACE_MANAGER',
}

export enum EnvironmentRoles {
  'ENVIRONMENT_SUPERADMIN' = 'ENVIRONMENT_SUPERADMIN',
  'ENVIRONMENT_ADMIN' = 'ENVIRONMENT_ADMIN',
}

export type Roles = WorkspaceRoles | DatamodelRoles | EnvironmentRoles

export type GetComponentProps<DataType> = (
  data: DataType,
  index?: number,
) => React.HTMLAttributes<any> & React.TdHTMLAttributes<any>;

export interface DataSourceEntry extends UACWithAces {
  permUserCanEdit: boolean | "",
}
