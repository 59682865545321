import {useCallback, useMemo} from "react";
import {OriginalSerie} from "../line/LineChart.types";
import {formatValue} from "../../../commons/format/formatter";
import {Mark} from "../../forms/selector/mark/MarkSelector";
import {
  Gradient,
  Interval,
} from "@biron-data/react-bqconf";
import {
  ColorName,
  Colors, ExtraConf,
  Format,
  strToColor
} from "@biron-data/bqconf";
import {EChartOption} from "echarts";
import {tooltipMetricHeader} from "../Chart.utils";
import {getMarker, tooltipLineFormat} from "../Chart.tooltipContent";
import {HTML_DIVIDER, tooltipBaseParameters} from "../Chart.tooltip";
import {isArray} from "lodash";
import Language from "../../../language";
import {ColorsType} from "./GaugeChart";
import {
  getGradientStops
} from "@biron-data/react-bqconf";

export const getXPositionOfValue = (barWidth: number, value: number, min: number, max: number) => {
  return (barWidth / (max - min)) * (value - min)
}

export const getTooltip = (
  format: Format,
  metricAlias: string,
  marks: Mark[],
  max: number,
  seriesIndex: number,
  achievedData: number,
  remainingData: number,
) => {
  const tooltipString: string[] = [
    tooltipMetricHeader({
      slicers: [],
      metrics: [{
        metricAlias
      }]
    } as any),
  ]
  tooltipString.push(tooltipLineFormat(format, undefined, getMarker(), seriesIndex === 1 ? Language.get('remaining') : Language.get('done'), seriesIndex === 1 ? remainingData : achievedData, max, undefined, undefined))
  if (marks.length > 0) {
    tooltipString.push(HTML_DIVIDER)
  }
  marks.forEach((mark, i) => {
    if (i !== 0) {
      tooltipString.push('<br/>')
    }
    tooltipString.push(tooltipLineFormat(format, undefined, getMarker(strToColor.get(mark.color)), mark.name, mark.value, undefined, undefined, undefined, (achievedData / mark.value) * 100))
  })
  return tooltipString.join('')
}

export const useGetHorizontalMarks = (
  marks: Mark[],
  startXPosition: number,
  barWidth: number,
  labelSize: number,
  markPosition: number,
  markHeight: number,
  format: Format,
  min: number,
  getMax: (serie: OriginalSerie) => number
) => useCallback((serie: OriginalSerie) => marks.flatMap((mark: Mark) => {
  if (getMax(serie) < mark.value) {
    return undefined
  } else {
    const xPosition = startXPosition + getXPositionOfValue(barWidth, mark.value, min, getMax(serie))
    return [{
      type: 'custom',
      z: 4,
      renderItem: () => {
        return {
          type: 'rect',
          z: 4,
          shape: {
            x: xPosition,
            y: -1 * (markPosition),
            width: 2,
            height: markHeight,
          },
          style: {
            fill: strToColor.get(mark.color),
            borderColor: strToColor.get(mark.color),
            borderWidth: 30,
          }
        }
      },
      data: [serie.values[0]],
    }, {
      type: 'custom',
      z: 4,
      renderItem: () => {
        return {
          type: 'text',
          z: 4,
          style: {
            text: mark.name ? `${mark.name}: ${formatValue(mark.value, format)}` : formatValue(mark.value, format),
            fontSize: labelSize,
            fontFamily: 'Poppins',
            x: xPosition,
            y: -1 * (markPosition + (markHeight / 3)),
            align: 'middle',
            verticalAlign: 'middle',
            backgroundColor: 'white',
            fill: "#94949E",
          }
        }
      },
      data: [serie.values[0]],
    }]
  }
}).filter(mark => mark), [marks, getMax, startXPosition, barWidth, min, markPosition, markHeight, format, labelSize])


export const useGetHorizontalOptions = (
  serie: OriginalSerie,
  dimensions: { height: number, width: number },
  format: Format,
  marks: Mark[],
  min: number,
  metricAlias: string,
  getMax: (serie: OriginalSerie) => number,
  colors: ColorsType
) => {
  const sizeReference = useMemo(() => {
    if (dimensions.height < 150) {
      return 4.2
    } else if (dimensions.height < 200) {
      return (dimensions.height / 100) + 4
    }
    return 6
  }, [dimensions])
  const barWidth = useMemo(() => dimensions.width, [dimensions.width])
  const getValueBarWidth = useCallback(() => getXPositionOfValue(barWidth, serie.values[0], min, getMax(serie)), [barWidth, getMax, min, serie])
  const startXPosition = useMemo(() => (dimensions.width / 2) - (barWidth / 2), [barWidth, dimensions.width])
  const endXPosition = useMemo(() => (dimensions.width / 2) + (barWidth / 2), [barWidth, dimensions.width])

  const fontSize = 14
  const labelSize = 10
  const headerFontSize = 24

  const textPosition = sizeReference * 4
  const titlePosition = 80
  const subTitlePosition = 50

  const progressBarHeight = sizeReference * 3

  const markHeight = sizeReference * 6
  const markPosition = sizeReference * -18.5
  const getHorizontalMarks = useGetHorizontalMarks(marks, startXPosition, barWidth, labelSize, markPosition, markHeight, format, min, getMax)

  const achievedData = serie.values[0]
  const remainingData = getMax(serie) - serie.values[0]
  const max = getMax(serie)

  return useMemo(() => {
    return {
      tooltip: {
        ...tooltipBaseParameters(),
        trigger: 'item',
        formatter: (params) => {
          return isArray(params) ? undefined : getTooltip(format, metricAlias, marks, max, params.seriesIndex ?? -1, achievedData, remainingData)
        },
        borderColor: 'transparent',
      },
      xAxis: {
        show: false,
        type: "value", // Horizontal gauge uses value type
        max: 100, // Adjust max based on your data range
        axisLine: {
          show: false,
        },
        splitLine: {
          show: false,
        },
      },
      yAxis: {
        show: false,
        type: 'category',
        data: ['Metric A', 'Metric B', 'Metric C'], // Categories for horizontal bars
        axisTick: {
          show: false,
        },
        axisLine: {
          show: false,
        },
      },
      series: [
        {
          type: 'custom',
          z: 0,
          renderItem: () => {
            return {
              type: 'rect',
              shape: {
                x: startXPosition,
                y: sizeReference * 20,
                width: barWidth,
                height: progressBarHeight,
              },
              style: {
                fill: colors[0],
                stroke: 'transparent',
                shadowBlur: 'none'
              }
            }
          },
          data: [serie.values[0]],
        },
        {
          type: 'custom',
          z: 1,
          renderItem: () => {
            return {
              type: 'rect',
              shape: {
                x: startXPosition + getValueBarWidth(),
                y: sizeReference * 20,
                width: barWidth - getValueBarWidth(),
                height: progressBarHeight + 1,
              },
              style: {
                fill: 'white',
                stroke: 'transparent',
              },
            }
          },
          data: [getMax(serie) - serie.values[0]],
        },
        {
          type: 'custom',
          z: 1,
          renderItem: () => {
            return {
              type: 'rect',
              shape: {
                x: startXPosition + getValueBarWidth(),
                y: sizeReference * 20,
                width: barWidth - getValueBarWidth(),
                height: progressBarHeight,
              },
              style: {
                fill: colors[1],
                stroke: 'transparent',
              },
            }
          },
          data: [getMax(serie) - serie.values[0]],
        },
        ...getHorizontalMarks(serie)
      ],
      graphic: {
        elements: [
          {
            type: 'group',
            top: 'center',
            children: [
              {
                type: 'text',
                z: 2,
                style: {
                  text: metricAlias,
                  fontSize,
                  fontFamily: 'Poppins',
                  x: startXPosition,
                  y: -1 * (titlePosition),
                  align: 'start',
                  verticalAlign: 'start',
                  fill: "#94949E",
                },
              },
              {
                type: 'text',
                z: 2,
                style: {
                  text: formatValue(serie.values[0], format),
                  fontSize: headerFontSize,
                  fontFamily: 'Poppins',
                  x: startXPosition,
                  y: -1 * (subTitlePosition),
                  align: 'start',
                  verticalAlign: 'start',
                },
              },
              {
                type: 'text',
                z: 2,
                style: {
                  text: formatValue(min, format),
                  fontSize: labelSize,
                  fontFamily: 'Poppins',
                  x: startXPosition,
                  y: textPosition,
                  align: 'start',
                  verticalAlign: 'start',
                  fill: "#94949E",
                },
              },
              {
                type: 'text',
                z: 2,
                style: {
                  text: formatValue(getMax(serie), format),
                  fontSize: labelSize,
                  fontFamily: 'Poppins',
                  x: barWidth,
                  y: textPosition,
                  textAlign: 'right',
                  fill: "#94949E",
                },
              },
            ]
          }
        ]
      }
    } as EChartOption
  }, [serie, getMax, getHorizontalMarks, metricAlias, startXPosition, format, min, textPosition, endXPosition, marks, max, achievedData, remainingData, sizeReference, getValueBarWidth, progressBarHeight, colors, barWidth])
}

export const useGetColors = (
  extraConf: ExtraConf | undefined,
  serie: OriginalSerie
): ColorsType => {
  const interval: Interval | undefined = useMemo(() => extraConf?.interval, [extraConf?.interval])
  const gradient: Gradient | undefined = useMemo(() => extraConf?.gradient, [extraConf?.gradient])
  const color = useMemo(() => extraConf?.color, [extraConf?.color])

  return useMemo(() => {
    if (color) {
      return [strToColor.get(color), `${strToColor.get(color)}10`] as [string, string]
    } else if (gradient) {
      const gradientColors = getGradientStops(gradient.type, gradient.base)
      return [{
        type: 'linear',
        x: 0,
        y: 0,
        x2: 1,
        y2: 0,
        colorStops: gradientColors.map(([c, percentage]) => ({
          offset: 1 - (percentage / 100), color: c // color at 0%
        })),
        global: false // default is false
      }, `${strToColor.get(ColorName["blue-border"])}10`]
    } else if (interval) {
      const consolidatedColor = serie.values[0] > interval.step ? strToColor.get(interval.firstColor) : strToColor.get(interval.secondColor)
      return [consolidatedColor ?? Colors.blue_border, consolidatedColor ? `${consolidatedColor}10` : Colors.blue_border] as [string, string]
    }
    return [strToColor.get(ColorName["blue-border"]), `${strToColor.get(ColorName["blue-border"])}10`] as [string, string]
  }, [color, gradient, interval, serie.values])
}