import React, {memo, useMemo, useRef, useState} from 'react'
import {Loader, WrapperItem, WrapperRoot, MdView, JustifyContentProperty} from "@biron-data/react-components"
import {FormBodyComponentProps} from "components/forms/confBodies/FormBody"
import {Col, Row, Switch} from "antd"
import styled from "styled-components"
import StylesSelector from "components/forms/confItems/StylesSelector"
import ColorSelector from "components/forms/confItems/ColorSelector"
import {textColor} from "components/charts/Chart.Theme"
import {DIVIDER_BORDER_TOP} from "components/charts/Chart.constants"
import TitleSelector from "components/forms/confItems/TitleSelector"
import DescriptionSelector from "components/forms/confItems/DescriptionSelector"
import {SparklesIcon} from "@heroicons/react/outline"
import {ColorSwatchIcon} from "@heroicons/react/solid"
import Language from "language"
import {ColorName, strToColor} from "@biron-data/bqconf"

const DividerConfBody = <T, >({loading, data}: FormBodyComponentProps<T>) => {
  const cssClass = useMemo(() => (data as any).cssClass, [data])
  const style = useMemo(() => (data as any).style, [data])
  const description = useMemo(() => (data as any).description, [data])
  const [preview, setPreview] = useState(false)
  const markdownEditor = useRef<any>()

  return <Loader loading={loading}>
    <Row gutter={32}>
      <Col span={12}>
        <InputContainer $cssClass={cssClass} $isborder={style === "border" ? 1 : 0}>
          <WrapperItem name={"title"}>
            <TitleSelector/>
          </WrapperItem>
          <PreviewSwitchWrapper>
            {Language.get("preview")} <Switch checked={preview} onChange={(checked) => setPreview(checked)}/>
          </PreviewSwitchWrapper>
        </InputContainer>
        <Container>
          <SubContainer>
            {
              preview ? <PreviewContainer style={{}} onClick={() => {
                setPreview(false)
                markdownEditor.current?.focus()
              }}>
                <MdView description={description} justifyContent={JustifyContentProperty.start}/>
              </PreviewContainer> : <WrapperItem name={"description"}>
                <DescriptionSelector ref={markdownEditor}/>
              </WrapperItem>
            }
          </SubContainer>
        </Container>
      </Col>
      <ColWithSeparator span={12}>
        <WrapperRoot
          icon={SparklesIcon}
          label="configuration-label-portlet-title-class"
          itemColumnWidth={6}>
          <WrapperItem name={"style"} rules={[{required: true, message: "Required field"}]}>
            <StylesSelector/>
          </WrapperItem>
        </WrapperRoot>
        <WrapperRoot
          icon={ColorSwatchIcon}
          label="configuration-label-color"
          itemColumnWidth={6}>
          <WrapperItem name={"cssClass"}>
            <ColorSelector/>
          </WrapperItem>
        </WrapperRoot>
      </ColWithSeparator>
    </Row>
  </Loader>
}

export default memo(DividerConfBody) as typeof DividerConfBody

const PreviewSwitchWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: 16px;
`

const PreviewContainer = styled.section`
  background-color: white;
  height: 100%;
  display: flex; 
  flex-direction: column; 
  justify-content: start;
`

const ColWithSeparator = styled(Col)`
  border-left: 1px solid var(--main-background-color);
`

const Container = styled.section`
  height: 536px;
  background-color: white;
`

const SubContainer = styled.section`
  height: 536px;
  overflow-y: scroll;
`

const InputContainer = styled.div<{ $cssClass: ColorName, $isborder: number }>`
  display: flex;
  justify-content: space-between;
  ${props => {
      if (props.$isborder === 1) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          return `border-top: ${DIVIDER_BORDER_TOP}px solid ${strToColor.get(props.$cssClass!)}; color: ${strToColor.get(props.$cssClass!)}; background-color: white;`

    } else {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          return `background-color: ${strToColor.get(props.$cssClass!)}; color: ${textColor.get(props.$cssClass!)};`
    }
  }};
  border-top-right-radius: var(--big-border-radius);
  border-top-left-radius: var(--big-border-radius);
  padding: 0px 15px;

  &&& .ant-form-item {
    ${props => {
        if (props.$isborder === 1) {
            return `color: ${strToColor.get(props.$cssClass!)};`
      } else {
            return `color: ${textColor.get(props.$cssClass!)};`
      }
    }};
  }

  &&& input {
    background: transparent;
    border: none;
    outline: none;
    width: 100%;
  }
`