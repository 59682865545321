import React, {useCallback, useMemo, useState} from "react"
import {Button, notification} from "antd";
import styled from "styled-components";
import {
    IconContainer,
    MdView,
    AdministrationLayout,
    ContentCard,
    BironList,
    BironBreadcrumb, BironBreadCrumbTitle, TableLink
} from "@biron-data/react-components";
import {useSelector} from "react-redux";
import {getAvailables, getCurrentWorkspaceExpanded} from "../../redux/workspace.selector";
import EditableTitle from "../editableTitle/EditableTitle";
import {CodeIcon, DuplicateIcon, PlusIcon} from "@heroicons/react/outline";
import {ExternalLinkIcon} from "@heroicons/react/solid";
import {getCurrentEnvironmentId} from "../../redux/environment.selector";
import Language from "../../language";
import {FormDuplicateWorkspaceProps, FormKeys, FormWorkspaceProps} from "../forms/Form.types";
import FormGeneric from "../forms/Form.Generic";
import HideableForm from "../forms/Form.Hideable";
import {
    ExpandedWorkspace,
    RawExpandedWorkspace,
    SummarizedWorkspace,
    WorkspaceDtoForm
} from "../../redux/models/workspace";
import WorkspaceManagerDelete from "./WorkspaceManager.delete";
import useDispatch from "../../hooks/useDispatch";
import {updateWorkspace} from "../../services/WorkspaceService";
import {useNavigate} from "react-router-dom";
import {getWorkspace} from "../../services/NavigationService";
import {NormalizedWorkspaceDetail} from "../../schemas/workspace";
import {omit} from "lodash";
import {buildWorkspaceAdminDatamodelUri, buildWorkspaceAdminWorkspaceUri} from "../../commons/buildWorkspaceAdminUri";
import {buildWorkspaceUri} from "../../commons/buildWorkspaceUri";
import AdminBreadcrumb from "../admin/Admin.Breadcrumb";

const WorkspaceManager = () => {
    const workspaces = useSelector(getAvailables)
    const currentWorkspace = useSelector(getCurrentWorkspaceExpanded) as ExpandedWorkspace
    const environmentId = useSelector(getCurrentEnvironmentId)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const baseWorkspace = useMemo<Partial<WorkspaceDtoForm>>(() => ({
        name: undefined,
    }), [])

    const baseAdvancedWorkspace = useMemo<Partial<WorkspaceDtoForm> & {configuration?: string}>(() => ({
        name: undefined,
        configuration: undefined
    }), [])

    const filteredWorkspaces = useMemo(() => workspaces.filter(workspace => workspace.environment.id === environmentId), [environmentId, workspaces])

    const onEditTitle = useCallback(async (newTitle: string, workspaceId: number) => {
        const workspaceDatamodelId = workspaces.find(workspace => workspace.id === workspaceId)?.datamodelCode
        if (workspaceDatamodelId) {
            updateWorkspace({
                name: newTitle,
                datamodelCode: workspaceDatamodelId
            }, workspaceId)
        }
    }, [workspaces])

    const triggerGAEvent = useCallback(() => {

    }, [])

    const [isWorkspace, setIsWorkspace] = useState(false)
    const [isAdvancedWorkspace, setIsAdvancedWorkspace] = useState(false)
    const [duplicateWorkspace, setDuplicateWorkspace] = useState<NormalizedWorkspaceDetail>()

    const handleWorkspaceConfCancel = useCallback(() => {
        setIsWorkspace(false)
    }, [])

    const handleAdvancedWorkspaceConfCancel = useCallback(() => {
        setIsAdvancedWorkspace(false)
    }, [])

    const handleDuplicateWorkspaceConfCancel = useCallback(() => {
        setDuplicateWorkspace(undefined)
    }, [])

    const triggerNewWorkspaceNotification = useCallback((createdWorkspace: RawExpandedWorkspace) => {
        notification.info({
            duration: 2.5,
            key: `workspaceCreated`,
            message: Language.get(`admin.workspaces.popup.created`, createdWorkspace.name),
            description: <MdView description={Language.get(`admin.workspaces.popup.created-description`, `/app/${createdWorkspace.uri}/admin/user`)}/>,
            placement: 'bottomRight',
        })
    }, [])

    const handleWorkspaceConfSubmit = useCallback((data: Partial<WorkspaceDtoForm>) => {
        return dispatch.workspace.createWorkspace({
            dto: {
                ...baseWorkspace,
                datamodelCode: data.datamodelCode ?? "",
                name: data.name ? data.name : Language.get("admin.workspaces.form.workspace.name.placeholder")
            },
            environmentId
        }).then((createdWorkspace) => {
            triggerNewWorkspaceNotification(createdWorkspace)
            handleWorkspaceConfCancel()
        })
    }, [baseWorkspace, dispatch.workspace, environmentId, handleWorkspaceConfCancel, triggerNewWorkspaceNotification])

    const handleAdvancedWorkspaceConfSubmit = useCallback((data: Partial<WorkspaceDtoForm> & {configuration?: string}) => {
        const jsonInput = JSON.parse(data.configuration ?? "{}")
        if (!jsonInput.datamodelCode) {
            return new Promise((resolve, reject) => reject(new Error("DatamodelCode is required")))
        }

        return dispatch.workspace.createWorkspace({
            dto: {
                ...baseWorkspace,
                name: Language.get("admin.workspaces.form.workspace.name.placeholder"),
                ...omit(JSON.parse(jsonInput ?? "{}"), 'id'),
                datamodelCode: jsonInput.datamodelCode,
                uri: undefined
            },
            environmentId
        }).then((createdWorkspace) => {
            triggerNewWorkspaceNotification(createdWorkspace)
            handleAdvancedWorkspaceConfCancel()
        })
    }, [baseWorkspace, dispatch.workspace, environmentId, handleAdvancedWorkspaceConfCancel, triggerNewWorkspaceNotification])


    const handleDuplicateWorkspaceConfSubmit = useCallback(async (data: Partial<WorkspaceDtoForm>) => {
       if (duplicateWorkspace?.id) {
           const fullWorkspace = await getWorkspace(duplicateWorkspace.id)
           return dispatch.workspace.createWorkspace({
               dto: {
                   ...omit(fullWorkspace, 'id'),
                   name: data.name ?? Language.get("admin.workspaces.form.workspace.name.placeholder"),
                   uri: undefined,
               },
               environmentId
           }).then((createdWorkspace) => {
               triggerNewWorkspaceNotification(createdWorkspace)
               handleDuplicateWorkspaceConfCancel()
           })
       }
        return new Promise((resolve, reject) => reject(new Error()))
    }, [dispatch.workspace, duplicateWorkspace, environmentId, handleDuplicateWorkspaceConfCancel, triggerNewWorkspaceNotification])

    const onRedirect = useCallback((workspace: SummarizedWorkspace) => {
        navigate(buildWorkspaceUri(workspace))
    }, [navigate])

    const onDelete = useCallback((id: number) => {
        dispatch.workspace.deleteWorkspace({id, environmentId, onRedirect})
    }, [dispatch.workspace, environmentId, onRedirect])

    return <AdministrationLayout breadcrumb={<AdminBreadcrumb
      category={{
          title: Language.get("admin.workspaces.head-title"),
          link: 'admin/workspaces'
      }}/>}>
        <HideableForm<Partial<WorkspaceDtoForm>, FormWorkspaceProps>
            renderFormComponent={(props) => <FormGeneric {...props}/>} {...{
            visible: isWorkspace,
            title: Language.get("admin.workspaces.form.workspace.title"),
            formType: {
                type: FormKeys.WORKSPACE_CONF
            },
            data: baseWorkspace,
            onConfirm: handleWorkspaceConfSubmit,
            onCancel: handleWorkspaceConfCancel,
        }}/>
        <HideableForm<Partial<WorkspaceDtoForm>, FormWorkspaceProps>
            renderFormComponent={(props) => <FormGeneric {...props}/>} {...{
            visible: isAdvancedWorkspace,
            title: Language.get("admin.workspaces.form.advancedWorkspace.title"),
            formType: {
                type: FormKeys.ADVANCED_WORKSPACE_CONF
            },
            data: baseAdvancedWorkspace,
            onConfirm: handleAdvancedWorkspaceConfSubmit,
            onCancel: handleAdvancedWorkspaceConfCancel,
        }}/>
        {duplicateWorkspace && <HideableForm<WorkspaceDtoForm, FormDuplicateWorkspaceProps>
            renderFormComponent={(props) => <FormGeneric {...props}/>} {...{
            visible: Boolean(duplicateWorkspace),
            title: Language.get("admin.workspaces.form.duplicateWorkspace.title", duplicateWorkspace.name),
            formType: {
                type: FormKeys.DUPLICATE_WORKSPACE_CONF
            },
            data: duplicateWorkspace,
            onConfirm: handleDuplicateWorkspaceConfSubmit,
            onCancel: handleDuplicateWorkspaceConfCancel,
        }}/>}
        <ContentCard title={Language.get("admin.workspaces.title")} contentBgColor={'#F6F6F8'} actions={[
            <FlexButton key={"workspaces.action.workspace"} type={"primary"} onClick={() => {
                setIsWorkspace(true)
            }}>
                <IconContainer>
                    <PlusIcon/>
                </IconContainer>
                {Language.get("admin.workspaces.action.workspace")}
            </FlexButton>,
            <FlexButton key={"workspaces.action.advanced"} type={"primary"} onClick={() => {
                setIsAdvancedWorkspace(true)
            }}>
                <IconContainer>
                    <PlusIcon/>
                </IconContainer>
                {Language.get("admin.workspaces.action.advanced")}
            </FlexButton>,
        ]}>
            <BironList options={
                filteredWorkspaces.map(workspace => {
                    return {
                        key: `${workspace.id}`,
                        elements: [
                            {
                                content: <EditableTitle fontSize={14}
                                  title={workspace.name}
                                  editable={true}
                                                        link={buildWorkspaceAdminWorkspaceUri(currentWorkspace, `${workspace.id}`)}
                                  onConfirm={(newTitle) => onEditTitle(newTitle, workspace.id)}
                                                        triggerGAEvent={triggerGAEvent}
                                />,
                                span: 10
                            },
                            {
                                content: <TableLink link={buildWorkspaceAdminDatamodelUri(currentWorkspace, workspace.datamodelCode)}>{workspace.datamodelCode}</TableLink>,
                                span: 14
                            }
                        ],
                        actions: [
                            <IconContainer key={`${workspace.id}-copy`} clickable={true} onClick={async () => {
                                const fullWorkspace = await getWorkspace(workspace.id)
                                await navigator.clipboard.writeText(JSON.stringify(fullWorkspace))
                                notification.info({
                                    duration: 2.5,
                                    key: `copyWorkspaceConf`,
                                    message: Language.get(`admin.workspaces.popup.copied`),
                                    placement: 'bottomRight',
                                })
                            }}>
                                <CodeIcon/>
                            </IconContainer>,
                            <IconContainer key={`${workspace.id}-open`} clickable={true} onClick={() => {
                                window.open(buildWorkspaceUri(workspace), '_blank')
                            }}>
                                <ExternalLinkIcon/>
                            </IconContainer>,
                            <IconContainer key={`${workspace.id}-duplicate`} clickable={true} onClick={() => {
                                setDuplicateWorkspace(workspace)
                            }}>
                                <DuplicateIcon/>
                            </IconContainer>,
                            <WorkspaceManagerDelete key={`${workspace.id}-delete`} onDelete={() => onDelete(workspace.id)} workspaceName={workspace.name} />
                        ]
                    }
                })
            }/>
        </ContentCard>
    </AdministrationLayout>
}

export default WorkspaceManager

const FlexButton = styled(Button)`
    display: flex;
    gap: 10px;
`
