import {useMemo} from "react";
import {
  DashboardTypes,
  PersonalDashboardDtoWithoutChart,
  WorkspaceDashboardDtoWithoutChart
} from "../types/dashboards";

export const useCanAccessDimensionValues = (
  dashboard: Pick<WorkspaceDashboardDtoWithoutChart, 'type' | 'filtersLocked'> | Pick<PersonalDashboardDtoWithoutChart, "type">
) => {
  return useMemo(() => {
    switch (dashboard.type) {
      case DashboardTypes.personal:
        return true
      case DashboardTypes.workspace:
        return !dashboard.filtersLocked
      default:
        return false
    }
  }, [dashboard])
}