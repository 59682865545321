import {ChartFormat, GenericChartTypes, MetricTarget, SlicerTarget, TargetAffect} from "@biron-data/bqconf"
import {ConfigurationOrderByWithDisablingReasons, LimitType} from "components/forms/chart/types"

export const usePieConfiguration = (
  withoutSlicer: boolean,
  withSlicer: boolean,
  pieSort: ConfigurationOrderByWithDisablingReasons[],
  withMetricAsRatio: boolean,
  withMetricWithGrowthAsRatio: boolean,
  withMetricMultiple: boolean,
  withMoreThanOneSlicerNotAnAxis: boolean,
) => {
  return {
    type: GenericChartTypes.PIE,
    nextCompatibleTypes: [GenericChartTypes.BARS],
    enableDateSlicer: true,
    isColorOverridable: withoutSlicer,
    metricTarget: [MetricTarget.PART],
    slicerTargets: [SlicerTarget.COLOR],
    format: [
      {
        type: ChartFormat.PIE_STANDARD,
        isMetricsFilterEnabled: false,
        isColorOverridable: withoutSlicer,
        isGradiantAvailable: false,
        isIntervalAvailable: false,
        disablingReasons: {},
      },
      {
        type: ChartFormat.DONUT,
        isMetricsFilterEnabled: false,
        isColorOverridable: withoutSlicer,
        isGradiantAvailable: false,
        isIntervalAvailable: false,
        disablingReasons: {},
      },
    ],
    orderBys: pieSort,
    limits: [{
      type: LimitType.SIMPLE,
      mandatory: withSlicer,
      default: withSlicer ? {
        enabled: true,
        value: 20,
      } : undefined,
      disablingReasons: {},
      displaySlicerOtherOption: withSlicer,
      affect: TargetAffect.PART,
    }],
    isMetricsFilterEnabled: false,
    isGradiantAvailable: false,
    isIntervalAvailable: false,
    disablingReasons: {
      withMetricAsRatio,
      withMetricWithGrowthAsRatio,
      withMetricMultiple,
      withMoreThanOneSlicerNotAnAxis,
    },
  }
}