import React, {memo, useCallback, useEffect, useMemo, useRef, useState} from 'react'
import FormHeader from 'components/forms/FormHeader'
import FormWorkflow, {State} from 'classes/workflows/main-workflows/FormWorkflow'
import FormBody, {FormBodyRef} from 'components/forms/confBodies/FormBody'
import {FormFooter} from '@biron-data/react-bqconf'
import {strToColor} from '@biron-data/bqconf'
import {Button, Modal} from 'antd'
import Language from "language"
import {FormGenericProps, FormKeys} from "components/forms/Form.types"
import {BaseEditionInformation} from "components/workspace/WorkspaceBridge.SiderContainer"
import {isEqual} from "lodash"

export const useCssClassOptions = () => useMemo(() => Array.from(strToColor.keys()).map((key) => ({
  label: Language.get(`colors.${key}`) as string,
  value: key,
})), [])

const FormGeneric = <T extends BaseEditionInformation>({
                       formType,
                       data,
                       workspace,
                       dashboardId,
                       environmentId,
                       altSubmit,
                       onConfirm,
                       onCancel,
                       title
                                                       }: FormGenericProps<T>) => {
  const cssClassOptions = useCssClassOptions()
  const [state, setState] = useState<State>({
    loading: true,
    availables: {
      menus: [],
      dashboardsPositions: [],
      menusPositions: [],
    },
    value: undefined,
  })
  const refFormBody = useRef<FormBodyRef | null>(null)

  const workflow = useMemo(
    () => new FormWorkflow(formType, {...data.extraConf, ...data}, (newState: State) => {
        setState(old => isEqual(newState, old) ? old : newState)
    }, title ?? '', workspace, dashboardId, environmentId),
    [setState, formType, data, title, workspace, dashboardId, environmentId],
  )

  const placeholder = useMemo(() => {
    switch (formType.type) {
      case FormKeys.ADD_MENU:
        return Language.get('form-title-new-menu')
      case FormKeys.ADD_DASHBOARD:
        return Language.get('form-title-new-dashboard')
      case FormKeys.CHART_CONF:
        return Language.get('new-chart-title')
      case FormKeys.EDIT_MENU:
        return Language.get('navigation-tools-edit-menu')
      default:
        return ''
    }
  }, [formType])

  const handleSubmit = useCallback(() => {
      workflow.saveConf()
      refFormBody.current?.validate().then(() => {
        const confResult = workflow.getConfResult() as BaseEditionInformation
        onConfirm(
          {
            ...confResult,
            title: confResult.title ?? placeholder,
            name: confResult.name ?? placeholder,
            extraConf: {
              ...confResult,
              cssClass: confResult.cssClass ?? cssClassOptions[0].value,
            },
          } as unknown as T,
        )
      }).catch(() => {
        console.log("catch")
      })
    },
    [cssClassOptions, onConfirm, placeholder, workflow])
  const handleUpdate = useCallback((key: string, value: string) => {
      workflow.update(key, value)
  }, [workflow])
  const handleCancel = onCancel

  useEffect(() => () => workflow.unsubscribe(), [workflow])

  const formFooter = <FormFooter {...{
    altSubmit: altSubmit ? {
      ...altSubmit,
      trigger: <Button type="primary" danger>{altSubmit.title}</Button>,
    } : undefined,
    cancel: handleCancel,
    submitting: false,
    submit: handleSubmit,
  }} />

  const isTitleEditable = useMemo(() => [
    FormKeys.ADD_MENU,
    FormKeys.EDIT_MENU,
    FormKeys.ADD_DASHBOARD,
    FormKeys.CLONE_DASHBOARD].includes(formType.type), [formType.type])

  const titleContent: ["name" | "title", string | undefined] = useMemo(() => {
    if (FormKeys.CHART_CONF === formType.type) {
      return ["name", Language.get("new-text-chart-title")]
    } else if ([FormKeys.ADD_MENU, FormKeys.EDIT_MENU, FormKeys.DELETE_MENU].includes(formType.type)) {
      return ["name", state?.value?.name]
    } else {
      return ["title", state?.value?.title ?? title]
    }
  }, [formType.type, state?.value?.name, state?.value?.title, title])

  const {loading, availables} = state

  return <Modal {...{
    title: <FormHeader value={titleContent?.[1]} onCancel={onCancel}
                       editable={isTitleEditable}
                       onChange={(newValue) => {
                         handleUpdate(titleContent?.[0], newValue)
                       }} placeholder={placeholder}/>,
    open: true,
    closable: false,
    onCancel,
    width: formType.type === FormKeys.CHART_CONF ? 1500 : 750,
    className: "conf-body",
    wrapClassName: "form",
    footer: formFooter,
  }}>
    {state.value && <FormBody ref={refFormBody} {...{
      formType,
      availables,
      loading,
      data: state.value,
      update: handleUpdate,
    }}/>}
  </Modal>
}

export default memo(FormGeneric) as typeof FormGeneric
