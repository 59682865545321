import {createSelector} from "reselect"
import * as UserSchemas from "schemas/user"
import {mapValues, sortBy} from "lodash"
import * as WorkspaceSelectors from 'redux/workspace.selector'
import {RootState} from "redux/store"
import {ClientWorkspace} from "redux/models/appEnvironment"
import {StringIndex} from "redux/models/workspace"
import {Environment} from "components/admin/user/UserManager.types"

export const getAssocClients = createSelector(
  (state: RootState) => state.user.entities,
  (entities) => sortBy(
    UserSchemas.denormalizeAssocClientResumes(Object.keys(entities.userAssocClients), entities),
    [({user}) => user.lastName.toLocaleLowerCase(), ({user}) => user.firstName.toLocaleLowerCase()],
  ),
)

export const getAcesByUserId = createSelector(
  (state: RootState) => state.user.acesByUserId,
  (state: RootState) => WorkspaceSelectors.getAvailablesForAce(state as never),
  (state: RootState) => state.appEnvironment?.environment,
  (acesByUserId, workspaces: ClientWorkspace[], environment: Environment) => {
    const workspacesById: StringIndex<ClientWorkspace> = workspaces.reduce((acc, w) => ({
      ...acc,
      [w.id]: w,
    }), {})
    const targetObjectByType = (type: string, id: number) => {
      if (type === 'environment' && id === environment.id) {
        return environment
      } else if (type === 'workspace') {
        return workspacesById[id]
      } else {
        return null
      }
    }
    return mapValues(acesByUserId, aces => aces.map((ace: any) => {
      const targetElements = ace.target.split(':')
      if (targetElements[0] === "datamodel") {
        const [targetType, environmentId, datamodelCode] = targetElements
        return ({
          ...ace,
          targetType,
          targetId: datamodelCode,
          targetObject: targetObjectByType(targetType, datamodelCode),
        })
      }
      const [targetType, id] = targetElements
      return ({
        ...ace,
        targetType,
        targetId: id,
        targetObject: targetObjectByType(targetType, id),
      })
    }))
  },
)
