import {QueryResponseDataWithCategory} from "services/QueryService"
import {OriginalSerie} from "components/charts/line/LineChart.types"
import {Children, Label, MetricDataNode, Values} from "classes/MetricDataNode"

import {Format, MetricWithMetricDef} from "@biron-data/bqconf"


// Metric data tree is the root node that exposes utility function to query parsed data and manage ingestion
export class MetricDataTree extends MetricDataNode {
  format: Format
  metric: MetricWithMetricDef
  private readonly numberOfXAxisSlicers // min 0 (bar chart with no slicer for instance)
  private readonly numberOfYAxisSlicers // min 0

  constructor(metricAlias: Label, metric: MetricWithMetricDef, format: Format, numberOfXAxisSlicers: number, numberOfYAxisSlicers: number, children?: Children, values?: Values) {
    super(metricAlias, false, values, false, false, false, metric.metricDef?.asRatio, children)
    this.format = format
    this.metric = metric
    this.numberOfXAxisSlicers = numberOfXAxisSlicers
    this.numberOfYAxisSlicers = numberOfYAxisSlicers
  }

  copy(children: Children): this {
    return new MetricDataTree(this.label, this.metric, this.format, this.numberOfXAxisSlicers, this.numberOfYAxisSlicers, children, this.values) as this
  }

  ingestDataRow(data: QueryResponseDataWithCategory, dateSlicerIndex: number, isAxisNumeric: boolean, metricIdx: number) {
    const metricData = data.slice(0, this.numberOfXAxisSlicers + this.numberOfYAxisSlicers).concat([data[this.numberOfXAxisSlicers + this.numberOfYAxisSlicers + metricIdx]])
    if (metricData.length === 1) { // special case when there is no slicer at all
      this.ingestRemainingDataRow(metricData, 0, 0)
    } else {
      let child = this.children.get(metricData[0] as string)
      if (!child) {
        // For now, we only have one axis, check if there is date on this axis to set the isDate property to false
        child = new MetricDataNode(metricData[0] as string, true, undefined, undefined, dateSlicerIndex === 0, isAxisNumeric)
        this.children = this.children.set(metricData[0] as string, child)
      }
      child.ingestRemainingDataRow(metricData.slice(1), this.numberOfXAxisSlicers - 1, this.numberOfYAxisSlicers)
    }
  }

  /**
   * if values are missing, we create node for all slicers and set default values
   * @param expectedLabels all expected values for a slicer,  all xAxis 'tick'
   * @param defaultValue
   */
  // for xAxis slicer at index,
  // returns a copy of MetricDataTree
  // currently only handles single Metric. To improve, expectedLabels should de double array to fill all xAxis at once
  // can take some time, could be done server side to improve perfs
  fillBlanks(expectedLabels: Label[], defaultValue = 0): this {
    // We build a map of level to unique label
    const labels = new Map<number, Set<Label>>()
    labels.set(0, new Set(expectedLabels))
    this.children.forEach((child => child.listChildLabels(labels, 1)))

    return super.fillBlanksAt(labels, new Array(this.numberOfXAxisSlicers).fill(defaultValue), 0, this.numberOfXAxisSlicers, this.numberOfYAxisSlicers)
  }

  getXAxisAt(index: number): Label[] {
    if (index > 0) {
      throw new Error("Not yet supported")
    }
    if (this.isLeaf()) {
      return [this.label]
    }
    return this.children.map(child => child.label).toIndexedSeq().toArray()
  }

  // Builds serie
  getSeries(xAxisIndex = 0): OriginalSerie[] {
    const seriesValues = new Map<Label, number[]>()
    // Meaning I am both a root and leaf node
    if (this.isLeaf()) {
      seriesValues.set(this.label, this.values as number[])
    } else {
      this.children.forEach((child) => child.buildSeriesValues(seriesValues, xAxisIndex, this.label))
    }

    return [...seriesValues].map(([dimensionValue, values]) => ({
      type: "original",
      label: dimensionValue,
      labelAlt: dimensionValue,
      values,
    }))
  }

  sortBySlicerValuesAt(index: number, asc = true): MetricDataTree {
    const nodeValues = new Map<string, number>()
    this.children.forEach((child) => child.buildNodeValues(nodeValues, index))

    return this.sortByNodeValue(nodeValues, index, asc) // One index before as we want to sort children
  }

  public isEmpty() {
    return this.isLeaf() && this.values.length === 0
  }
}
