import {EffectiveConfTypes} from "types/charts"
import {EffectiveConfSlicerTypes, OrderBy, ConfDimensionFilterTypesWithSource, ConfMetricFilterTypesWithSource} from "@biron-data/bqconf"

export const extractSlicersFromEffectiveConf = (effectiveConf: EffectiveConfTypes): EffectiveConfSlicerTypes[] | undefined => {
  switch (effectiveConf.type) {
    case "generic":
      return effectiveConf.slicers
    case "target":
      return undefined
    default:
      // eslint-disable-next-line no-case-declarations
      const exhaustiveCheck: never = effectiveConf
      return exhaustiveCheck
  }
}
export const extractFiltersFromEffectiveConf = (effectiveConf: EffectiveConfTypes): ConfDimensionFilterTypesWithSource[] => {
  switch (effectiveConf.type) {
    case "generic":
      return effectiveConf.filters
    case "target":
      return []
    default:
      // eslint-disable-next-line no-case-declarations
      const exhaustiveCheck: never = effectiveConf
      return exhaustiveCheck
  }
}
export const extractMetricFiltersFromEffectiveConf = (effectiveConf: EffectiveConfTypes): ConfMetricFilterTypesWithSource[] => {
  switch (effectiveConf.type) {
    case "generic":
      return effectiveConf.metricFilters
    case "target":
      return []
    default:
      // eslint-disable-next-line no-case-declarations
      const exhaustiveCheck: never = effectiveConf
      return exhaustiveCheck
  }
}
export const extractOrderByFromEffectiveConf = (effectiveConf: EffectiveConfTypes): OrderBy[] => {
  switch (effectiveConf.type) {
    case "generic":
      return effectiveConf.orderBys
    case "target":
      return []
    default:
      // eslint-disable-next-line no-case-declarations
      const exhaustiveCheck: never = effectiveConf
      return exhaustiveCheck
  }
}