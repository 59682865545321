import React from "react"
import {Breadcrumb, Button} from "antd";
import styled from "styled-components";
import {Navigate, Route, Routes} from "react-router-dom";
import DatamodelList from "./DatamodelList";
import DatamodelDetail from "./DatamodelDetail";

const DatamodelManager = () => {

  return <Routes>
    <Route path="/" element={<DatamodelList />}/>
    <Route path={`/:id`} element={<DatamodelDetail />}/>
    <Route
      path="*"
      element={<Navigate to="/" replace/>}
    />
  </Routes>
}

export default DatamodelManager
