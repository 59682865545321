import React, {useCallback, useMemo, useRef, useState} from 'react'
import FormModal from "components/forms/Form.Modal"
import {
  convertOrderByToConfOrderBy,
  formatFormDataToExportDto,
  uniqueViewOptions,
  useDimensionFilterConverterToConfModel,
} from "components/forms/chart/utils"
import {ExportExtendedConfModel, GenericExtendedConfModel, SimplifiedChartGenericFormProps} from "components/forms/chart/types"
import {uniq} from "lodash"
import {
  useRequirements,
  useMetricFilterConverterToConfModel,
} from "@biron-data/react-bqconf"
import {
  convertFilterDetailToConfFilter,
  extractSlicerDate,
  ConfSlicer,
  GenericChartTypes,
  MetricDtoDetail,
  convertMetricHavingToConfFilters
} from "@biron-data/bqconf"
import {ExportBasicDto, ExportBasicDtoForm, FormExportBasicConfProps} from "components/forms/Form.types"
import {getResolvedFromPeriod, predefinedPeriod, SimpleTimeIntervalWithSourceResolved, Granularity} from "@biron-data/period-resolver"
import {useGetPopupContainer} from "@biron-data/react-hooks"
import {ChartGenericExtraConf} from "types/charts"
import {useSelector} from "react-redux"
import {getPeriods} from "redux/period.selector"
import FormComponentGeneric from "components/forms/chart/FormComponentGeneric"
import Language from "language"
import {useAdditionalDetails, useHandlePartialFormDataChange} from "components/forms/chart/hooks"
import {convertMetricToConfModel} from "commons/parsers/utils"
import {useLanguageResolver} from "@biron-data/react-contexts"

const initDateSlicer = (data: ExportBasicDto) => extractSlicerDate(data.slicers)?.granularity ?? Granularity.MONTH

type InitialData =
  ExportBasicDtoForm
  & {
  slicers: ConfSlicer[],
  metrics: MetricDtoDetail[],
  dateSlicerGranularity: Granularity,
  uniqueView: string | null,
  extraConf?: ChartGenericExtraConf
}
const FormExportBasicCmp: (props: FormExportBasicConfProps) => JSX.Element | null = ({
                                                                                       data: originalData,
                                                                                       environmentId,
                                                                                       datamodel,
                                                                                       metaModel,
                                                                                       loadDictionaryEntries,
                                                                                       dashboard,
                                                                                       onConfirm,
                                                                                       onCancel,
                                                                                     }) => {
  const lr = useLanguageResolver()
  const title = Language.get('new-export-title')
  const periods = useSelector(getPeriods)
  const popupContainerRef = useRef(null)
  const dashboardSelection = useMemo(() => ({
    filters: [],
    slicers: [],
    initTimestamp: Date.now(),
    date: getResolvedFromPeriod(predefinedPeriod.LAST_7_DAYS) as SimpleTimeIntervalWithSourceResolved,
  }), [])
  const initialData = useMemo<InitialData>(
    () => {
      const usedViewsCode = uniq(originalData.metrics.map(({viewCode}) => viewCode))
      return ({
        ...originalData,
        slicers: originalData.slicers.map(slicer => ({
          ...slicer,
          isDefault: false,
        })),
        dateSlicerGranularity: initDateSlicer(originalData),
        uniqueView: (usedViewsCode.length === 1 && metaModel.getView(usedViewsCode[0])?.code) || null,
      })
    },
    [originalData, metaModel],
  )

  const [formData, setData] = useState<GenericExtendedConfModel>({
    ...initialData,
    period: periods.find(p => p.code === initialData.period?.code) ?? predefinedPeriod.CURRENT_MONTH,
    metrics: convertMetricToConfModel(initialData.metrics),
    filters: convertFilterDetailToConfFilter(initialData.filters),
    metricFilters: convertMetricHavingToConfFilters(initialData.metrics),
    orderBys: convertOrderByToConfOrderBy(initialData.orderBys),
  })

  const {
    availableViews: viewsWithMetrics,
    unavailableViews,
    requiredViewsCode,
    availableDimensions,
    unavailableDimensions,
  } = useRequirements(metaModel,
    datamodel,
    formData.uniqueView,
    formData.slicers,
    formData.metrics)

  const dimensionFilterConverter = useDimensionFilterConverterToConfModel(formData.filters, availableDimensions)
  const metricFilterConverter = useMetricFilterConverterToConfModel(metaModel, formData.metricFilters, !formData.uniqueView, viewsWithMetrics)

  const consolidatedFormData: GenericExtendedConfModel = useMemo(() => ({
    ...formData,
    metrics: convertMetricToConfModel(formData.metrics, availableDimensions),
    filters: dimensionFilterConverter(),
    metricFilters: metricFilterConverter(formData.metrics),
  }), [availableDimensions, dimensionFilterConverter, formData, metricFilterConverter])

  const getPopupContainer = useGetPopupContainer(popupContainerRef.current)

  const additionalDetails = useAdditionalDetails(formData.type, formData.displayType)

  const formProps = useMemo<SimplifiedChartGenericFormProps>(() => ({
      uniqueViewOptions: uniqueViewOptions(viewsWithMetrics),
      metricInvertible: formData.displayType === GenericChartTypes.TABLES,
      loadDictionaryEntries,
      metaModel,
      environmentId,
      datamodel,
      viewsWithMetrics,
      unavailableViews,
      requiredViewsCode,
      additionalDetails,
      availableDimensions,
      unavailableDimensions,
      getPopupContainer,
      dashboard,
      dashboardSelection,
    }),
    [metaModel, formData.displayType, loadDictionaryEntries, environmentId, viewsWithMetrics, unavailableViews, requiredViewsCode, additionalDetails, availableDimensions, unavailableDimensions, getPopupContainer, dashboard, dashboardSelection],
  )

  const handleConfirm = useCallback(
    (newData: ExportExtendedConfModel) => {
      onConfirm(formatFormDataToExportDto({
        ...formData,
        ...newData,
        displayType: GenericChartTypes.TABLES,
      }, viewsWithMetrics, !formData.uniqueView, metaModel, title, availableDimensions, lr))
    }, [availableDimensions, formData, lr, metaModel, onConfirm, title, viewsWithMetrics],
  )

  const handlePartialFormDataChange = useHandlePartialFormDataChange(setData)

  return <FormModal<GenericExtendedConfModel, SimplifiedChartGenericFormProps> {...{
    ref: popupContainerRef,
    defaultTitle: title,
    isTitleEditable: true,
    width: 1500,
    data: consolidatedFormData,
    setData: handlePartialFormDataChange,
    renderFormComponent: (props) => <FormComponentGeneric {...props}/>,
    onConfirm: handleConfirm,
    onCancel,
    formProps,
  }} />
}

export default FormExportBasicCmp
