import {Button} from 'antd'
import React, {FunctionComponent, useCallback} from "react"
import styled from "styled-components"
import {User} from "components/admin/user/UserManager.types"

interface Props {
  user: User
  onUserNameClick?: (user: User) => void
  displayEmail: boolean
}

const UserManagerInfo: FunctionComponent<Props> = ({user, onUserNameClick, displayEmail}) => {
  const handleEditClick = useCallback(() => {
    onUserNameClick?.(user)
  }, [onUserNameClick, user])
  return <StyledDiv>
    {onUserNameClick
      ? <ButtonWithoutPadding type="link" onClick={handleEditClick}>{`${user.firstName} ${user.lastName}`}</ButtonWithoutPadding>
      : `${user.firstName} ${user.lastName}`
    }
    {displayEmail && <Email className="email">{user.email}</Email>}
  </StyledDiv>
}

export default UserManagerInfo

const Email = styled.div`
  padding-left: 15px;
`

const ButtonWithoutPadding = styled(Button)`
    padding: 0;
`

const StyledDiv = styled.div`
  position: relative;
  
  > .ant-btn-icon-only {
    position: absolute;
    top: 0px;
    right: 0px;
  }
  
  > .inactive {
    color: var(--negative);
    opacity: .8;
    font-size: 12px;
    margin-left: 10px;
  }
  
  > .email {
    opacity: .45;
  }
`
