import React, {FunctionComponent, useCallback} from 'react'
import {loadDictionaryEntries as loadDictionaryEntriesService} from "services/MetaModelService"
import FormDashboardConfCmp from "./FormDashboardConfCmp"
import {FormDashboardConfProps, FormKeys} from "components/forms/Form.types"
import {OptionListPagination} from "@biron-data/react-bqconf"

const FormDashboardConf: FunctionComponent<FormDashboardConfProps> = ({environmentId, datamodelCodes, onConfirm, onCancel, data, metaModel}) => {
  const loadDictionaryEntries = useCallback(
    (dimensionCode: string, search?: string, pagination?: OptionListPagination) => loadDictionaryEntriesService(environmentId, dimensionCode, datamodelCodes, search, pagination),
    [datamodelCodes, environmentId],
  )

  return <FormDashboardConfCmp {...{
    formType: {
      type: FormKeys.DASHBOARD_CONF,
    },
    data,
    metaModel,
    datamodelCodes,
    loadDictionaryEntries,
    environmentId,
    onCancel,
    onConfirm,
  }}/>
}


export default FormDashboardConf
