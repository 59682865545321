/* eslint-disable max-lines */
import React, {forwardRef, MutableRefObject, useCallback, useMemo} from "react"
import {EChartOption} from "echarts"
import {
  DISTANCE_BETWEEN_CHART_TOP_AND_LEGEND,
  HIDE_SYMBOLS_THRESHOLD,
  LINE_CHART_LEGEND_THRESHOLD,
  OPACITY,
  preventInfinite,
} from "components/charts/Chart.constants"
import EChartContainer from "components/charts/Chart.Container"
import ChartBase, {BaseChartRef} from "components/charts/Chart.Base"
import {percentageFormat} from "commons/format/formatter"
import {lineChartTooltip} from "components/charts/line/LineChart.utils"
import {ChartSelection} from "classes/workflows/query-workflows/QueryWorkflow"
import {seriesAsPercentage} from "classes/workflows/query-workflows/aggregatedQueryWorkflow"
import {
  MetricDataTreeWithSeries,
  overlaidAxis,
  overlaidLineSeries,
  SerieInfo,
  useAxisFormat,
  useAxisLabel,
  useColors,
  useFillBlanks,
  useGetSeriesLabel,
  useLegend,
  useLineChartColors,
  useLineChartRawParsedMetrics,
  useSeriesMappedToAxis,
  useSumOfTreeSeries, useVirtualMap,
  useXAxis,
} from "components/charts/Chart.utils"
import {standardYAxisOptions} from "components/charts/Chart.options"
import {CategoryAxis, Hoverdata, ValuesAxis} from "components/charts/bar/BarChart"
import {ELineChartProps, OriginalSerie, RawChartData} from "components/charts/line/LineChart.types"
import {extractSlicerDate} from "@biron-data/bqconf"
import {isGradientAvailable, isIntervalAvailable} from "../../forms/chart/useChartTypes.utils";

export const ELineChart = forwardRef<BaseChartRef, ELineChartProps>(function ELineChart(props, ref) {
  const {extraConf} = props.rawChartData.meta
  const orderBy = useMemo(() => props.rawChartData.meta.effectiveConf.orderBys.length > 0 ? props.rawChartData.meta.effectiveConf.orderBys[0] : undefined, [props.rawChartData.meta.effectiveConf.orderBys])
  const [firstLimit, secondLimit] = useMemo(() => extraConf.limits && extraConf.limits?.length > 0 ? extraConf.limits : [], [extraConf.limits])
  const slicers = useMemo(() => props.rawChartData.meta.effectiveConf.slicers, [props.rawChartData.meta.effectiveConf.slicers])
  const metrics = useMemo(() => props.rawChartData.meta.effectiveConf.metrics, [props.rawChartData.meta.effectiveConf.metrics])
  const isFirstSlicerOfTypeDate = useMemo(() => slicers.length > 0 && slicers[0].type === "date", [slicers])
  const hasSlicer = useMemo(() => slicers.length > 0, [slicers])
  const firstSlicerIndex = 0
  const secondSlicerIndex = slicers.length > 0 ? 1 : -1
  const dateSlicer = extractSlicerDate(slicers)
  const includeLegend = (slicers.length - (dateSlicer ? 1 : 0)) > 0 || metrics.length > 1

  const parsedDataWithBlanksFilled = useFillBlanks(props.rawChartData.parsedData, isFirstSlicerOfTypeDate, hasSlicer, props.rawChartData.expectedDates)

  const indexOfSortedMetrics = useMemo(() => [...Array(metrics.length).keys()], [metrics.length])

  const sumOfValues = useSumOfTreeSeries(parsedDataWithBlanksFilled, indexOfSortedMetrics)

  const parsedMetrics = useLineChartRawParsedMetrics(parsedDataWithBlanksFilled, metrics.length, slicers.length, firstLimit, secondLimit, firstSlicerIndex, secondSlicerIndex, orderBy, sumOfValues)
  const metricsInDisplayOrder = useMemo(() => parsedMetrics.map(p => p.metric), [parsedMetrics])

  const formattedMetrics: MetricDataTreeWithSeries<OriginalSerie>[] = useMemo(() => parsedMetrics.map(parsedMetric => ({
    ...parsedMetric,
    series: parsedMetric.getSeries(),
  }) as MetricDataTreeWithSeries<OriginalSerie>), [parsedMetrics])

  const seriesLabel = useMemo(() => formattedMetrics[0].series.map((serie) => serie.label), [parsedMetrics])
  const initialColors = useColors(1, parsedMetrics[0],
    slicers.length > 1 ? seriesLabel : [])
  const metricColors = useMemo(() => props.rawChartData.parsedData.map(data => data.metric.extraConf?.color), [props.rawChartData.parsedData])

  const colors = useLineChartColors(slicers.length, initialColors, seriesLabel, metricColors)

  const [originalYAxis, serieInfos] = useSeriesMappedToAxis(formattedMetrics)

  const yAxis = useMemo(() => props.asArea ? originalYAxis.map(axis => ({
    ...axis,
    min: standardYAxisOptions().min,
    max: standardYAxisOptions().max,
  } as ValuesAxis)) : originalYAxis as ValuesAxis[], [originalYAxis, props.asArea])

  const alternativeDisplays: SerieInfo<OriginalSerie>[] = useMemo(() => props.selection?.asPercentage && !parsedMetrics.find(parsedMetric => parsedMetric.format.asRatio) ? serieInfos.map(serieMappedToAxis => ({
      ...serieMappedToAxis,
      series: seriesAsPercentage(serieMappedToAxis.series),
      format: percentageFormat,
    }) as SerieInfo<OriginalSerie>) : []
    , [parsedMetrics, props.selection?.asPercentage, serieInfos])

  const symbol = 'circle'

  const alternativeFormats = useMemo(() => alternativeDisplays.flatMap(alternativeDisplay => alternativeDisplay.format), [alternativeDisplays])
  const displayedFormats = useMemo(() => serieInfos.flatMap((ss) => ss.format), [serieInfos])

  const xAxis = useXAxis(hasSlicer, parsedMetrics)

  const isLegendAtTop = useCallback(() => {
    const echarts = (ref as MutableRefObject<BaseChartRef>)?.current?.getEchartsInstance()
    const legend = echarts?.getOption().legend as { orient: string, show: boolean }[]

    if (legend && legend.length) {
      return legend[0].orient === "horizontal" && legend[0].show
    }
    return undefined
  }, [ref])

  const displayedSeries = useMemo(() => alternativeDisplays.length > 0 ? alternativeDisplays : serieInfos, [alternativeDisplays, serieInfos])

  const axisLabel = useAxisLabel(xAxis, firstLimit, slicers, props.dimensions.height)

  const categoryAxis: CategoryAxis[] = useMemo(() => [{
    type: "category", // time axis formatting isn't suitable
    boundaryGap: false,
    axisPointer: {
      label: {
        show: false,
      },
    },
    minorTick: {
      show: false,
    },
    splitLine: {
      show: false,
      lineStyle: {
        color: "#DCE0E4",
      },
    },
    axisTick: {
      alignWithLabel: true,
    },
    axisLabel,
    axisLine: {
      lineStyle: {
        color: "#DCE0E4",
      },
    },
  }], [axisLabel])

  const getSeriesLabel = useGetSeriesLabel(1)

  const getXAxisValues = useCallback((displayedSerieIndex: number) => parsedMetrics[displayedSerieIndex].getXAxisAt(0), [parsedMetrics])
  const getXAxisValuesAt = useCallback((index: number, displayedSerieIndex: number) => getXAxisValues(displayedSerieIndex)[index], [getXAxisValues])

  const renderedSeries: EChartOption.SeriesLine[] = useMemo(() => displayedSeries.flatMap((displayedSerie, displayedSerieIndex) => displayedSerie.series.map((serie, idx) => {
    return {
      yAxisIndex: yAxis.length > 1 ? displayedSerie.axisIndex : undefined,
      triggerLineEvent: true,
      label: getSeriesLabel(displayedFormats[displayedSerieIndex], alternativeFormats[displayedSerieIndex], props.selection?.displayLabels),
      smooth: true,
      name: serie.label,
      lineStyle: {
        opacity: OPACITY,
        width: 1.5,
      },
      itemStyle: {
        opacity: OPACITY,
      },
      type: 'line',
      symbol: serie.values.length < HIDE_SYMBOLS_THRESHOLD ? symbol : 'none',
      symbolSize: 4,
      data: serie.values.map((value, i) => {
        return [
          getXAxisValuesAt(i, displayedSerieIndex),
          preventInfinite(value, Number(yAxis[displayedSerie.axisIndex].min), Number(yAxis[displayedSerie.axisIndex].max)),
          serie.isOther ? 1 : 0,
          serieInfos[displayedSerieIndex].series[idx]?.values[i],
          alternativeDisplays[displayedSerieIndex]?.series?.[idx]?.values[i], // dimension 4, originalValue retrieved from second serie that may or may not exist
        ]
        },
      ),
      areaStyle: props.asArea ? {
        opacity: OPACITY,
      } : undefined,
      stack: props.asArea ? "stack" : undefined,
    }
  })), [alternativeDisplays, alternativeFormats, displayedFormats, displayedSeries, getSeriesLabel, getXAxisValuesAt, props.asArea, props.selection?.displayLabels, serieInfos, yAxis])

  const mouseoverCallBack = useCallback((data?: Hoverdata) => {
    if (data) {
      const echarts = (ref as MutableRefObject<BaseChartRef>)?.current.getEchartsInstance()
      echarts?.setOption({
        ...(serieInfos.length > 1 ? ({
          yAxis: overlaidAxis(data, serieInfos, yAxis),
          series: overlaidLineSeries(renderedSeries, data.yAxisIndex),
        }) : {}),
        tooltip: lineChartTooltip(yAxis.length, serieInfos, props.rawChartData.meta.effectiveConf, displayedFormats, alternativeFormats, data),
      })
    }
  }, [alternativeFormats, displayedFormats, props.rawChartData.meta.effectiveConf, ref, renderedSeries, serieInfos, yAxis])

  const mouseoutCallback = useCallback(() => {
    const echarts = (ref as MutableRefObject<BaseChartRef>)?.current.getEchartsInstance()
    echarts?.setOption({
      yAxis,
      series: renderedSeries,
      tooltip: lineChartTooltip(yAxis.length, serieInfos, props.rawChartData.meta.effectiveConf, displayedFormats, alternativeFormats),
    })
  }, [alternativeFormats, displayedFormats, props.rawChartData.meta.effectiveConf, ref, renderedSeries, serieInfos, yAxis])

  const tooltip = useMemo(() => lineChartTooltip(yAxis.length, serieInfos, props.rawChartData.meta.effectiveConf, displayedFormats, alternativeFormats), [alternativeFormats, displayedFormats, props.rawChartData.meta.effectiveConf, serieInfos, yAxis])

  const legend = useLegend(
    includeLegend,
    isGradientAvailable(extraConf.displayType, slicers.length) && isIntervalAvailable(extraConf.displayType, slicers.length),
    serieInfos,
    symbol,
    true)

  const visualMap = useVirtualMap(
    isGradientAvailable(extraConf.displayType, slicers.length) && isIntervalAvailable(extraConf.displayType, slicers.length),
    metricsInDisplayOrder,
    originalYAxis[0].min as number,
    originalYAxis[0].max as number
  )

  const options: EChartOption = useMemo(() => {
    return {
      tooltip,
      yAxis,
      legend,
      xAxis: categoryAxis,
      grid: {
        top: props.selection.displayLabels && !isLegendAtTop() ? DISTANCE_BETWEEN_CHART_TOP_AND_LEGEND : undefined,
      },
      visualMap,
      series: renderedSeries,
      color: colors,
    }
    // we force dimensions as dep so echart does redraw
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tooltip, props.asArea, props.selection.displayLabels, yAxis, legend, isLegendAtTop, renderedSeries, colors, xAxis, firstLimit?.limitSeries, firstLimit?.hideOthers, props.dimensions, slicers])

  const axisFormats = useAxisFormat(yAxis, serieInfos)

  return <ChartBase ref={ref}
                    option={options}
                    dimensions={props.dimensions}
                    yAxisFormats={alternativeFormats.length > 0 ? alternativeFormats : axisFormats}
                    includeLegend={legend?.show}
                    events={{
                      mouseover: mouseoverCallBack,
                      mouseout: mouseoutCallback,
                    }}
                    sideLegend={props.dimensions.width > LINE_CHART_LEGEND_THRESHOLD}
  />
})

export interface GenericEChartProps {
  chartData: RawChartData
  dimensions: {
    height: number,
    width: number,
  }
  selection: ChartSelection
  withSummary: boolean
}

export default forwardRef<any, GenericEChartProps>(function LineChart(props, ref) {
  return <EChartContainer ref={ref}
                          chart={ELineChart}
                          rawChartData={props.chartData}
                          dimensions={props.dimensions}
                          selection={props.selection}
                          withSummary={props.withSummary}/>
})
