/* eslint-disable max-lines */
import {useMemo} from "react"
import {
  MetaModelView,
  DimensionOption,
  hasMultipleViews,
  ConfOrderBy,
  ConfSlicer,
  GenericChartTypes,
  GroupedOptions,
  MetricDtoDetail,
  getMetricLabel,
  getSortOptionByIndex,
} from "@biron-data/bqconf"
import {
  dateOption,
} from "@biron-data/react-bqconf"
import {ConfLimit} from "types/widgets"
import {
  createSlicers,
  createSortFromCache,
  getAvailableAdditionalDetails,
  getColumnIndexByLabel,
  getDimensionLabel,
  sortSlicersAxisFirst,
} from "components/forms/chart/utils"
import {Granularity} from "@biron-data/period-resolver"
import {
  GenericAdditionalDetails,
  CacheElementTypes,
  ChartTypeWithDisabledReason,
  ConfigCache,
  ConfigurationLimitWithDisablingReasons,
  ConfigurationOrderByWithDisablingReasons,
  DimensionCacheElement,
  SlicerConfiguration,
} from "components/forms/chart/types"
import {isEmpty} from "@biron-data/react-components"
import {WidgetTypes} from "commons/dashboard/dashboard.types"

const isSecondaryLimitDefinedByUser = (secondarySlicer: DimensionCacheElement) => secondarySlicer.limit !== undefined || (secondarySlicer.limit === undefined && secondarySlicer.limitDefault === false)

const validateHideOtherProperty = (hideOther: boolean, availableLimitConfiguration: Pick<ConfigurationLimitWithDisablingReasons, 'displaySlicerOtherOption'>) => availableLimitConfiguration.displaySlicerOtherOption ? hideOther : true

const validateLimit = (
  limit: Pick<CacheElementTypes, 'limit' | 'hideOthers'>,
  availableLimitConfiguration: Pick<ConfigurationLimitWithDisablingReasons, 'displaySlicerOtherOption' | 'default'>,
  hideOthers: boolean,
  isDefault: boolean) => availableLimitConfiguration.default?.enabled ? {
  limit: limit.limit,
  hideOthers: limit.hideOthers === undefined ? hideOthers : validateHideOtherProperty(limit.hideOthers, availableLimitConfiguration),
  isDefault,
} : {
  limit: undefined,
  hideOthers: true,
  isDefault: true,
}

const doubleLimitFromDefinedCacheLimits = (availableLimitConfiguration: ConfigurationLimitWithDisablingReasons[], slicerAxis: DimensionCacheElement, secondarySlicer: DimensionCacheElement) => {
  const firstLimit = validateLimit(slicerAxis, availableLimitConfiguration[0], true, false)
  const secondLimit = validateLimit(secondarySlicer, availableLimitConfiguration[1], false, false)
  return [consolidateLimit(
    firstLimit.limit,
    firstLimit.hideOthers,
    firstLimit.isDefault,
    availableLimitConfiguration[0].mandatory,
    availableLimitConfiguration[0].default?.enabled,
  ), isSecondaryLimitDefinedByUser(secondarySlicer) ? consolidateLimit(
    secondLimit.limit,
    secondLimit.hideOthers,
    secondLimit.isDefault,
    availableLimitConfiguration[1].mandatory,
    availableLimitConfiguration[1].default?.enabled,
  ) : consolidateLimit(
    undefined,
    !availableLimitConfiguration[1],
    true,
    availableLimitConfiguration[1].mandatory,
    availableLimitConfiguration[1].default?.enabled,
  )]
}

const axisAndSlicerLimitFromCacheSingleNotAxisLimit = (availableLimitConfiguration: ConfigurationLimitWithDisablingReasons[], secondarySlicer: DimensionCacheElement) => {
  const firstLimit = validateLimit({
    limit: availableLimitConfiguration[0].default?.value,
    hideOthers: !availableLimitConfiguration[0].displaySlicerOtherOption,
  }, availableLimitConfiguration[0], true, true)
  const secondLimit = validateLimit(secondarySlicer, availableLimitConfiguration[1], false, secondarySlicer.isDefault)
  return [consolidateLimit(
    firstLimit.limit,
    firstLimit.hideOthers,
    firstLimit.isDefault,
    availableLimitConfiguration[0].mandatory,
    availableLimitConfiguration[0].default?.enabled,
  ), isSecondaryLimitDefinedByUser(secondarySlicer) ? consolidateLimit(
    secondLimit.limit,
    secondLimit.hideOthers,
    secondLimit.isDefault,
    availableLimitConfiguration[1].mandatory,
    availableLimitConfiguration[1].default?.enabled,
  ) : consolidateLimit(
    availableLimitConfiguration[1].default?.value,
    !availableLimitConfiguration[1].displaySlicerOtherOption,
    true,
    availableLimitConfiguration[1].mandatory,
    availableLimitConfiguration[1].default?.enabled,
  )]
}

const axisLimitFromDefinedCacheLimit = (availableLimitConfiguration: ConfigurationLimitWithDisablingReasons[], slicerAxis: DimensionCacheElement) => {
  const firstLimit = validateLimit(slicerAxis, availableLimitConfiguration[0], true, false)
  return [
    consolidateLimit(
      firstLimit.limit,
      firstLimit.hideOthers,
      firstLimit.isDefault,
      availableLimitConfiguration[0].mandatory,
      availableLimitConfiguration[0].default?.enabled,
    ),
  ]
}

const consolidateLimit = (
  limitSeries: number | undefined,
  hideOthers: boolean,
  isDefault: boolean,
  isMandatory: boolean,
  isEnabled?: boolean,
) => {
  if (!isEnabled) {
    return {
      limitSeries: undefined,
      hideOthers,
      isDefault,
    }
  }
  return {
    limitSeries: isMandatory ? limitSeries ?? 100 : limitSeries,
    hideOthers,
    isDefault,
  }
}

export const useLimits = (
  cache: ConfigCache,
  metrics: Pick<MetricDtoDetail, 'metricCode'>[],
  slicers: ConfSlicer[],
  availableLimitConfiguration: ConfigurationLimitWithDisablingReasons[],
  displayType?: GenericChartTypes): ConfLimit[] => useMemo(() => {
    if (!displayType) {
      return []
    }
    const consolidatedSlicers = slicers.map(s => cache.slicers.find(cacheSlicer => cacheSlicer.code === (s.type === "dimension" ? s.dimensionCode : "date")))
    const consolidatedMetrics = metrics.map(m => cache.metrics.find(cacheMetric => cacheMetric.code === m.metricCode))

    if (consolidatedSlicers.length === 0 && consolidatedMetrics.length > 0 && consolidatedMetrics?.[0]?.limit && !consolidatedMetrics?.[0]?.limitDefault) {
      return [{
        limitSeries: consolidatedMetrics[0].limit,
        hideOthers: consolidatedMetrics[0].hideOthers ?? false,
        isDefault: false,
      }]
    }
    switch (displayType) {
      case GenericChartTypes.BARS:
      case GenericChartTypes.SCATTER:
      case GenericChartTypes.AREA:
      case GenericChartTypes.LINE: {
        const secondarySlicer = cache && consolidatedSlicers.find((s, i) => i !== 0)
        const firstSlicer = cache && consolidatedSlicers.find((s, i) => i === 0)

        if (secondarySlicer && (firstSlicer?.limitDefault === false || secondarySlicer.limitDefault === false) && availableLimitConfiguration.length > 1) {
          if (firstSlicer && !firstSlicer.limitDefault) {
            return doubleLimitFromDefinedCacheLimits(availableLimitConfiguration, firstSlicer, secondarySlicer)
          } else {
            return axisAndSlicerLimitFromCacheSingleNotAxisLimit(availableLimitConfiguration, secondarySlicer)
          }
        } else if (firstSlicer && firstSlicer.limitDefault === false && availableLimitConfiguration.length === 1) {
          return axisLimitFromDefinedCacheLimit(availableLimitConfiguration, firstSlicer)
        }
        return availableLimitConfiguration.map(conf => {
          return consolidateLimit(
            conf.default?.value,
            !conf.displaySlicerOtherOption,
            true,
            conf.mandatory,
            conf.default?.enabled
          )
        })
      }
      case GenericChartTypes.PIE: {
        const firstSlicer = cache && consolidatedSlicers.find((s, i) => i === 0)
        if ((!firstSlicer || firstSlicer.limit === undefined || firstSlicer.limitDefault) && availableLimitConfiguration.length > 0) {
          return availableLimitConfiguration[0] ? [consolidateLimit(
            availableLimitConfiguration[0].default?.value,
            !availableLimitConfiguration[0].displaySlicerOtherOption,
            true,
            availableLimitConfiguration[0].mandatory,
            availableLimitConfiguration[0].default?.enabled,
          )] : []
        }
        return firstSlicer ? [consolidateLimit(
          firstSlicer.limit,
          firstSlicer.hideOthers ?? false,
          false,
          availableLimitConfiguration.length > 0 ? availableLimitConfiguration[0].mandatory : false,
          availableLimitConfiguration.length > 0 ? availableLimitConfiguration[0].default?.enabled : undefined,
        )] : []
      }
      case GenericChartTypes.BOXES:
      case GenericChartTypes.GAUGE:
      case GenericChartTypes.TABLES: {
        const appliedLimit = consolidatedMetrics.find((metric) => metric?.limit !== undefined)
        if (appliedLimit && !appliedLimit.limitDefault) {
          return availableLimitConfiguration.length > 0 && availableLimitConfiguration[0] ? [consolidateLimit(
            appliedLimit.limit,
            !availableLimitConfiguration[0].displaySlicerOtherOption,
            false,
            availableLimitConfiguration[0].mandatory,
            availableLimitConfiguration[0].default?.enabled,
          )] : []
        } else {
          return availableLimitConfiguration.length > 0 && availableLimitConfiguration[0] ? [consolidateLimit(
            availableLimitConfiguration[0].default?.value,
            !availableLimitConfiguration[0].displaySlicerOtherOption,
            true,
            availableLimitConfiguration[0].mandatory,
            availableLimitConfiguration[0].default?.enabled,
          )] : []
        }
      }
      default: {
        const exhaustiveCheck: never = displayType
        return exhaustiveCheck
      }
    }
  },
  [cache, displayType, availableLimitConfiguration, metrics, slicers])

export const useSlicers = (
  cache: ConfigCache,
  granularity: Granularity | null,
  slicerConfiguration?: SlicerConfiguration,
  displayType?: GenericChartTypes): ConfSlicer[] => useMemo(() => {
  if (!displayType) {
    return []
  }
  const slicers = createSlicers(cache.slicers, granularity)
  switch (displayType) {
    case GenericChartTypes.AREA:
    case GenericChartTypes.LINE: {
      const defaultSlicer = slicerConfiguration?.disablingReasons
      && Object.values(slicerConfiguration?.disablingReasons).filter(Boolean)
        ? slicerConfiguration.value
        : undefined
      const isMultiMetric = cache.metrics.length === 1 || cache.slicers.length === 0
      return defaultSlicer && (cache.slicers.length <= 1 && isMultiMetric && !cache.slicers.some(s => s.isDefault || s.hasRefDate)) ? [{
        ...defaultSlicer,
        isDefault: true,
      }, ...slicers] : createSlicers(sortSlicersAxisFirst(cache.slicers), granularity)
    }
    case GenericChartTypes.BOXES:
    case GenericChartTypes.GAUGE:
    case GenericChartTypes.PIE: {
      const hasMultipleSlicer = cache.slicers.length > 1
      const hasMultipleAxisSlicer = cache.slicers.filter(s => s.isAxis).length > 1
      const axisSlicerIndex = cache.slicers.findIndex(s => s.isAxis)
      const slicersWithoutAxis = (hasMultipleSlicer || hasMultipleAxisSlicer) && axisSlicerIndex !== -1 ? slicers.filter((s, i) => i !== axisSlicerIndex) : slicers
      return slicersWithoutAxis.filter(slicer => !slicer.isDefault).splice(0, 1)
    }
    case GenericChartTypes.BARS: {
      return slicers.filter(slicer => !slicer.isDefault).splice(0, 2)
    }
    case GenericChartTypes.SCATTER: {
      return slicers.filter(slicer => !slicer.isDefault)
    }
    case GenericChartTypes.TABLES: {
      return slicers.filter(slicer => !slicer.isDefault)
    }
    default: {
      const exhaustiveCheck: never = displayType
      return exhaustiveCheck
    }
  }
}, [cache.metrics.length, cache.slicers, displayType, granularity, slicerConfiguration])

export const useOrderBys = (
  cache: ConfigCache,
  viewsWithMetrics: MetaModelView[],
  groupedOption: GroupedOptions,
  availableSortsConfiguration?: ConfigurationOrderByWithDisablingReasons[],
  displayType?: GenericChartTypes): ConfOrderBy[] => useMemo(() => {
  if (!displayType) {
    return []
  }
  switch (displayType) {
    case GenericChartTypes.AREA:
    case GenericChartTypes.LINE:
    case GenericChartTypes.BARS:
    case GenericChartTypes.SCATTER:
    case GenericChartTypes.PIE: {
      const firstSortOnMetric = cache.metrics.find(m => m.orderByOrder === 0 && !m.orderByDefault)
      const firstSortOnSlicers = cache.slicers.find(m => m.orderByOrder === 0 && !m.orderByDefault)

      if ((firstSortOnMetric || firstSortOnSlicers) && availableSortsConfiguration?.find(configuration => configuration.editable)) {
        if (firstSortOnMetric) {
          const opt = getSortOptionByIndex(groupedOption, cache.slicers.length)
          return [{
            column: cache.slicers.length,
            asc: Boolean(firstSortOnMetric.orderByAsc),
            isDefault: false,
            value: opt?.label,
            id: opt?.id,
          }]
        }

        if (firstSortOnSlicers && firstSortOnSlicers.orderByAsc) {
          const opt = getSortOptionByIndex(groupedOption, 0)
          return [{
            column: 0,
            asc: true,
            isDefault: false,
            value: opt?.label,
            id: opt?.id,
          }]
        }
      }
      return availableSortsConfiguration ? availableSortsConfiguration.map(conf => {
        const opt = getSortOptionByIndex(groupedOption, conf.default.column)
        return {
          isDefault: true,
          column: conf.default.column,
          asc: conf.default.asc,
          value: opt?.label,
          id: opt?.id,
        }
      }) : []
    }
    case GenericChartTypes.BOXES:
    case GenericChartTypes.GAUGE:
    case GenericChartTypes.TABLES: {
      const hasSortApplied = [...cache.slicers, ...cache.metrics].find(element => element.orderByIndex !== undefined && element.orderByOrder !== undefined && element.orderByOrder !== -1 && !element.orderByDefault)
      if (hasSortApplied) {
        return createSortFromCache(cache)
      } else {
        if (availableSortsConfiguration
          && availableSortsConfiguration.length > 0
          && availableSortsConfiguration[0]
          && availableSortsConfiguration[0].default.column === 0) {
          if (cache.slicers.find(slicer => slicer.hasRefDate)) {
            const index = getColumnIndexByLabel(dateOption.label, groupedOption)
            const opt = getSortOptionByIndex(groupedOption, index)
            return [{
              asc: false,
              column: index,
              isDefault: true,
              value: opt?.label,
              id: opt?.id,
            } as ConfOrderBy]
          } else {
            if (cache.metrics.length > 0) {
              const isMultiView = hasMultipleViews(cache.metrics)
              const index = getColumnIndexByLabel(getMetricLabel(viewsWithMetrics, {
                ...cache.metrics[0],
                metricCode: `${cache.metrics[0].code}`,
              }, isMultiView), groupedOption)
              const opt = getSortOptionByIndex(groupedOption, index)
              return [{
                asc: false,
                column: index,
                isDefault: true,
                value: opt?.label,
                id: opt?.id,
              } as ConfOrderBy]
            } else {
              return []
            }
          }
        } else {
          return []
        }
      }
    }

    default: {
      const exhaustiveCheck: never = displayType
      return exhaustiveCheck
    }
  }
}, [availableSortsConfiguration, cache, displayType, groupedOption, viewsWithMetrics])


export const useMetricFilters = (
  cache: Pick<ConfigCache, "metricFilters">,
  configuration?: Pick<ChartTypeWithDisabledReason, "isMetricsFilterEnabled">,
  displayType?: GenericChartTypes) => useMemo(() => {
  if (displayType === undefined) {
    return []
  }
  if (configuration?.isMetricsFilterEnabled) {
    return cache.metricFilters
  } else {
    return []
  }
}, [cache.metricFilters, configuration?.isMetricsFilterEnabled, displayType])

// export const useMetricWithFilters = (
//   cache: Pick<ConfigCache, "metrics">,
//   configuration?: Pick<ChartTypeWithDisabledReason, "isMetricsFilterEnabled">,
//   displayType?: GenericChartTypes): { [code: string]: RangeQueryPredicate | ScalarQueryPredicate | undefined } => useMemo(() => {
//   if (cache.metrics.length === 0) {
//     return {}
//   }
//   if (displayType === undefined) {
//     return Object.fromEntries(cache.metrics.map(m => ([m.id, undefined])))
//   }
//   if (configuration?.isMetricsFilterEnabled) {
//     return Object.fromEntries(cache.metrics.map(m => ([m.id, m.having])))
//   } else {
//     return Object.fromEntries(cache.metrics.map(m => ([m.id, undefined])))
//   }
// }, [cache.metrics, configuration?.isMetricsFilterEnabled, displayType])

export const useGroupedSortOptions = (
  cache: ConfigCache,
  viewsWithMetrics: MetaModelView[],
  dimensionOptions: DimensionOption[]): GroupedOptions => {
  const isMultiView = hasMultipleViews(cache.metrics)

  return useMemo(() => ({
    slicers: cache.slicers.map((slicer, index) => {
      if (slicer.code === "date") {
        return {
          label: dateOption.label,
          value: index,
          disabled: slicer.orderByOrder !== undefined,
          id: slicer.id,
        }
      }
      return {
        label: slicer.code ? getDimensionLabel(dimensionOptions, {
          type: "dimension",
          dimensionCode: `${slicer.code}`,
        } as ConfSlicer) : "",
        value: index,
        disabled: slicer.orderByOrder !== undefined,
        id: slicer.id,
      }
    }),
    metrics: cache.metrics.map((metric, index) => {
      const metricLabel = getMetricLabel(viewsWithMetrics, {...metric, metricCode: `${metric.code}`}, isMultiView)
      return {
        label: metricLabel,
        value: cache.slicers.length + index,
        disabled: metric.orderByOrder !== undefined,
        id: metric.id,
      }
    }),
  } as GroupedOptions), [cache.metrics, cache.slicers, dimensionOptions, isMultiView, viewsWithMetrics])
}

export const useOptions = (
  cache: Pick<ConfigCache, 'options'>,
  displayType?: GenericChartTypes
): [GenericAdditionalDetails, boolean | undefined][] => {
  const availableAdditionalDetails = getAvailableAdditionalDetails(WidgetTypes.GENERIC, displayType) as GenericAdditionalDetails[]
  return Object.keys(GenericAdditionalDetails).map((additionalDetail) => [additionalDetail as GenericAdditionalDetails, availableAdditionalDetails.includes(additionalDetail as GenericAdditionalDetails) ? cache.options[additionalDetail as GenericAdditionalDetails] : undefined])
}

