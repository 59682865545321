import {memo, ReactElement, useCallback, useEffect} from 'react'
import {captureEvent} from 'services/GoogleAnalyticsService'
import {CommonProps, FormType} from "components/forms/Form.types"
import {notification} from "antd";

const captureAnalyticsEventConfig = (action: string, formType: FormType) => captureEvent({
  category: 'config',
  action,
// @ts-ignore
  widgetType: [formType.type, formType.chartType].filter(Boolean).join(' - '),
})
interface HideableFormProps<T, P extends CommonProps<T>> {
  visible: boolean,
  onConfirm: (data: T) => Promise<any>,
  onCancel: () => void,
  renderFormComponent: (props: P) => ReactElement<P>
}

const FormHideable = <T, P extends CommonProps<T>>({
                                                     onConfirm,
                                                     onCancel,
                                                     visible,
                                                     renderFormComponent,
                                                     ...props
                                                   }: (HideableFormProps<T, P> & P)) => {
  const handleConfirm = useCallback((data: T) => {
      return Promise.resolve(onConfirm(data))
        .then(result => {
          captureAnalyticsEventConfig('submit_config', props.formType)
          return result
        })
        .catch((error: Error) => {
          notification.error({
            message: error.message,
            placement: 'bottomRight',
            duration: 0,
          })
        })
    },
    [props.formType, onConfirm])
  const handleCancel = useCallback(() => {
      captureAnalyticsEventConfig('close_config', props.formType)
      onCancel()
    },
    [props.formType, onCancel])

  useEffect(
    () => {
      if (visible) {
        captureAnalyticsEventConfig('open_config', props.formType)
      }
    },
    [props.formType, visible],
  )
  return visible
    ? renderFormComponent({
      ...(props as unknown as P),
      onConfirm: handleConfirm,
      onCancel: handleCancel,
    })
    : null
}

export default memo(FormHideable) as typeof FormHideable
