import React, {FunctionComponent, useMemo, useRef, useState} from 'react'
import {TableColumnProps} from 'antd'
import {buildWorkspaceUri} from "commons/buildWorkspaceUri"
import {ExpandedWorkspace, SummarizedWorkspace} from "redux/models/workspace"
import Language from "language"
import {Column} from "components/dataSourceDoc/DataSource"
import {DataSourceObjectType} from "components/dataSourceDoc/DataSource.types"
import {Search} from "components/search/Search"
import {
  TableLink,
  ColorizedTag,
  MdView,
  AdministrationLayout,
  useSearchOnValues,
  ContentCard, BironTable,
} from "@biron-data/react-components"
import {
  useTextWithHighLight,
  useTextWithHighLightAsString,
  useTypeRepresentationColor,
} from "components/dataSourceDoc/DataSource.hooks"
import {TableContainer} from "components/dataSourceDoc/DataSource.style"
import {useResizeDetector} from "@biron-data/react-hooks"
import DatadocBreadcrumb from "./utils/DatadocBreadcrumb";

interface Props {
  data: Column[]
  currentWorkspace: ExpandedWorkspace | SummarizedWorkspace
}

export const DataSourceList: FunctionComponent<Props> = ({data, currentWorkspace}) => {
  const [consolidatedData, setConsolidatedData] = useState(data)
  const [search, setSearch] = useState("")

  const filteredValues = useMemo(() => [['alias'], ['description'], ['code']], [])

  useSearchOnValues(search, filteredValues, data, setConsolidatedData)

  const getTextWithHighLightAsString = useTextWithHighLightAsString(search)
  const getTextWithHighLight = useTextWithHighLight(search)

  const getTypeRepresentationColor = useTypeRepresentationColor()

  const columns: TableColumnProps<Column>[] = useMemo(() => {
    return [
      {
        title: Language.get(`admin.dataDoc.columns.object`),
        dataIndex: 'type',
        width: "8%",
        filters: Object.entries(DataSourceObjectType).map(([, source]) => ({
          text: Language.get("admin.dataDoc.details.type", source),
          value: source,
        })),
        onFilter: (value, record) => record.type === value.toString(),
        render: (text: DataSourceObjectType) => <ColorizedTag
          text={Language.get("admin.dataDoc.details.type", text)}
          color={getTypeRepresentationColor(text)}/>,
        style: {
          display: "flex",
          justifyContent: "center",
        },
      },
      {
        title: Language.get(`admin.dataDoc.columns.name`),
        dataIndex: 'alias',
        width: "26%",
        render: (text: string, record: Column) => {
          if (!record.view?.code) {
            return <div>{text}</div>
          }
          const path = `help/${record.type}/${record.code}`
          const link = buildWorkspaceUri(currentWorkspace, path)
          return <TableLink link={link}>{getTextWithHighLight(text)}</TableLink>
        },
      },
      {
        title: Language.get(`admin.dataDoc.columns.description`),
        dataIndex: 'description',
        width: "51%",
        render: (text: string) => <MdView description={getTextWithHighLightAsString(text)} padding={0}/>,
      },
      {
        title: Language.get(`admin.dataDoc.columns.id`),
        dataIndex: 'code',
        width: "15%",
        render: (text: string) => getTextWithHighLight(text),
      },
    ]
  }, [getTypeRepresentationColor, currentWorkspace, getTextWithHighLight, getTextWithHighLightAsString])

  const onSearch = (val: string) => {
    setSearch(val)
  }

  return (<AdministrationLayout breadcrumb={<DatadocBreadcrumb/>}><ContentCard>
          <TableContainer>
            <Search
              width={'calc(95% - 5px)'}
              onChange={onSearch}/>
            <BironTable columns={columns} dataSource={consolidatedData} />
          </TableContainer>
      </ContentCard>
    </AdministrationLayout>
  )
}

