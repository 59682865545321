import React, {FunctionComponent, useCallback, useEffect, useMemo, useState} from 'react'
import {Button, Col, Input, Row, Select} from 'antd'
import styled from "styled-components"
import {ColoredElementWithRadius, IconContainer} from "@biron-data/react-components";
import {TrashIcon} from "@heroicons/react/outline";
import {ColorName, strToColor} from "@biron-data/bqconf";
import Language from "../../../../language";
import {useDebouncedCallback} from 'use-debounce'

export interface Mark {
    name?: string
    value: number
    color: ColorName
}

interface Props {
  value: Mark[]
  onChange?: (data: Mark[]) => void,
}

const MarkSelector: FunctionComponent<Props> = ({
                                                       value,
                                                       onChange: originalOnChange,
                                                     }) => {
    const [temporaryValue, setTemporaryValue] = useState<Mark[]>(value)

    const addMark = useCallback(() => {
      originalOnChange?.([...(value ?? []), {
        value: 0,
        color: ColorName["blue-pastel"]
      }])
    }, [originalOnChange, value])

  useEffect(() => {
    setTemporaryValue(value)
  }, [value]);

    const options = useMemo(() => {
        return Array.from(strToColor.entries()).map(([key, color]) => ({
            label: <ColoredElementWithRadius $color={color}/>,
            value: key
        }))
    }, [])

  const onChange = useMemo(() => originalOnChange ?? (() => {}), [originalOnChange])

  const getNewValue = useCallback((newMark: Mark | undefined, valIndex: number) => value.map((v, vi) => vi === valIndex ? newMark as Mark : v).filter(mark => mark), [value])

  const onLineChange = useCallback((newMark: Mark | undefined, valIndex: number) => {
    onChange(getNewValue(newMark, valIndex))
  }, [getNewValue, onChange])

  const onChangeDebounced = useDebouncedCallback(onChange, 500)

  const debouncedOnLineChange = useCallback((newMark: Mark | undefined, valIndex: number) => {
    const newValue = getNewValue(newMark, valIndex)
    setTemporaryValue(newValue)
    onChangeDebounced(newValue)
  }, [getNewValue, onChangeDebounced])

  return <AlignedRow id={"56"}>
      <ColWithTextAtTop span={3}>
          <div>{Language.get('configuration-mark-selector.mark-list')} :</div>
      </ColWithTextAtTop>
      <ColWithGap span={21}>
          {temporaryValue?.map((mark, markIndex) => <Row key={markIndex} gutter={[10, 10]}>
              <Col span={6}>
                  <Input value={mark?.name} data-testid={"name"} placeholder={Language.get('configuration-mark-selector.mark')} onChange={(e) => {
                    debouncedOnLineChange({
                          ...mark,
                          name: e.target.value
                      }, markIndex)
                  }}/>
              </Col>
              <ColWithTextAtCenter span={3}>
                  <span>{Language.get('configuration-mark-selector.value')}</span>
              </ColWithTextAtCenter>
              <Col span={5}>
                  <Input value={mark?.value} type={"number"} onChange={(e) => {
                    debouncedOnLineChange({
                          ...mark,
                          value: Number(e.target.value)
                      }, markIndex)
                  }}/>
              </Col>
              <Col span={3}>
                  <Select value={mark?.color} options={options} onChange={(e) => {
                      onLineChange({
                          ...mark,
                          color: e
                      }, markIndex)
                  }}/>
              </Col>
              <Col span={1}>
                  <IconContainer clickable={true} onClick={() => onLineChange(undefined, markIndex)}><TrashIcon/></IconContainer>
              </Col>
          </Row>)}
        <Row>
          <Col span={6}>
            <Button type={"default"} onClick={addMark}>+ {Language.get('configuration-mark-selector.mark')}</Button>
          </Col>
        </Row>
      </ColWithGap>
  </AlignedRow>
}

export default MarkSelector

const AlignedRow = styled(Row)`
    align-items: center;
    width: 100%;
`

const ColWithGap = styled(Col)`
    display: flex;
    flex-direction: column;
    gap: 5px;
`

const ColWithTextAtTop = styled(Col)`
    height: 100%;
    display: flex;
    justify-content: start;
    flex-direction: column;
    padding-top: 8px;
`

const ColWithTextAtCenter = styled(Col)`
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    line-height: 38px;
`

