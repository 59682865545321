import React, {memo, useCallback} from "react"
import {Radio} from 'antd'
import {Days} from "components/forms/Form.types"
import Language from "language"
import styled from "styled-components"

interface ExportDestinationSelectorProps {
  value?: Days[]
  onChange?: (newValue?: Days[]) => void
}

const ExportExecutionDaysSelector = ({
                                       value: values,
                                       onChange,
                                     }: ExportDestinationSelectorProps) => {

  const onUpdateDays = useCallback((value: Days, state: boolean) => {
    onChange?.(Object.values(Days).filter(d => (d !== value && values?.includes(d)) || (d === value && state)))
  }, [onChange, values])

  const toggleAllDays = useCallback(() => {
    onChange?.(values?.length === Object.values(Days).length ? [] : Object.values(Days))
  }, [onChange, values?.length])

  return <StyledSelector>
    <RadioButtonWithMargin onClick={() => toggleAllDays()} checked={Boolean(values?.length === Object.values(Days).length)}>
      <StyledSpan>{Language.get('all')}</StyledSpan>
    </RadioButtonWithMargin>

    {Object.values(Days).map(
      v => <StyledCheckbox key={v} value={v} onClick={() => onUpdateDays(v, !values?.includes(v))}
                           checked={Boolean(values?.find(value => value === v))}>
        <StyledSpan>{Language.get(`admin.export.days.${v}`)}</StyledSpan>
      </StyledCheckbox>,
    )}
  </StyledSelector>
}

export default memo(ExportExecutionDaysSelector)

const StyledSelector = styled.div`
  .ant-radio-button-wrapper{
    box-shadow: none;
  }
`

const StyledSpan = styled.span`
  @media (max-width: 850px) {
    &&& {
      overflow: hidden;
      width: 2.5ch;
      display: block;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
`

const StyledCheckbox = styled(Radio.Button)`
  overflow: hidden;
  border: none;
  box-shadow: none;
`

const RadioButtonWithMargin = styled(Radio.Button)`
  overflow: hidden;
  margin-right: 15px;
  border: none;
  box-shadow: none;
`