import React from 'react'
import {Route, Routes} from "react-router-dom"
import UserManager from "components/admin/user/UserManager"
import ExportManager from "components/admin/exports/ExportManager"
import PeriodManager from "components/admin/periods/PeriodManager"
import WorkspaceManager from "../workspaceManager/WorkspaceManager";
import DatamodelManager from "../datamodelManager/DatamodelManager";
import DatamodelDetail from "../datamodelManager/DatamodelDetail";
import UserDetails from "./user/UserDetails";
import WorkspaceDetail from "../workspaceManager/WorkspaceDetail";
import AdminHome from "./Admin.Home";
import {AdminPath} from "../../commons/buildWorkspaceAdminUri";


const AdminContainer = () => {
  return <>
    <Routes>
      <Route path={AdminPath.users} element={<UserManager/>}/>
      <Route path={`${AdminPath.user}/:uid`} element={<UserDetails/>}/>
      <Route path={`${AdminPath.workspace}/:id`} element={<WorkspaceDetail/>}/>
      <Route path={AdminPath.workspaces} element={<WorkspaceManager />}/>
      <Route path={AdminPath.datamodels} element={<DatamodelManager />}/>
      <Route path={`${AdminPath.datamodel}/:id`} element={<DatamodelDetail />}/>
      <Route path={AdminPath.exports} element={<ExportManager/>}/>
      <Route path={AdminPath.periods} element={<PeriodManager/>}/>
      <Route
        path="*"
        element={<AdminHome/>}
      />
    </Routes>
  </>
}

export default AdminContainer;
