import {isEqual, pick} from "lodash"
import {GenericWorkflowFunction} from "classes/workflows/query-workflows/QueryWorkflow"
import {GenericEffectiveConf, SerieType} from "components/charts/line/LineChart.types"
import {MetricDataTree} from "classes/MetricDataTree"
import {QueryResponseData} from "services/QueryService"
import {SemanticType, extractSlicerDate, parseFormat, GenericChartTypes} from "@biron-data/bqconf"
import {computeChartSelection} from "classes/workflows/query-workflows/tablesQueryWorkflow"
import {dayjs} from "@biron-data/period-resolver"

export const aggregatedQueryWorkflow: GenericWorkflowFunction = (qc) => {
  const {dashboardSelection, chart, chartSelectionRaw, prevChartData} = qc
  const prevLoadedCache = prevChartData?.loadedCache

  const prevChartSelection = prevChartData?.chartSelection
  const chartSelection = computeChartSelection(dashboardSelection, chart, chartSelectionRaw, prevChartSelection)

  const keepPrevData = prevChartData
    && isEqual(dashboardSelection, prevLoadedCache?.dashboardSelection)
    && isEqual({
      ...pick(chartSelection, 'withChartOverriddenPeriod'),
    }, {
      ...pick(prevChartSelection, 'withChartOverriddenPeriod'),
    })

  const loadedCache = {
    dashboardSelection,
  }

  if (keepPrevData) {
    return new Promise(resolve => resolve({
      ...prevChartData,
      chartSelection: chartSelectionRaw,
    }))
  } else {
    return qc.prepareQuery(qc.query()).then(({data, effectiveConf}: { data: QueryResponseData[], effectiveConf: GenericEffectiveConf }) => {
      const expectedDates = computeExpectedDates(effectiveConf)
      const isMultiView = [...new Set(effectiveConf.metrics.map(({viewCode}) => viewCode))].length > 1
      return ({
        type: "generic",
        loadedCache,
        meta: {
          effectiveConf,
          extraConf: chart.extraConf,
        },
        parsedData: effectiveConf.metrics.map((metric, idx) => {
          const metricData = new MetricDataTree(
            isMultiView ? `${metric.view.alias}/${metric.metricAlias}` : metric.metricAlias,
            metric,
            parseFormat(metric),
            1, // hardcode 1 as that is the only thing we handle for now. Ingestion code should change slightly once we handle more
            effectiveConf.slicers.length - 1,
          )
          // This index enables specific coloration of the series with a date as axis, but for now we only want it to be applied to the bar chart
          const dateSlicerIndex = qc.chart.extraConf.displayType === GenericChartTypes.BARS ? effectiveConf.slicers.findIndex(slicer => slicer.type === "date") : -1
          const isAxisNumeric = effectiveConf.slicers.length > 0 && effectiveConf.slicers[0].type === "dimension" && effectiveConf.slicers[0].dimension.semanticType === SemanticType.NUMBER
          data.forEach((dataRow) => {
            metricData.ingestDataRow(dataRow, dateSlicerIndex, isAxisNumeric, idx)
          })
          return metricData
        }),
        expectedDates,
      })
    })
  }
}

/*
  Returns all date interval for date slicer based on granularity
 */
export function computeExpectedDates({timeInterval, slicers}: GenericEffectiveConf): string[] {
  const dateSlicer = slicers ? extractSlicerDate(slicers) : undefined
  if (!dateSlicer) {
    return []
  }

  const jsGranularity: any = dateSlicer.granularity?.toLowerCase()
  const expectedDates: string[] = []
  for (let date = dayjs(timeInterval.start, 'YYYY-MM-DD').startOf(jsGranularity);
       dayjs(timeInterval.end, 'YYYY-MM-DD').diff(date, 'day') >= 0;
       date = date.add(1, jsGranularity)
  ) {
    expectedDates.push(date.format('YYYY-MM-DD'))
  }
  return expectedDates
}


export function seriesAsPercentage(series: SerieType[]): SerieType[] {
  const totals = sumSeriesValues(series)
  return series.map(({values, ...aSeries}) => ({
    ...aSeries,
    values: values.map((val, i) => {
      if (val === undefined) {
        return undefined
      }
      return totals[i] === 0 ? 0 : val / totals[i]
    }),
  })) as SerieType[]
}

const sumSeriesValues = (series: SerieType[]) => series.reduce(
  (sums, aSeries) => aSeries.values.map((v, index) => sums[index] + v),
  new Array(series[0].values.length).fill(0),
)
