import React, {forwardRef, useCallback, useMemo, useRef, useState} from 'react'
import FormModal, {ConsolidatedFormProps} from "components/forms/Form.Modal"
import {SimplifiedExportExecutionFormProps} from "components/forms/chart/types"
import {ExportExecutionDto, FormExportExecutionConfProps} from "components/forms/Form.types"
import {Form} from "antd"
import CheckboxSelector from "components/forms/export/selectors/CheckBoxSelector"
import Language from "language"
import ExportExecutionDaysSelector from "components/forms/export/selectors/ExportExecutionDaysSelector"
import {FormInstance} from "antd/lib/form/hooks/useForm"
import {useFields} from "@biron-data/react-hooks"

type FormComponentProps = SimplifiedExportExecutionFormProps & ConsolidatedFormProps<ExportExecutionDto>

// eslint-disable-next-line react/display-name
const FormComponent = forwardRef<FormInstance, FormComponentProps>(({data, errors, onValuesChange}, ref) => {
  const [form] = Form.useForm()
  const fields = useFields(data, errors)

  return <Form ref={ref} fields={fields} name={'ExportExecutionDto'} form={form} onValuesChange={onValuesChange}>
    <Form.Item
      name="days"
      label={Language.get('configuration-label-days')}
      rules={[{required: true, message: Language.get('configuration-export-select-days'), type: 'array'}]}
    >
      <ExportExecutionDaysSelector/>
    </Form.Item>
    <Form.Item label={Language.get('configuration-label-override')} name={`override`}>
      <CheckboxSelector message={{
        checked: Language.get('admin.export.override-checked'),
        notchecked: Language.get('admin.export.override-notchecked'),
      }}/>
    </Form.Item>
  </Form>
})

const FormExportExecutionCmp: (props: FormExportExecutionConfProps) => JSX.Element | null = ({
                                                                                               data: originalData,
                                                                                               title,
                                                                                               exportDestinations,
                                                                                               onConfirm,
                                                                                               onCancel,
                                                                                             }) => {
  const popupContainerRef = useRef()

  const [formData] = useState<ExportExecutionDto>(originalData)

  const handleConfirm = useCallback(
    (newData: ExportExecutionDto) => {
      onConfirm(newData)
    }, [onConfirm],
  )

  const formProps: SimplifiedExportExecutionFormProps = useMemo(() => ({
    exportDestinations,
  }), [exportDestinations])

  return <FormModal<ExportExecutionDto, SimplifiedExportExecutionFormProps> {...{
    ref: popupContainerRef,
    width: 1500,
    data: formData,
    defaultTitle: title,
    renderFormComponent: (props) => <FormComponent {...props}/>,
    onConfirm: handleConfirm,
    onCancel,
    formProps,
  }} />
}

export default FormExportExecutionCmp
