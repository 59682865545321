import React, {memo} from 'react'
import styled from "styled-components"
import {
  IconContainer, JustifyContentProperty,
} from "@biron-data/react-components"
import {FolderIcon} from "@heroicons/react/outline";
import Language from "../../../language";
import {DatamodelRoles} from "./UserManager.types";
import {
  datamodelManagerDetails,
  datamodelReaderDetails,
  wrapWithTooltip
} from "./DatamodelRights.utils";
import {TooltipPlacement} from "antd/es/tooltip";

interface Props {
  roles: DatamodelRoles[]
  layout: "row" | "column"
  justifyContent?: JustifyContentProperty
  tooltipPlacement: TooltipPlacement
}

// eslint-disable-next-line react/display-name
const DatamodelRights = memo<Props>(({roles,
                                       layout,
                                       justifyContent,
                                       tooltipPlacement}) => {
  const hasPersonalDashboardAccess = roles?.includes(DatamodelRoles.DATAMODEL_EXPLORER)
  return wrapWithTooltip(<FlexDiv $layout={layout} $justifyContent={justifyContent}>
    <FlexDiv $layout={"row"} $justifyContent={justifyContent}>
      <IconContainer key="personalDashboard" data-testid="personalDashboardIcon"
                     color={hasPersonalDashboardAccess ? "var(--primary)" : undefined}><FolderIcon/></IconContainer>
      {
        layout === "column" && Language.get("admin.datamodels.content.personalDashboardAccess")
      }
    </FlexDiv>
  </FlexDiv>, DatamodelRoles.DATAMODEL_EXPLORER,
    Language.get('admin.user.tooltips.datamodel.manager.summary'), [
      datamodelReaderDetails,
      datamodelManagerDetails,
  ],
    true,
    tooltipPlacement)
})

export default DatamodelRights

const FlexDiv = styled.div<{$layout: "row" | "column", $justifyContent?: JustifyContentProperty}>`
    display: flex;
    ${({$justifyContent}) => `justify-content: ${$justifyContent ?? 'start'};`}
    ${({$layout}) => `flex-direction: ${$layout};`}
    gap: 10px;
`
