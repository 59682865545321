import React, {forwardRef, FunctionComponent, PropsWithChildren} from "react"
import {ConfOrderBy, GroupedOptions, ConsolidatedDimension} from "@biron-data/bqconf"
import {OrderBySelector} from "@biron-data/react-bqconf"
import {WrapperItem, WrapperRoot} from "@biron-data/react-components"
import {ChartExtraConf} from "types/widgets"
import {SortDescendingIcon} from "@heroicons/react/outline"
import {ConfigurationOrderByWithDisablingReasons} from "components/forms/chart/types"

interface Props extends PropsWithChildren {
  name: string
}

export const OrderBysWrapper: FunctionComponent<Props> = ({children, name}) => (
  <WrapperRoot {...{
    icon: SortDescendingIcon,
    label: "configuration-label-orderBys",
    description: "configuration-description-orderBys",
  }}>
    <WrapperItem name={name}>
      {children}
    </WrapperItem>
  </WrapperRoot>
)

interface FieldProps {
  value?: ConfOrderBy[]
  disabled?: boolean
  multiple?: boolean
  onChange?: (orderBys: Partial<ConfOrderBy>[]) => void
  extraConf: ChartExtraConf
  slicers: any[]
  configurations: ConfigurationOrderByWithDisablingReasons[]
  getPopupContainer?: (triggerNode: HTMLElement) => HTMLElement
  groupedOptions: GroupedOptions
  availableDimensions: ConsolidatedDimension[]
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const OrderBysField = forwardRef<any, any>(function Field({...props}: FieldProps, ref) {
  return <OrderBySelector {...props} />
})
