import React, {useMemo} from 'react'
import {Days, Export} from "components/forms/Form.types"
import Language from "language"
import {Col, Row} from 'antd'
import {TooltipPopover, IconContainer} from "@biron-data/react-components"
import {CogIcon} from "@heroicons/react/outline"
import styled from "styled-components"
import {dayjs} from "@biron-data/period-resolver"
import {isEmpty} from "lodash"

interface ElementManagerOptionsProps {
  onConfEdit: () => void
}

type Props = ElementManagerOptionsProps & Pick<Export, 'title' | 'days' | 'override' | 'ignoreMetrics0' | 'destination'>

const ExportManagerOptions = ({
                                title,
                                days,
                                override,
                                ignoreMetrics0,
                                destination,
                                onConfEdit,
                              }: Props) => {
  const nextExecution = useMemo(() => {
    const weekday = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]

    const today = dayjs()
    const nexExecution = weekday.findIndex((day, i) => i > weekday.indexOf(weekday[today.day()]))
    return dayjs().startOf('week').add(nexExecution, 'day').diff(today, 'day')
  }, [])

  const informations = useMemo(() => ({
    [Language.get("admin.export.execution-day")]: days.sort((day1, day2) => Object.values(Days).indexOf(day1) - Object.values(Days).indexOf(day2)).join('/'),
    [Language.get("admin.export.override")]: override ? Language.get("button-yes") : Language.get("button-no"),
    [Language.get("admin.export.destination")]: destination.name,
    [Language.get("admin.export.ignoreMetrics0")]: ignoreMetrics0 ? Language.get("button-yes") : Language.get("button-no"),
    [Language.get("admin.export.next-execution")]: `${nextExecution} ${Language.get('day')}`,
    [Language.get("admin.export.file-name")]: `${override ? title : `${dayjs().add(nextExecution, 'day').format("YYYYMMDD_HHmmss")}_${title}`}`,
  }), [days, destination, ignoreMetrics0, nextExecution, override, title])
  return <Row>
    <Col span={22}>
      <FlexContainer>
        {Object.entries(informations).filter(([, value]) => !isEmpty(value)).map(([name, value]) => <Row key={name}>
          <ColWithTextAlignedToRight span={8}>
            {name}:
          </ColWithTextAlignedToRight>
          <ColWithLowOpacity span={15} offset={1}>
            {value}
          </ColWithLowOpacity>
        </Row>)}
      </FlexContainer>
    </Col>
    <FlexCol span={2}>
      <FlexRow>
        <TooltipPopover size={'small'} content={Language.get('configuration')}>
          <IconContainer clickable={true} hoverColor={"var(--primary-light)"} containerSize={28}><CogIcon className="disable-grab"
                                                                                                          onClick={onConfEdit}/></IconContainer>
        </TooltipPopover>
      </FlexRow>
    </FlexCol>
  </Row>
}

export default ExportManagerOptions

const ColWithLowOpacity = styled(Col)`
  opacity: 0.7;
`

const ColWithTextAlignedToRight = styled(Col)`
  display: flex;
  flex-direction: row-reverse;
`

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`

const FlexRow = styled.div`
  display: flex;
  justify-content: end;
`

const FlexCol = styled(Col)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  `