import {PeriodSummary, PeriodTypes} from "@biron-data/period-resolver"
import {DimensionFilterDtoDetail, FilterDtoDetailTypes} from "@biron-data/bqconf"
import {ChartDtoDetailTypes, RawChartTypes} from "types/charts"

export enum Folder {
  DRAFT = "DRAFT", STARRED = "STARRED", TRASH = "TRASH"
}

export enum DashboardTypes {
  workspace = "w",
  personal = "p"
}

export enum DashboardFormats {
  detailed = "detailed",
  summarized = "summarized"
}

interface DashboardDtoDetail {
  type: DashboardTypes.workspace | DashboardTypes.personal
  id: number
  title: string
  updatedAt: string
  period: PeriodTypes
  filters: DimensionFilterDtoDetail[]
  charts: ChartDtoDetailTypes[]
  chartHashLink?: string
  datamodelCode: string
}

export interface WorkspaceDashboardDto extends DashboardDtoDetail {
  type: DashboardTypes.workspace
  relativeId: number
  uri: string
  protected: boolean
  filtersLocked: boolean
}

export interface PersonalDashboardDto extends DashboardDtoDetail {
  type: DashboardTypes.personal
  format: DashboardFormats.detailed | DashboardFormats.summarized
  uid: string
  folder: Folder
}

interface RawDashboardDtoDetail extends Omit<DashboardDtoDetail, "charts" | "period"> {
  charts: RawChartTypes[]
  period: PeriodSummary
}

export interface RawWorkspaceDashboardDto extends RawDashboardDtoDetail {
  relativeId: number
  uri: string
  chartHashLink?: string
}

export interface RawPersonalDashboardDto extends RawDashboardDtoDetail {
  format: DashboardFormats.detailed | DashboardFormats.summarized
  uid: string
  folder: Folder,
  datamodelCode: string
}

export type RawDashboardDtoTypes = RawWorkspaceDashboardDto | RawPersonalDashboardDto
export type DashboardDtoTypes = WorkspaceDashboardDto | PersonalDashboardDto


interface DashboardDtoDetailWithoutChart {
  type: DashboardTypes.workspace | DashboardTypes.personal
  id: number
  title: string
  updatedAt: string
  period: PeriodTypes
  filters: DimensionFilterDtoDetail[]
  chartHashLink?: string
  datamodelCode: string
}

export interface PersonalDashboardDtoWithoutChart extends DashboardDtoDetailWithoutChart {
  type: DashboardTypes.personal
  format: DashboardFormats.detailed | DashboardFormats.summarized
  uid: string
  folder: Folder,
}

export interface WorkspaceDashboardDtoWithoutChart extends DashboardDtoDetailWithoutChart {
  type: DashboardTypes.workspace
  relativeId: number
  uri: string
  protected: boolean
  filtersLocked: boolean
}


export type DashboardDtoWithoutChartTypes = WorkspaceDashboardDtoWithoutChart | PersonalDashboardDtoWithoutChart

export interface RawPersonalDashboardDtoDetailed extends RawPersonalDashboardDto {
  format: DashboardFormats.detailed
}

export interface RawPersonalDashboardDtoSummarized extends Omit<RawPersonalDashboardDto, "period" | "filters" | "charts"> {
  format: DashboardFormats.summarized
}


export type RawPersonalDashboardDtoTypes = RawPersonalDashboardDtoDetailed | RawPersonalDashboardDtoSummarized

export interface PersonalDashboardDtoForm {
  title: string
  period: PeriodTypes
  filters: FilterDtoDetailTypes[]
  charts: ChartDtoDetailTypes[]
  uid?: string
  folder?: Folder
  datamodelCode: string
}

export interface RawPersonalDashboardsList {
  detailed: Omit<RawPersonalDashboardDtoDetailed, 'format'>[]
  summarized: Omit<RawPersonalDashboardDtoSummarized, 'format'>[]
}

export interface PersonalDashboardsList {
  detailed?: RawPersonalDashboardDtoDetailed[]
  summarized?: RawPersonalDashboardDtoSummarized[]
}