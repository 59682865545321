import React, {memo, useCallback, useEffect, useMemo, useRef, useState} from 'react'
import WidgetContent from "components/widgetContainer/WidgetContainer.Content"
import {ChartSelection, EmptyObject, queryWorkflow, WorkflowResultTypes} from "classes/workflows/query-workflows/QueryWorkflow"
import {NormalizedDashboardTypes} from "schemas/dashboard"
import {WidgetTypes} from "commons/dashboard/dashboard.types"
import {batch} from "commons/batch"
import {
  MetaModel,
  MetaModelView,
  convertFilterDetailToConfFilter,
  extractSlicerDate,
  isFilterEmpty,
  ConfDimensionFilterTypesWithSource,
  Source,
  ChartFormat,
  DataSelection,
  GenericChartTypes,
  ConsolidatedDimension, DatamodelDto,
} from "@biron-data/bqconf"
import {
  useIsAxisOptionAllowed,
} from "@biron-data/react-bqconf"
import {formatFormDataToChartDto} from "components/forms/chart/utils"
import {GenericExtendedConfModel} from "components/forms/chart/types"
import styled from "styled-components"
import {useDebouncedCallback} from "use-debounce"
import {isEmpty} from "@biron-data/react-components"
import {ChartGenericDtoDetail, ChartGenericExtraConf} from "types/charts"
import {isEqual, omit} from "lodash"
import {FilterSummary} from "components/filterSummary/FilterSummary"
import {CHART_SUMMARY_HEIGHT} from "components/charts/Chart.constants"
import {isPeriodOverridden} from "commons/selection"
import {consolidateSlicers} from "commons/parsers/utils"
import {useLanguageResolver} from "@biron-data/react-contexts"
import Language from "../../../language";

interface FieldProps {
  dashboard: Pick<NormalizedDashboardTypes, "id" | "title">
  environmentId: number
  datamodel: DatamodelDto,
  metaModel: MetaModel
  dashboardSelection: DataSelection
  dimensions: {
    width: number
    height: number
  }
  data: GenericExtendedConfModel
  displayType?: GenericChartTypes
  format?: ChartFormat | null
  viewsWithMetrics: MetaModelView[]
  isMultiView: boolean,
  availableDimensions: ConsolidatedDimension[]
}

type ExtraConfDtoType = Omit<ChartGenericExtraConf, 'displayLabels' | 'asPercentage' | 'ignoreMetrics0'>

// eslint-disable-next-line react/display-name
export const ChartPreviewField = memo<FieldProps>(({
                                         dimensions,
                                         metaModel,
                                         dashboardSelection,
                                         data,
                                         dashboard,
                                          environmentId,
                                          datamodel,
                                         displayType,
                                         format,
                                         viewsWithMetrics,
                                         availableDimensions,
                                         isMultiView,
                                       }) => {
  const lr = useLanguageResolver()
  const [loading, setLoading] = useState(true)
  const [displayedChartDto, setDisplayedChartDto] = useState<Omit<ChartGenericDtoDetail, "x" | "y" | "h" | "w" | "extraConf"> & {extraConf: ExtraConfDtoType}>()
  const [chartData, setChartData] = useState<WorkflowResultTypes | EmptyObject>()
  const [chartSelection, setChartSelection] = useState<ChartSelection>({})
  const paginationSelection = useRef<ChartSelection>()

  useEffect(() => {
    const withDateSlicer = !isEmpty(extractSlicerDate(data.slicers))
    const consolidatedSlicers = consolidateSlicers(data.slicers, dashboardSelection, withDateSlicer, chartSelection.withChartOverriddenPeriod)
    paginationSelection.current = {
      pagination: data.displayType && [GenericChartTypes.BOXES, GenericChartTypes.TABLES].includes(data.displayType) ? {
        pageSize: data.displayType === GenericChartTypes.BOXES ? 10 : 5,
        current: 1,
      } : undefined,
      sorters: data.displayType && [GenericChartTypes.BOXES, GenericChartTypes.TABLES].includes(data.displayType) ? data.orderBys : [],
      format: data.format,
      displayLabels: data.displayLabels,
      withDateSlicer,
      asPercentage: Boolean(data.format && [ChartFormat.AREA_PERCENTAGE, ChartFormat.PERCENTAGE_V_STACKED, ChartFormat.PERCENTAGE_H_STACKED].includes(data.format)) || data.asPercentage,
      ignoreMetrics0: data.ignoreMetrics0,
      withChartOverriddenPeriod: isPeriodOverridden({
        ...data,
        slicers: consolidatedSlicers,
      }),
    }
    // Disable warning as we don't need the data dependency (needed for isPeriodOverridden call) but the two only changing value are data.slicers and data.period
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.period, data.asPercentage, data.displayLabels, data.displayType, data.format, data.ignoreMetrics0, data.slicers, data.orderBys])

  const isAxisOptionAllowed = useIsAxisOptionAllowed(displayType, format)

  const chartDto: Omit<ChartGenericDtoDetail, "x" | "y" | "h" | "w" | "extraConf"> & {extraConf: ExtraConfDtoType} = useMemo(() => {
    const dto = formatFormDataToChartDto({
        ...data,
        filters: data.filters.filter(filter => !isFilterEmpty(filter) && filter.isValid),
        type: WidgetTypes.GENERIC,
      },
      isAxisOptionAllowed, viewsWithMetrics, isMultiView, metaModel, availableDimensions, lr, Language.get(`new-chart-title`)
    )
    return {
      ...dto,
        extraConf: omit(dto.extraConf, ['displayLabels', 'asPercentage', 'ignoreMetrics0']) as ExtraConfDtoType
      }
    },
    [availableDimensions, data, isAxisOptionAllowed, isMultiView, lr, metaModel, viewsWithMetrics])

  const queryData = useCallback(async (prevChartData?: WorkflowResultTypes | EmptyObject) => {
    let loadingTimeout: NodeJS.Timeout
    const result = await queryWorkflow({
        metaModel,
        datamodel,
        param: {
          type: "environment",
          environmentId,
          datamodelCode: datamodel.code
        },
        dashboardSelection,
        queryId: "preview",
        chart: chartDto,
        // @ts-ignore
        chartSelectionRaw: paginationSelection.current,
        prevChartData: prevChartData && Object.entries(prevChartData).length > 0 ? prevChartData as WorkflowResultTypes : undefined,
      },
      () => {
        loadingTimeout = setTimeout(() => {
          setLoading(true)
        }, 200)
      }, lr
    )
    // @ts-ignore
    clearTimeout(loadingTimeout)
    batch(() => {
      setLoading(false)
      setChartData(result)
      setDisplayedChartDto({
        ...chartDto,
        extraConf: omit(chartDto.extraConf, ['displayLabels', 'asPercentage', 'ignoreMetrics0']) as ExtraConfDtoType
      })
      setChartSelection(paginationSelection.current ?? {})
    })
  }, [chartDto, dashboard, dashboardSelection, lr, metaModel])

  const queryExecutor = useCallback(() => queryData(), [queryData])

  const queryDataDebounced = useDebouncedCallback(queryExecutor, 350)

  useEffect(() => {
    queryDataDebounced()
  }, [data.slicers, data.metrics, data.orderBys, data.period, data.dateSlicerGranularity, queryExecutor, queryDataDebounced])

  useEffect(() => {
    if (data.limits) {
      const [limit] = data.limits
      if (limit && limit.limitSeries && paginationSelection.current
        && paginationSelection.current.pagination
        && paginationSelection.current.pagination.pageSize
        && paginationSelection.current.pagination.current
        && limit.limitSeries < (paginationSelection.current.pagination.pageSize * (paginationSelection.current.pagination.current - 1))) {
        const selection = {
          ...paginationSelection.current,
          pageSize: 1,
        }
        setChartSelection(selection)
        paginationSelection.current = selection
      }
    }
  }, [data.limits])

  const handleSelectionChange = useCallback(
    (newSelection: ChartSelection) => {
      setChartSelection(newSelection)
      paginationSelection.current = newSelection
      // There seem to be a react problem here: on the first call, data is undefined (even though it is filled)
      queryData(chartData)
    },
    [chartData, queryData],
  )

  const dashboardFiltersWithDimension: ConfDimensionFilterTypesWithSource[] = useMemo(() => dashboardSelection.filters.length > 0 ? convertFilterDetailToConfFilter(dashboardSelection.filters).map(filter => ({
    ...filter,
    source: Source.QUERY,
  })) : [], [dashboardSelection.filters])

  return <WidgetContainer>
    {dashboardFiltersWithDimension.length > 0 && <FilterSummaryContainer>
      <FilterSummary filters={dashboardFiltersWithDimension}/>
    </FilterSummaryContainer>}
    {displayedChartDto && <WidgetContent {...{
      withSummary: false,
      chart: displayedChartDto,
      data: chartData,
      selection: chartSelection,
      loading: loading || !isEqual(displayedChartDto, chartDto),
      dimensions: {
        width: dimensions.width,
        height: dashboardFiltersWithDimension.length > 0 ? dimensions.height - CHART_SUMMARY_HEIGHT : dimensions.height,
      },
      onSelectionChange: handleSelectionChange,
    }} />}
  </WidgetContainer>
})

const FilterSummaryContainer = styled.div`
  margin-top: 0;
  width: 100%;
  overflow: hidden;
`

const WidgetContainer = styled.div`
  background-color: white;
  border-radius: var(--big-border-radius);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`