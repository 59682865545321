import React, {memo} from 'react'
import {MetricTooltipContentProps, TooltipContentMetric} from "@biron-data/react-bqconf"
import {MetricWithView} from "@biron-data/bqconf"
import {EffectiveConfTypes} from "types/charts"
import {useDataDocDimensionLink} from "components/dataSourceDoc/DataSource.hooks"

export function parseMetricAsAlt(metric: MetricWithView, effectiveConf: EffectiveConfTypes): JSX.Element | string {
  const {metricDef} = metric
  const labelWithView = `${metric.view.alias} - ${metric.metricAlias}`
  if (metricDef.description) {
    return <TooltipContentMetricWithLink metric={metric} timeInterval={effectiveConf.timeInterval}/>
  } else {
    return labelWithView
  }
}

// eslint-disable-next-line react/display-name
const TooltipContentMetricWithLink = memo<Omit<MetricTooltipContentProps, "getMoreInformationLink">>(({
                                                                                                        metric,
                                                                                                        timeInterval,
                                                                                                      }) => {
  const getDatadocLink = useDataDocDimensionLink()

  return <TooltipContentMetric metric={metric} timeInterval={timeInterval} getMoreInformationLink={getDatadocLink}/>
})