import {MetricCacheElement} from "components/forms/chart/types"
import {GenericChartTypes, MetricTarget} from "@biron-data/bqconf"

export const isBarColorOverridable = (slicerCount: number, metricCount: number) => {
  if (slicerCount === 0) {
    return true
  } else if (slicerCount === 1 && metricCount > 1) {
    return true
  }
  return false
}

export const isLineColorOverridable = (slicerCount: number) => {
  return slicerCount <= 1
}

export const replicateMetricsTarget = (metrics: Pick<MetricCacheElement, "isRatio" | "growth" | "format">[], affect: MetricTarget) => [...Array(metrics.length + 1).keys()].map(() => affect)

export const isGradientAvailable = (
  type: GenericChartTypes | undefined,
  slicersCount: number
) => (type && ![GenericChartTypes.AREA, GenericChartTypes.TABLES].includes(type)) || (type === GenericChartTypes.AREA && slicersCount < 2)

export const isIntervalAvailable = (
  type: GenericChartTypes | undefined,
  slicersCount: number
) => type !== GenericChartTypes.AREA || (type === GenericChartTypes.AREA && slicersCount < 2)