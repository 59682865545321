import React, {forwardRef, FunctionComponent, useMemo} from 'react'
import {Form, Input} from 'antd'
import FormModal, {ConsolidatedFormProps} from "components/forms/Form.Modal"
import Language from "language"
import {useFields} from "@biron-data/react-hooks"
import {EmptyConfModel, EmptyProps} from "components/forms/chart/types"
import {FormInstance} from "antd/lib/form/hooks/useForm"

// eslint-disable-next-line react/display-name
const FormComponent = forwardRef<FormInstance, ConsolidatedFormProps<EmptyConfModel>>((props, ref) => {
  const fields = useFields(props.data, props.errors)
  return <Form ref={ref} name={'newUac'} labelCol={{span: 6}} wrapperCol={{span: 18}} fields={fields} onValuesChange={props.onValuesChange}>
    <Form.Item label={Language.get('admin.user.forms.email')} name={'userEmail'} rules={[{type: 'email'}, {required: true}]}>
      <Input/>
    </Form.Item>
  </Form>
})

interface Props {
  visible: boolean
  onConfirm: ({userEmail}: { userEmail: string }) => void
  onCancel: () => void
}

const NewUacForm: FunctionComponent<Props> = ({visible, onConfirm, onCancel}) => {
  // reset data only when visible change
  const data: EmptyConfModel = useMemo(() => ((visible || true) && {}),
    [visible]
  )

  return <FormModal<EmptyConfModel, EmptyProps> {...{
    defaultTitle: Language.get('admin.user.forms.newUacTitle'),
    visible,
    data,
    renderFormComponent: (props) => <FormComponent {...props}/>,
    onConfirm,
    onCancel,
    formProps: {},
  }} />
}

export default NewUacForm
