import {getCurrentEnvironmentId} from "redux/environment.selector"
import {MetaModel, DatamodelDto} from "@biron-data/bqconf"
import RootModel from "redux/models/index"
import {createModel} from "@rematch/core"
import {getViewsAndDatamodels} from "services/MetaModelService"

export interface Environment {
  currentClientId: number | null
  currentMetaModel: MetaModel | null
  datamodels: DatamodelDto[]
}

const initialState: Environment = {
  currentClientId: null,
  currentMetaModel: null,
  datamodels: []
}

export default createModel<RootModel>()({
  state: initialState,
  reducers: {
    setCurrent(state, {currentClientId, currentMetaModel, datamodels}) {
      return {
        ...state,
        currentClientId,
        currentMetaModel,
        datamodels
      }
    },
  },
  effects: (dispatch) => ({
    async updateCurrentClient({environment: {id}}: { environment: { id: number } }, state) {
      if (id !== getCurrentEnvironmentId(state)) {
        const {views, datamodels} = await getViewsAndDatamodels(id)
        dispatch.environment.setCurrent({
          currentClientId: id,
          currentMetaModel: new MetaModel(views),
          datamodels
        })
        dispatch({type: 'environment/cleanStates'})
      }
    },
  }),
})
