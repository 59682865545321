import {ExpandedWorkspace, SummarizedWorkspace} from "../redux/models/workspace";
import {buildWorkspaceUri} from "./buildWorkspaceUri";

export enum AdminPath {
  "workspace" = "workspace",
  "workspaces" = "workspaces",
  "datamodel" = "datamodel",
  "datamodels" = "datamodels",
  "periods" = "periods",
  "exports" = "exports",
  "user" = "user",
  "users" = "users",
}

export const buildWorkspaceAdminUri = (
  workspace: ExpandedWorkspace | SummarizedWorkspace,
  additionnalParams?: string) => {
  return buildWorkspaceUri(workspace, additionnalParams ? `admin/${additionnalParams}` : 'admin')
}

export const buildWorkspaceAdminDatamodelsUri = (
  workspace: ExpandedWorkspace | SummarizedWorkspace) => {
  return buildWorkspaceAdminUri(workspace, `${AdminPath.datamodels}`)
}

export const buildWorkspaceAdminDatamodelUri = (
  workspace: ExpandedWorkspace | SummarizedWorkspace,
  code: string) => {
  return buildWorkspaceAdminUri(workspace, `${AdminPath.datamodel}/${code}`)
}

export const buildWorkspaceAdminWorkspacesUri = (
  workspace: ExpandedWorkspace | SummarizedWorkspace) => {
  return buildWorkspaceAdminUri(workspace, `${AdminPath.workspaces}`)
}

export const buildWorkspaceAdminWorkspaceUri = (
  workspace: ExpandedWorkspace | SummarizedWorkspace,
  code: string) => {
  return buildWorkspaceAdminUri(workspace, `${AdminPath.workspace}/${code}`)
}

export const buildWorkspaceAdminUsersUri = (
  workspace: ExpandedWorkspace | SummarizedWorkspace) => {
  return buildWorkspaceAdminUri(workspace, `${AdminPath.users}`)
}

export const buildWorkspaceAdminUserUri = (
  workspace: ExpandedWorkspace | SummarizedWorkspace,
  code: string) => {
  return buildWorkspaceAdminUri(workspace, `${AdminPath.user}/${code}`)
}

export const buildWorkspaceAdminExportsUri = (
  workspace: ExpandedWorkspace | SummarizedWorkspace) => {
  return buildWorkspaceAdminUri(workspace, `${AdminPath.exports}`)
}

export const buildWorkspaceAdminPeriodsUri = (
  workspace: ExpandedWorkspace | SummarizedWorkspace) => {
  return buildWorkspaceAdminUri(workspace, `${AdminPath.periods}`)
}
