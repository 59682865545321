import React, {FunctionComponent} from 'react'
import {Link} from 'react-router-dom'
import MainLayoutExtraActionsWrapper from 'components/mainLayout/MainLayout.ExtraActionsWrapper'
import Switch from "components/switch/Switch"
import {ExtraAction} from "types/navigation"
import {MetaModel} from "@biron-data/bqconf"
import styled from "styled-components"
import {CogIcon} from "@heroicons/react/outline"
import {HandleMenuActionParamTypes} from "components/workspace/WorkspaceBridge.SiderContainer"
import {ExpandedWorkspace} from "redux/models/workspace"
import {Submenu} from "@biron-data/icons"


interface Props {
  label: string
  icon?: FunctionComponent
  link: string
  extraActions?: ExtraAction[]
  onAction: (params: HandleMenuActionParamTypes) => void
  metaModel: MetaModel
  workspace: ExpandedWorkspace | undefined
  switchEditMode?: ((state: any) => void) | boolean
  editMode?: boolean
  submenu?: boolean
}

const MainLayoutMenuLink: (props: Props) => JSX.Element = ({
                                                             label,
                                                             icon: Icon,
                                                             link,
                                                             extraActions,
                                                             onAction,
                                                             metaModel,
                                                             editMode,
                                                             switchEditMode,
                                                             workspace,
                                                             submenu,
                                                           }) => {
  const labelObj = Icon ? <React.Fragment>
    <IconContainer>{<Icon/>}</IconContainer><OneLineText>{label}</OneLineText>
  </React.Fragment> : label
  return <MenuItemContainer>
      {submenu && <StyledSubmenu />}
      <MenuItemContent>
        {link[0] === '#' ? <FlexA href={link} title={label}>{labelObj}</FlexA> : <FlexLink to={link} title={label}>{labelObj}</FlexLink>}
        {editMode && extraActions && workspace && <MainLayoutExtraActionsWrapper {...{
          confs: extraActions,
          onAction,
          metaModel,
          workspace,
        }}/>}
        {switchEditMode && <StyledSwitchContainer><Switch
          change={(newSwitchState, event) => {
            event.stopPropagation()
            // @ts-ignore
            switchEditMode(newSwitchState)
          }}
          clickable={false}
          switchState={editMode}
          switchItem={{
            icon: CogIcon,
            checked: 'switch-deactivate-edit-mode',
            unchecked: 'switch-activate-edit-mode',
          }}/></StyledSwitchContainer>}
      </MenuItemContent>
    </MenuItemContainer>
}

export default MainLayoutMenuLink

const StyledSubmenu = styled(Submenu)`
  width: 12px;
  height: 12px;
`

const StyledSwitchContainer = styled.div`
  z-index: 1;
  display: flex;
  align-items: center;
  align-content: end;
  justify-content: end;
`

const OneLineText = styled.div`
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
`

const MenuItemContainer = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 10px;
`

const MenuItemContent = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;  
  min-width: 0;
`

const IconContainer = styled.div`
  margin-right: 10px;
`

const FlexA = styled.a`
  display: block;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
`

const FlexLink = styled(Link)`
  display: block;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
`