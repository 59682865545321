import React, {memo, useCallback} from 'react'
import TableChartHeaderCell from 'components/charts/table/TableChart.HeaderCell'
import styled from "styled-components"
import {TableColumn} from "types/charts"
import {OrderBy} from "@biron-data/bqconf"

interface Props {
  widths: number[]
  columns: TableColumn[]
  onSort: (sorters: OrderBy[]) => void
  sorters: OrderBy[]
}

const TableChartHeaderRow = memo<Props>(function HeaderRow({widths, columns, sorters, onSort}) {
  const deleteSort = useCallback(() => {
    onSort([])
  }, [onSort])

  const updateSort = useCallback((orderByIndex: number, column: number) => {
    onSort([{
      column,
      asc: sorters && sorters[orderByIndex] && !sorters[orderByIndex].asc,
    }])
  }, [onSort, sorters])

  const addSort = useCallback((index: number) => {
    onSort([{
      column: index,
      asc: true,
    }])
  }, [onSort])

  const onCellSort = useCallback(
    (index: number) => {
      const existingSortIndex = sorters.findIndex(sort => sort.column === index)
      if (existingSortIndex === -1) {
        addSort(index)
      } else {
        if (sorters[existingSortIndex].asc) {
          updateSort(existingSortIndex, index)
        } else {
          deleteSort()
        }
      }
    },
    [addSort, deleteSort, sorters, updateSort],
  )

  return <BironTableRowHeader>
    {/* header columns */}
    {columns.map((column, i) => {
      return <TableChartHeaderCell
        key={i}
        column={column}
        index={i}
        onSort={onCellSort}
        asc={sorters && sorters.find(sort => sort.column === i)?.asc}
        width={widths[i]}
      />
    })}
  </BironTableRowHeader>
})

export default TableChartHeaderRow


const BironTableRowHeader = styled.tr`
  height: 57px;
`
