export enum FormItemKeys {
  TYPE = 'type',
  TITLE = 'title',
  NAME = 'name',
  PERIOD = 'period',
  SLICERS = 'slicers',
  FILTERS = 'filters',
  CSS_CLASS = 'cssClass',
  STYLE = 'style',
  DESCRIPTION = 'description',
  PARENT_MENU = 'parentMenu',
  POSITION_MENU = 'positionMenu',
  POSITION_DASHBOARD = 'positionDashboard',
  CONFIGURATION = 'configuration',
  URI = 'uri',
  HOMEPAGE = 'homepage',
  MENU = 'menu',
  DATAMODEL = 'datamodelCode'
}
