/* eslint-disable max-lines */
import React, {useCallback, useEffect, useMemo} from "react"
import {useDebouncedCallback} from "use-debounce"
import {isEmpty} from "@biron-data/react-components"
import {ChartSource, DataSourceObjectType, ObjectType, UsageTypes} from "components/dataSourceDoc/DataSource.types"
import {Statistic} from "antd"
import Language from "language"
import {Location, useLocation} from "react-router-dom"
import {ChartDividerDtoDetail, ChartGenericDtoDetail, ChartTargetDtoDetail} from "types/charts"
import {Export} from "components/forms/Form.types"
import {WidgetTypes} from "commons/dashboard/dashboard.types"
import {extractSlicersDimension, MetricDtoDetail} from "@biron-data/bqconf"
import {ExpandedWorkspace, Menu} from "redux/models/workspace"
import {deburr} from "lodash"
import {usePrevious} from "@biron-data/react-hooks"

const indexOfSearchedValue = (searchedValue: string, value?: string) => searchedValue.length > 0 ? deburr(value)?.toLowerCase().indexOf(deburr(searchedValue).toLowerCase()) ?? -1 : -1

export const useTextWithHighLightAsString = (search?: string) => useCallback((baseValue?: string) => {
  if (!search || !baseValue) {
    return baseValue
  }
  const index = indexOfSearchedValue(search, baseValue)
  if (isEmpty(index) || index === -1) {
    return baseValue
  }
  const firstPart = baseValue.substring(0, index)
  const secPart = baseValue.substring(index, index + search.length)
  const thirdPart = baseValue.substring(index + search.length)

  return `${firstPart}<span style="background: rgba(242, 140, 90, 0.50);">${secPart}</span>${thirdPart}`
}, [search])

export const useTextWithHighLight = (search?: string) => useCallback((baseValue?: string) => {
  if (!search || !baseValue) {
    return baseValue
  }
  const index = indexOfSearchedValue(search, baseValue)

  if (isEmpty(index) || index === -1) {
    return baseValue
  }
  const firstPart = baseValue.substring(0, index)
  const secPart = baseValue.substring(index, index + search.length)
  const thirdPart = baseValue.substring(index + search.length)

  return <span>{firstPart}<span style={{background: "rgba(242, 140, 90, 0.50)"}}>{secPart}</span>{thirdPart}</span>
}, [search])

export const useSearchOnValues = <T extends { [key: string]: any }, >(search: string, filteredProperties: string[][], data: T[], setter: (filtereValues: T[]) => void) => {
  const previousSearch = usePrevious(search)
  const filterData = useCallback(() => {
    setter(search && search.length > 0 ? data.filter(d => {
      return filteredProperties.find(properties => {
        if (properties.length === 1) {
          return indexOfSearchedValue(search, d[properties[0]]) !== -1
        }
        return indexOfSearchedValue(search, d[properties[0]]?.[properties[1]]) !== -1
      })
    }) : data)
  }, [data, filteredProperties, search, setter])

  const filterDataDebounced = useDebouncedCallback(filterData, 350)

  useEffect(() => {
    if (search !== previousSearch) {
      filterDataDebounced()
    }
  }, [filterDataDebounced, data, filteredProperties, search, setter, previousSearch])
}

export const useGetSourceColor = () => useCallback((source: ChartSource) => {
  switch (source) {
    case ChartSource.FILTER:
      return "#f3c0a7"
    case ChartSource.SLICER:
      return "#a2b0f7"
    default:
      return "transparent"
  }
}, [])

export const useStats = (usages: UsageTypes[], sources: ChartSource[]) => useMemo(() => {
  const sourceList = usages.map(u => u.source)
  const occurrences: { [key: string]: number } = {}

  sources.forEach(item => {
    occurrences[item] = 0
  })

  sourceList.forEach(item => {
    occurrences[item] += 1
  })

  return Object.entries(occurrences).map(([source, occurence]) => <Statistic
      key={source}
      title={Language.get('admin.dataDoc.details.stats', Language.get('admin.dataDoc.details.sources', source))}
      value={occurence}
      valueStyle={{color: 'var(--primary)'}}
    />,
  )
}, [sources, usages])

const getDataSourceLink = (type: DataSourceObjectType, location: Location, code?: string) => {
  const locationParts = location.pathname.split("/")
  const indexOfWorkspace = locationParts.indexOf("app") + 1
  return indexOfWorkspace < (locationParts.length - 1) && code ? `/app/${locationParts[indexOfWorkspace]}/help/${type}/${code}` : undefined
}

export const useDataDocDimensionLink = () => {
  const location = useLocation()
  return useCallback((code?: string) => {
    return getDataSourceLink(DataSourceObjectType.DIMENSION, location, code)
  }, [location])
}

export const useDataDocMetricLink = () => {
  const location = useLocation()
  return useCallback((code?: string) => {
    return getDataSourceLink(DataSourceObjectType.METRIC, location, code)
  }, [location])
}

export const useDataDocViewLink = () => {
  const location = useLocation()
  return useCallback((code?: string) => {
    return getDataSourceLink(DataSourceObjectType.VIEW, location, code)
  }, [location])
}

export const useTypeRepresentationColor = () => useCallback((dataSourceObjectType: DataSourceObjectType) => {
  switch (dataSourceObjectType) {
    case DataSourceObjectType.DIMENSION:
      return "#f3c0a7"
    case DataSourceObjectType.VIEW:
      return "#a0ecc8"
    case DataSourceObjectType.METRIC:
      return "#a2b0f7"
    default:
      return "transparent"
  }
}, [])

const useFilters = () => useCallback((obj: ChartGenericDtoDetail | ChartTargetDtoDetail | ChartDividerDtoDetail | Export, code?: string) => {
  switch (obj.type) {
    case WidgetTypes.GENERIC:
      return obj.filters.find(filter => filter.dimensionCode === code)
    case WidgetTypes.EXPORT:
      return obj.filters.find(filter => filter.dimensionCode === code)
    default:
      return undefined
  }
}, [])

const useSlicers = () => useCallback((obj: ChartGenericDtoDetail | ChartTargetDtoDetail | ChartDividerDtoDetail | Export, code?: string) => {
  switch (obj.type) {
    case WidgetTypes.GENERIC:
      return extractSlicersDimension(obj.slicers).find(d => d?.dimensionCode === code)
    case WidgetTypes.EXPORT:
      extractSlicersDimension(obj.slicers).find(d => {
        return d?.dimensionCode === code
      })
      return extractSlicersDimension(obj.slicers).find(d => d?.dimensionCode === code)
    default:
      return undefined
  }
}, [])

const useMetrics = () => useCallback((obj: ChartGenericDtoDetail | ChartTargetDtoDetail | ChartDividerDtoDetail | Export, code: string) => {
  switch (obj.type) {
    case WidgetTypes.TARGET:
      return code === obj.metricCode ? {
        metricCode: obj.metricCode,
        viewCode: obj.viewCode,
      } as MetricDtoDetail : undefined
    case WidgetTypes.GENERIC:
      return obj.metrics.find(m => m.metricCode === code)
    case WidgetTypes.EXPORT:
      return obj.metrics.find(m => m.metricCode === code)
    default:
      return undefined
  }
}, [])

export const useDimensionUsages = (
  currentExports: Export[],
  currentWorkspace: ExpandedWorkspace,
  code?: string): UsageTypes[] => {
  const getFilters = useFilters()
  const getSlicers = useSlicers()
  const menuList = [{id: -1, name: "-", dashboards: [currentWorkspace.homepage]} as Menu, ...currentWorkspace.menus] as Menu[]
  const result: UsageTypes[] = []

  menuList.forEach(menu => {
    menu.dashboards.forEach(dash => {
      const hasSlicersUsage = dash.filters.find(filter => filter.dimensionCode === code)
      dash.charts.forEach(chart => {
        const chartFilters = getFilters(chart, code)
        const chartSlicers = getSlicers(chart, code)

        if (chartFilters) {
          result.push({
            type: "Chart",
            object: {
              type: ObjectType.CHART,
              id: chart.id,
              title: chart.title,
            },
            dashboard: {
              uri: dash.uri,
              title: dash.title,
            },
            menu: {
              title: menu.name,
            },
            source: ChartSource.FILTER,
          })
        }
        if (chartSlicers) {
          result.push({
            type: "Chart",
            object: {
              type: ObjectType.CHART,
              id: chart.id,
              title: chart.title,
            },
            dashboard: {
              uri: dash.uri,
              title: dash.title,
            },
            menu: {
              title: menu.name,
            },
            source: ChartSource.SLICER,
          })
        }
      })
      if (hasSlicersUsage) {
        result.push({
          type: "Dashboard",
          object: {
            type: ObjectType.DASHBOARD,
            id: dash.id,
            title: dash.title,
            uri: dash.uri,
          },
          menu: {
            title: menu.name,
          },
          source: ChartSource.FILTER,
        })
      }
    })
  })

  currentExports.forEach(exp => {
    const exportFilters = getFilters(exp, code)
    const exportSlicers = getSlicers(exp, code)

    if (exportFilters) {
      result.push({
        type: "Export",
        object: {
          type: ObjectType.EXPORT,
          id: exp.id,
          title: exp.title,
        },
        source: ChartSource.FILTER,
      })
    }
    if (exportSlicers) {
      result.push({
        type: "Export",
        object: {
          type: ObjectType.EXPORT,
          id: exp.id,
          title: exp.title,
        },
        source: ChartSource.SLICER,
      })
    }
  })

  return result
}

export const useMetricsUsages = (
  currentExports: Export[],
  currentWorkspace: ExpandedWorkspace,
  code: string): UsageTypes[] => {
  const getMetrics = useMetrics()

  const menuList = [{id: -1, name: "-", dashboards: [currentWorkspace.homepage]} as Menu, ...currentWorkspace.menus] as Menu[]
  const result: UsageTypes[] = []

  menuList.forEach(menu => {
    menu.dashboards.forEach(dash => {
      dash.charts.forEach(chart => {
        const chartMetrics = getMetrics(chart, code)

        if (chartMetrics) {
          result.push({
            type: "Chart",
            object: {
              type: ObjectType.CHART,
              id: chart.id,
              title: chart.title,
            },
            dashboard: {
              uri: dash.uri,
              title: dash.title,
            },
            menu: {
              title: menu.name,
            },
            source: ChartSource.METRIC,
          })
        }
      })
    })
  })

  currentExports.forEach(exp => {
    const chartMetrics = getMetrics(exp, code)

    if (chartMetrics) {
      result.push({
        type: "Export",
        object: {
          type: ObjectType.EXPORT,
          id: exp.id,
          title: exp.title,
        },
        source: ChartSource.METRIC,
      })
    }
  })

  return result
}