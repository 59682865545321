import React, {forwardRef, PropsWithChildren} from 'react'
import {WrapperItem, WrapperRoot} from "@biron-data/react-components"
import {ConfigurationWithDisabledReasons} from "components/forms/chart/types"
import {ChartFormat} from "@biron-data/bqconf"
import ChartFormatSelector from '../selector/chartFormat/ChartFormatSelector'

export interface Props<T> extends PropsWithChildren {
  name: string
  initialValue?: T
}

export const ChartFormatWrapper = <T, >({children, name, initialValue}: Props<T>) => <WrapperRoot>
  <WrapperItem name={name} initialValue={initialValue}>
    {children}
  </WrapperItem>
</WrapperRoot>

interface FieldProps {
  configuration: Omit<ConfigurationWithDisabledReasons<ChartFormat>, "nextCompatibleTypes">[],
  defaultFormat?: ChartFormat
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const ChartFormatField = forwardRef<any, FieldProps>(function Field({...props}, ref) {
  return <ChartFormatSelector {...props}/>
})
