import React, {FunctionComponent} from 'react'
import {Col, Input, Row} from 'antd'
import styled from "styled-components"
import Language from "language";

export interface Boundaries {
    min: number
    max: number
}

interface Props {
  value?: Boundaries,
  onChange?: (data: Boundaries) => void,
}

const BoundariesSelector: FunctionComponent<Props> = ({
                                                       value,
                                                       onChange,
                                                     }) => {

  return <AlignedRow>
      <Col span={2}>
          <div>{Language.get('configuration-boundary-selector.start')}</div>
      </Col>
      <Col span={3}>
        <Input value={value?.min} placeholder={"0"} type={"number"} onChange={(event) => onChange?.({
            max: (value?.max ?? 0) < Number(event.target.value) ? Number(event.target.value) : (value?.max ?? 0),
            min: Number(event.target.value)
        })}/>
      </Col>
      <Col span={2} offset={1}>
        <div>{Language.get('configuration-boundary-selector.end')}</div>
      </Col>
    <Col span={3}>
      <Input value={value?.max} placeholder={"0"} type={"number"} onChange={(event) => onChange?.({
              min: (value?.min ?? 0) > Number(event.target.value) ? Number(event.target.value) : (value?.min ?? 0),
              max: Number(event.target.value)
          })}/>
      </Col>
  </AlignedRow>
}

export default BoundariesSelector

const AlignedRow = styled(Row)`
    align-items: center;
`
