import {DatamodelRoles, WorkspaceRoles} from "./UserManager.types";
import {Popover} from "antd";
import {IconContainer} from "@biron-data/react-components";
import React, {ReactNode} from "react";
import {
  EyeIcon, FolderIcon, PencilIcon,
  UsersIcon
} from "@heroicons/react/outline";
import styled from "styled-components";
import Language from "../../../language";
import {TooltipPlacement} from "antd/es/tooltip";
import {API, Excel, GSheet} from "@biron-data/icons/dist/outlined";

export const wrapWithTooltip = (
  element: ReactNode,
  role: DatamodelRoles,
  summary: string,
  details: ReactNode[],
  otherTools: boolean,
  placement: TooltipPlacement) => {
  return <Popover {...{
    placement,
    title: <DatamodelRightsTooltip>
      <RoleContainer>{Language.get(`admin.user.roles.${role}`)}</RoleContainer>
      <Summary>{summary}</Summary>
      <Title>{Language.get(`admin.user.tooltips.datamodel.title`)}</Title>
      <div>{Language.get(`admin.user.tooltips.datamodel.description`)}</div>
      {details}
      {otherTools && <DetailLine $includeBorder={true}>
        <Title>{Language.get("admin.user.tooltips.datamodel.others.title")}</Title>
        {datamodelOthersDetails}
      </DetailLine>}
    </DatamodelRightsTooltip>,
    mouseEnterDelay: 0.5,

    arrow: {pointAtCenter: true},
    autoAdjustOverflow: true,
  }}>{
    element
  }</Popover>
}

const Title = styled.div`
    font-weight: 500;
`

const DetailLine = styled.div<{$includeBorder: boolean}>`
    ${({$includeBorder}) => $includeBorder ? 'border-top: 1px solid var(--border-color-base);' : ''}
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
`

const RoleContainer = styled.div`
    display: flex;
    padding-bottom: 10px;
    gap: 10px;
    font-size: 19px;
    font-weight: 500;
    justify-content: center;
    width: 100%;
`
const DatamodelRightsTooltip = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px;
    gap: 10px;
`

const PrimaryDiv = styled.div`
    color: var(--primary);
`

const Summary = styled.div`
    font-weight: 400;
    font-style: italic;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    vertical-align: middle;
`
const FlexDiv = styled.div`
  
  display: flex;
    flex-direction: row;   
    align-items: center;
    gap: 10px;
  `
export const datamodelReaderDetails = <>
  <FlexDiv><IconContainer color={"var(--primary)"}><EyeIcon/></IconContainer>{Language.get("admin.user.tooltips.datamodel.canBe")}<PrimaryDiv>{Language.get(`admin.user.roles.${WorkspaceRoles.WORKSPACE_READER}`)}</PrimaryDiv></FlexDiv>
</>

export const datamodelManagerDetails = <>
  <FlexDiv><IconContainer color={"var(--primary)"}><PencilIcon/></IconContainer>{Language.get("admin.user.tooltips.datamodel.canBe")}<PrimaryDiv>{Language.get(`admin.user.roles.${WorkspaceRoles.WORKSPACE_WRITER}`)}</PrimaryDiv></FlexDiv>
  <FlexDiv><IconContainer color={"var(--primary)"}><UsersIcon/></IconContainer>{Language.get("admin.user.tooltips.datamodel.canBe")}<PrimaryDiv>{Language.get(`admin.user.roles.${WorkspaceRoles.WORKSPACE_MANAGER}`)}</PrimaryDiv></FlexDiv>
  <FlexDiv><IconContainer color={"var(--primary)"}><FolderIcon/></IconContainer>{Language.get("admin.user.tooltips.datamodel.manager.personalDashboard")}</FlexDiv>
</>

export const datamodelOthersDetails = <>
  <FlexDiv><IconContainer color={"var(--primary)"}><Excel/></IconContainer>{Language.get("admin.user.tooltips.datamodel.others.excel")}</FlexDiv>
  <FlexDiv><IconContainer color={"var(--primary)"}><GSheet/></IconContainer>{Language.get("admin.user.tooltips.datamodel.others.gsheet")}</FlexDiv>
  <FlexDiv><IconContainer color={"var(--primary)"}><API/></IconContainer>{Language.get("admin.user.tooltips.datamodel.others.api")}</FlexDiv>
</>
