import React, {memo} from 'react'
import styled from "styled-components"
import {
  IconContainer,
} from "@biron-data/react-components"
import {EyeIcon, PencilIcon, UsersIcon} from "@heroicons/react/outline";
import Language from "../../../language";
import {WorkspaceRoles} from "./UserManager.types";
import {workspaceManagerDetails, workspaceReaderDetails, wrapWithTooltip, workspaceWriterDetails} from "./WorkspaceRights.utils";
import {TooltipPlacement} from "antd/es/tooltip";

interface Props {
  roles: WorkspaceRoles[]
  layout: "row" | "column"
  justifyContent?: "start" | "end"
  tooltipPlacement: TooltipPlacement
}

// eslint-disable-next-line react/display-name
const WorkspaceRights = memo<Props>(({roles,
                                       layout,
                                       justifyContent,
                                        tooltipPlacement}) => {
  const isReader = roles?.includes(WorkspaceRoles.WORKSPACE_READER)
  const isWriter = roles?.includes(WorkspaceRoles.WORKSPACE_WRITER)
  const isManager = roles?.includes(WorkspaceRoles.WORKSPACE_MANAGER)

  return <FlexDiv $layout={layout} $justifyContent={justifyContent}>
    {wrapWithTooltip(<FlexDiv $layout={"row"} $justifyContent={justifyContent}>
      <IconContainer key="reader" color={isReader ? "var(--primary)" : undefined}><EyeIcon/></IconContainer>

      {
        layout === "column" && Language.get('admin.workspace.rights.reader.description')
      }
    </FlexDiv>, WorkspaceRoles.WORKSPACE_READER,
      Language.get('admin.user.tooltips.reader.summary'),
      [
        workspaceReaderDetails
      ],
      tooltipPlacement
    )}
    {wrapWithTooltip(<FlexDiv $layout={"row"} $justifyContent={justifyContent}>
      <IconContainer key="writer" color={isWriter ? "var(--primary)" : undefined}><PencilIcon/></IconContainer>
      {
        layout === "column" && Language.get('admin.workspace.rights.writer.description')
      }
    </FlexDiv>, WorkspaceRoles.WORKSPACE_WRITER,
      Language.get('admin.user.tooltips.writer.summary'),
      [
        workspaceReaderDetails,
        workspaceWriterDetails
      ],
      tooltipPlacement
    )}
    {wrapWithTooltip(<FlexDiv $layout={"row"} $justifyContent={justifyContent}>
      <IconContainer key="manager" color={isManager ? "var(--primary)" : undefined}><UsersIcon/></IconContainer>
      {
        layout === "column" && Language.get('admin.workspace.rights.manager.description')
      }
    </FlexDiv>, WorkspaceRoles.WORKSPACE_MANAGER,
      Language.get('admin.user.tooltips.manager.summary'),
      [
        workspaceReaderDetails,
        workspaceWriterDetails,
        workspaceManagerDetails
      ],
      tooltipPlacement
    )}
  </FlexDiv>
})

export default WorkspaceRights

const FlexDiv = styled.div<{$layout: "row" | "column", $justifyContent?: "start" | "end"}>`
    display: flex;
    ${({$justifyContent}) => `justify-content: ${$justifyContent ?? 'start'};`}
    ${({$layout}) => `flex-direction: ${$layout};`}
    gap: 10px;
`
