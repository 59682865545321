import {createSelector} from "reselect"
import {sortBy} from 'lodash'
import {denormalizeWorkspaceDetail, NormalizedWorkspaceDetail} from "schemas/workspace"
import {ExpandedWorkspace} from "redux/models/workspace"
import {RootState} from "redux/store"
import {ClientWorkspace} from "redux/models/appEnvironment"
import {WorkspaceDashboardDto} from "types/dashboards"
import {getDataModels} from "./environment.selector";

export const isInitialized = (state: RootState) => state.workspace.initialized
export const isLoading = (state: RootState) => state.workspace.loading

export const getAvailables = createSelector(
  (state: RootState) => state.workspace.entities.workspaces,
  (keyedWorkspaces) => sortBy(Object.values(keyedWorkspaces), w => w.name.toLocaleLowerCase()),
)

export const getAvailablesForAce = createSelector(
  (state: RootState) => {
    return state.appEnvironment?.environment.workspaces
  },
  (keyedWorkspaces) => {
    return sortBy(Object.values(keyedWorkspaces as ClientWorkspace[]), w => w.name.toLocaleLowerCase())
  },
)

export const getDashboards = (state: RootState) => state.workspace.entities.dashboards
export const getCurrentWorkspace = (state: RootState): NormalizedWorkspaceDetail | undefined => state.workspace.currentWorkspace
export const getCurrentWorkspaceId = (state: RootState) => getCurrentWorkspace(state)?.id as number
export const getCurrentWorkspaceUri = (state: RootState) => getCurrentWorkspace(state)?.uri
export const getCurrentWorkspaceName = (state: RootState) => getCurrentWorkspace(state)?.name
export const getCurrentWorkspaceDatamodel = (state: RootState) => getDataModels(state).find(datamodel => datamodel.code === getCurrentWorkspace(state)?.datamodelCode)
export const getCurrentWorkspaceForBuildingUri = (state: RootState) => state.workspace.entities.workspaces[getCurrentWorkspaceId(state) as number]
export const getCurrentWorkspaceExpanded = createSelector(
  getCurrentWorkspace,
  (state: RootState) => state.workspace.entities.menus,
  (state: RootState) => state.workspace.entities.dashboards,
  (state: RootState) => state.workspace.entities.charts,
  (workspace: NormalizedWorkspaceDetail | undefined, menus, dashboards, charts): ExpandedWorkspace | undefined => workspace ? denormalizeWorkspaceDetail(workspace.id, {
    workspaces: {
      [workspace.id]: workspace,
    },
    menus,
    dashboards,
    charts,
  }) : undefined,
)

export const findWorkspaceDashboardInfosWith = (state: RootState, key: keyof WorkspaceDashboardDto, value: any) => {
  const dashboard = Object.values(state.workspace.entities.dashboards).find(candidate => candidate[key] === value)
  if (!dashboard) {
    // eslint-disable-next-line no-warning-comments
    return null // Fixme this should return {dashboard: null, menu: null}
  }
  const menu = getCurrentWorkspace(state)?.menus
    .map(menuId => state.workspace.entities.menus[menuId])
    .find(candidate => candidate.dashboards.includes(dashboard.id))
  const charts = Object.fromEntries(dashboard.charts
    .map(chartId => [chartId, state.workspace.entities.charts[chartId]]))
  return {
    dashboard,
    menu,
    charts,
  }
}
