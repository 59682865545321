import {IconContainer} from "@biron-data/react-components";
import {KeyIcon, StarIcon, TrashIcon, UserIcon} from "@heroicons/react/outline";
import React, {ReactNode, SVGProps} from "react";
import styled from "styled-components";
import {DatamodelRoles, Roles, WorkspaceRoles} from "./UserManager.types";
import Language from "../../../language";
import {Tooltip} from "antd";
import {UserPosition} from "../../../hooks/useUACWithAces";

export const isWorkspaceRole = (role: Roles): role is WorkspaceRoles => {
  return Object.values(WorkspaceRoles).includes(role as WorkspaceRoles);
}

export const isDatamodelRole = (role: Roles): role is DatamodelRoles => {
  return Object.values(DatamodelRoles).includes(role as DatamodelRoles);
}

export const isEnvironmentRole = (role: Roles): role is DatamodelRoles => {
  return Object.values(DatamodelRoles).includes(role as DatamodelRoles);
}

const wrapWithTooltip = (element: ReactNode, title: string, Icon: (props: React. SVGProps<SVGSVGElement>) => JSX. Element, description : string) => {
  return <Tooltip {...{
    placement: "top",
    title: <RightsTooltip>
      <FlexTitle><IconContainer><Icon/></IconContainer> {title}</FlexTitle>
      <div>{description}</div>
    </RightsTooltip>,
    mouseEnterDelay: 0.5,
    // overlayStyle: {minWidth: 400},
    overlayInnerStyle: {minWidth: 300},
    arrow: {pointAtCenter: true},
    autoAdjustOverflow: true,
  }}>{
    element
  }</Tooltip>
}

const FlexTitle = styled.div`
    display: flex;
    padding-bottom: 10px;
    gap: 10px;
`

const RightsTooltip = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px;
    gap: 10px;
`

const FlexDiv = styled.div`
    display: flex;
    justify-content: start;
    gap: 10px;
`

export const getUserPosition = (position: UserPosition) => {
  switch (position) {
    case UserPosition.ACTIVE:
      return wrapWithTooltip(<FlexDiv>
        <IconContainer><UserIcon/></IconContainer>
        {Language.get("admin.user.content.user")}
      </FlexDiv>, Language.get("admin.user.content.user"), UserIcon, Language.get('admin.user.tooltips.user'))
    case UserPosition.INACTIVE:
      return wrapWithTooltip(<FlexDiv>
          <IconContainer><TrashIcon/></IconContainer>
          {Language.get("admin.user.content.inactive")}
        </FlexDiv>, Language.get("admin.user.content.inactive"), TrashIcon, Language.get('admin.user.tooltips.inactive')
      )
    case UserPosition.ADMIN:
      return wrapWithTooltip(<FlexDiv>
        <IconContainer><StarIcon/></IconContainer>
        {Language.get("admin.user.content.admin")}
      </FlexDiv>, Language.get("admin.user.content.superAdmin"), StarIcon, Language.get('admin.user.tooltips.admin'))
    case UserPosition.SUPERADMIN:
      return wrapWithTooltip(<FlexDiv>
        <IconContainer><KeyIcon/></IconContainer>
        {Language.get("admin.user.content.superAdmin")}
      </FlexDiv>, Language.get("admin.user.content.superAdmin"), KeyIcon, Language.get('admin.user.tooltips.admin'))
    default: {
      // eslint-disable-next-line no-case-declarations
      const exhaustiveCheck: never = position
      return exhaustiveCheck
    }
  }
}

