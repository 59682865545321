import React, {memo} from 'react'
import {Loader, WrapperItem, WrapperRoot} from "@biron-data/react-components"
import {FormBodyComponentProps} from "components/forms/confBodies/FormBody"
import {CogIcon} from "@heroicons/react/outline"
import TextArea from "antd/lib/input/TextArea"
import Language from "../../../language"

const WorkspaceConf = <T, >({
                                   loading,
                                 }: FormBodyComponentProps<T>) => {
  return <Loader loading={loading}>
      <WrapperRoot
          icon={CogIcon}
          label={'admin.workspaces.form.advancedWorkspace.configuration.title'}
          itemColumnWidth={6}>
          <WrapperItem name={"configuration"}>
              <TextArea placeholder={Language.get('admin.workspaces.form.advancedWorkspace.configuration.placeholder')}/>
          </WrapperItem>
      </WrapperRoot>
  </Loader>
}

export default memo(WorkspaceConf) as typeof WorkspaceConf
