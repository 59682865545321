import React, {forwardRef, FunctionComponent, useCallback, useEffect, useMemo} from 'react'
import {Button, Checkbox, Form, Input, Select} from 'antd'
import FormModal, {ConsolidatedFormProps} from "components/forms/Form.Modal"
import {locales} from "commons/locales/locales"
import Language from "language"
import {Popconfirm} from "@biron-data/react-components"
import {useFields} from "@biron-data/react-hooks"
import {UAC, User} from "components/admin/user/UserManager.types"
import {ModelUser} from "redux/models/user"
import {SimplifiedUserFormProps, UserConfModel} from "components/forms/chart/types"
import {FormInstance} from "antd/lib/form/hooks/useForm"
import {PermissionsEnum} from "redux/models/appEnvironment"

type UserFormProps = SimplifiedUserFormProps & ConsolidatedFormProps<UserConfModel>

// eslint-disable-next-line react/display-name
const FormComponent = forwardRef<FormInstance, UserFormProps>(({data, errors, onValuesChange, userCanSetPowerUser, defaultEmailFqdn}, ref) => {
  const [form] = Form.useForm()
  const fields = useFields(data, errors)

  useEffect(() => {
    if (Object.entries(data).length === 0) {
      form.resetFields()
    }
  }, [form, data])

  return <Form ref={ref} form={form} fields={fields} name={Object.entries(data).length > 0 ? 'editUser' : 'newUser'}
               labelCol={{span: 6}} wrapperCol={{span: 18}} onValuesChange={onValuesChange}>
    <Form.Item label={Language.get('admin.user.forms.email')} name={'email'} initialValue={`@${defaultEmailFqdn}`} rules={[{type: 'email'}, {required: true}]}>
      <Input/>
    </Form.Item>
    <Form.Item label={Language.get('admin.user.forms.firstName')} name={'firstName'} rules={[{required: true}]}>
      <Input />
    </Form.Item>
    <Form.Item label={Language.get('admin.user.forms.lastName')} name={'lastName'} rules={[{required: true}]}>
      <Input/>
    </Form.Item>
    <Form.Item label={Language.get('admin.user.forms.locale')} name={'locale'} initialValue={locales[0]} rules={[{required: true}]}>
      <Select virtual={false}>
        {locales.map(locale => <Select.Option key={locale} value={locale}>{locale}</Select.Option>)}
      </Select>
    </Form.Item>
    {userCanSetPowerUser && <Form.Item label={Language.get('admin.user.forms.powerUser')} name={'powerUser'} valuePropName={'checked'}>
      <Checkbox/>
    </Form.Item>}
  </Form>
})

interface Props {
  uac?: UAC
  getPermission: (key: PermissionsEnum) => boolean
  clientAllowedEmailFqdns: string[]
  onResetPassword: (user: User) => void
  onDelete: (uac: UAC) => void
  onConfirm: (modelUser: ModelUser) => void
  onCancel: () => void
}

const UserForm: FunctionComponent<Props> = ({uac, getPermission, clientAllowedEmailFqdns, onResetPassword, onDelete, onConfirm, onCancel}) => {
  const userCanSetPowerUser = getPermission(PermissionsEnum.userCanSetPowerUser)
  const defaultEmailFqdn = clientAllowedEmailFqdns[0]

  const handleDelete = useCallback(() => {
    if (uac) {
      onDelete(uac)
    }
  }, [uac, onDelete])
  const handleResetPassword = useCallback(() => {
    if (uac) {
      onResetPassword(uac.user)
    }
  }, [uac, onResetPassword])
  const extraButtons = useMemo(() => {
    if (uac && uac.id) {
      if (uac.active) {
        return [
          <Button key="resetPassword" type="primary"
                  onClick={handleResetPassword}>{Language.get('admin.user.actions.resetPassword')}</Button>,
        ]
      } else {
        return [
          <Popconfirm key="delete" title={Language.get('admin.user.actions.deleteUserConfirm')} onConfirm={handleDelete}>
            <Button danger>{Language.get('admin.user.actions.deleteUser')}</Button>
          </Popconfirm>,
        ]
      }
    } else {
      return []
    }
  }, [uac, handleDelete, handleResetPassword])
  const formProps = useMemo(() => ({
      defaultEmailFqdn,
      userCanSetPowerUser,
    }),
    [defaultEmailFqdn, userCanSetPowerUser],
  )

  const data: UserConfModel = useMemo(() => (uac && uac.user) || {}, [uac])

  return <FormModal<UserConfModel, SimplifiedUserFormProps> {...{
    defaultTitle: uac && uac.user.id ? Language.get('admin.user.forms.updateTitle', {user: uac.user}) : Language.get('admin.user.forms.createTitle'),
    open: !!uac,
    data,
    width: 700,
    renderFormComponent: (props) => <FormComponent {...props}/>,
    extraButtons,
    onConfirm,
    onCancel,
    formProps,
  }} />
}
export default UserForm
