import React, {memo} from 'react'
import {Loader, WrapperItem, WrapperRoot} from "@biron-data/react-components"
import {FormBodyComponentProps} from "components/forms/confBodies/FormBody"
import {Input, Select} from "antd";
import {DatabaseIcon, PencilIcon} from "@heroicons/react/outline";
import Language from "../../../language";
import {useSelector} from "react-redux";
import {getDataModels} from "../../../redux/environment.selector";

const WorkspaceConf = <T, >({
                                   loading,
                                 }: FormBodyComponentProps<T>) => {
  const datamodels = useSelector(getDataModels)
  return <Loader loading={loading}>
      <WrapperRoot
          icon={PencilIcon}
          label={'admin.workspaces.form.workspace.name.title'}>
          <WrapperItem name={"name"}>
              <Input placeholder={Language.get('admin.workspaces.form.workspace.name.placeholder')}/>
          </WrapperItem>
      </WrapperRoot>
      <WrapperRoot
        icon={DatabaseIcon}
        label={'admin.workspaces.form.workspace.datamodel.title'} >
        <WrapperItem name={"datamodelCode"} rules={[{required: true, message: "Required field"}]}>
          <Select placeholder={Language.get('admin.workspaces.form.workspace.datamodel.placeholder')}
                  options={datamodels.map(dataModel => ({
                    value: dataModel.code,
                    label: dataModel.alias,
                  }))}/>
        </WrapperItem>
      </WrapperRoot>
  </Loader>
}

export default memo(WorkspaceConf) as typeof WorkspaceConf
