import React, {FunctionComponent, useEffect, useMemo} from 'react'
import {useSelector} from "react-redux"
import {getCopiedChart} from "redux/clipboard.selector"
import {getCharts, getCurrentPersonalDashboard} from "redux/currentDashboard.selector"
import useDispatch from "hooks/useDispatch"
import DashboardErrorBoundary from 'components/dashboard/Dashboard.ErrorBoundary'
import {MetaModel} from "@biron-data/bqconf"
import Dashboard from "components/dashboard/Dashboard"
import {getCurrentEnvironmentId} from "redux/environment.selector"
import {IconContainer} from "@biron-data/react-components"
import {BookmarkIcon, DocumentIcon, MailIcon, TrashIcon} from "@heroicons/react/outline"
import styled from "styled-components"
import {Folder} from "types/dashboards"
import Language from "language"
import {
  getPermission,
} from "redux/appEnvironment.selector"
import {PermissionsEnum} from "redux/models/appEnvironment"
import {omit} from "lodash"
import {getCurrentWorkspaceDatamodel} from "../../redux/workspace.selector";

interface Props {
  metaModel: MetaModel
}

const PersonalDashboardContainer: FunctionComponent<Props> = ({
                                                                metaModel,
                                                              }) => {
  const dispatch = useDispatch()
  const currentDashboard = useSelector(getCurrentPersonalDashboard)
  const copiedChart = useSelector(getCopiedChart())
  const environmentId = useSelector(getCurrentEnvironmentId)
  const datamodel = useSelector(getCurrentWorkspaceDatamodel)
  const charts = useSelector(getCharts)
  const dashboardPCanEditAll = useSelector(getPermission)(PermissionsEnum.dashboardPCanEditAll)

  useEffect(() => {
    document.title = ["Draft", currentDashboard?.dashboard?.title ?? "", 'Biron'].join(' - ')
    return () => {
      document.title = 'Biron'
    }
  }, [currentDashboard])

  const availableDimensions = metaModel?.listDimensions()

  const dashboardTitlePrefix = useMemo(() => {
    if (!currentDashboard?.ownedByUser) {
      return <FlexDev>
        <IconContainer size={24}><MailIcon/></IconContainer>
        {Language.get('personal-dashboard.header.prefix.external-draft')}
      </FlexDev>
    }
    switch (currentDashboard?.dashboard?.folder) {
      case Folder.STARRED:
        return <FlexDev>
          <IconContainer size={24}><BookmarkIcon/></IconContainer>
          {Language.get('personal-dashboard.header.prefix.favorite')}
        </FlexDev>
      case Folder.TRASH:
        return <FlexDev>
          <IconContainer size={24}><TrashIcon/></IconContainer>
          {Language.get('personal-dashboard.header.prefix.trash')}
        </FlexDev>
      case Folder.DRAFT:
        return <FlexDev>
          <IconContainer size={24}><DocumentIcon/></IconContainer>
          {Language.get('personal-dashboard.header.prefix.draft')}
        </FlexDev>
      default:
        return ""
    }
  }, [currentDashboard?.dashboard?.folder, currentDashboard?.ownedByUser])

  const canEdit = useMemo(() => Boolean(currentDashboard?.ownedByUser) || dashboardPCanEditAll, [currentDashboard?.ownedByUser, dashboardPCanEditAll])

  return <DashboardErrorBoundary>
    {currentDashboard?.dashboard
      && currentDashboard?.selection && datamodel?.code && <Dashboard {...{
        metaModel,
        availableDimensions,
        dashboardTitlePrefix,
        dashboard: omit(currentDashboard?.dashboard, "charts"),
        environmentId,
        datamodel,
        queryParam: {
          type: "environment",
          environmentId,
          datamodelCode: datamodel.code
        },
        currentSelection: currentDashboard?.selection,
        charts,
        canEditDashboardContent: canEdit,
        canEditDashboardProperties: canEdit,
        editMode: canEdit,
        copiedChart,
        onConfChange: dispatch.currentDashboard.confUpdate,
        onLayoutChange: dispatch.currentDashboard.layoutUpdate,
        onChartAdd: dispatch.currentDashboard.chartAdd,
        onSelectionChange: dispatch.currentDashboard.selectionUpdate,
      }}/>}
  </DashboardErrorBoundary>
}

export default PersonalDashboardContainer

const FlexDev = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
`