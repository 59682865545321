import React, {memo} from 'react'
import Language from 'language'
import FormTriggerable from 'components/forms/Form.Triggerable'
import WorkspaceModel from 'classes/WorkspaceModel'
import {descriptionTypeNotPopover} from "components/forms/FormTrigger"
import {FormItemKeys} from "commons/keywords/form"
import {DuplicateIcon, PencilIcon, PlusCircleIcon} from "@heroicons/react/outline"
import {IconContainer} from "@biron-data/react-components"
import styled from "styled-components"
import {ExtraAction as ExtraActionType} from "types/navigation"
import {DashboardDto, HandleMenuActionParamTypes, MenuDto} from "components/workspace/WorkspaceBridge.SiderContainer"
import {MetaModel} from "@biron-data/bqconf"
import useDispatch from "hooks/useDispatch"
import {ExpandedWorkspace} from "redux/models/workspace"
import {FormKeys} from "components/forms/Form.types"

interface Props {
  conf: ExtraActionType
  onAction: (params: HandleMenuActionParamTypes) => void
  metaModel: MetaModel
  workspace: ExpandedWorkspace
}

const MainLayoutExtraAction = memo<Props>(function ExtraAction({conf, onAction, metaModel, workspace}) {
    const dispatch = useDispatch()

    if (conf.key === FormKeys.EDIT_MENU) {
      return <FormTriggerable {...{
        descriptionType: descriptionTypeNotPopover,
        trigger: <TriggerContainer><IconContainer size={14}><PencilIcon
          className={'disable-grab'}/></IconContainer></TriggerContainer>,
        formType: {type: conf.key},
        metaModel,
        workspace,
        onChange: (value: MenuDto) => {
          onAction({
            eventKey: FormKeys.EDIT_MENU,
            value,
            specificId: conf.data.menu.id,
          })
        },
        value: {
          ...conf.data.menu,
          [FormItemKeys.POSITION_MENU]: conf.data.menuIndex,
        },
        name: conf.data.menu.name,
        title: Language.get('navigation-tools-edit-menu'),
        altSubmit: {
          title: Language.get('navigation-tools-delete-menu'),
          confirm: {
            title: Language.get('navigation-tools-delete-menu-confirmation'),
            on: {
              open: () => {
              },
              ok: () => {
                dispatch.workspace.deleteMenu(conf.data.menu.id)
              },
              cancel: () => {
              },
            },
          },
        },
      }}/>
    } else if (conf.key === FormKeys.ADD_DASHBOARD) {
      const parentMenuId = conf.data.menu.id
      return <FormTriggerable {...{
        descriptionType: descriptionTypeNotPopover,
        trigger: <TriggerContainer><IconContainer size={14}><PlusCircleIcon
          className={'disable-grab'}/></IconContainer></TriggerContainer>,
        formType: {type: conf.key},
        metaModel,
        workspace,
        onChange: (value: DashboardDto) => {
          onAction({
            eventKey: FormKeys.ADD_DASHBOARD,
            value,
            specificId: conf.data.menu.id,
          })
        },
        value: {
          [FormItemKeys.PARENT_MENU]: parentMenuId,
          [FormItemKeys.POSITION_DASHBOARD]: new WorkspaceModel(workspace).getDashboardsPositions(parentMenuId)[1].value,
        },
        name: Language.get('navigation-tools-add-dashboard'),
        title: Language.get('navigation-tools-add-dashboard'),
      }}/>
    } else if (conf.key === FormKeys.EDIT_DASHBOARD) {
      const {dashboard, menu: {id: parentMenuId}} = conf.data

      return <FormTriggerable {...{
        descriptionType: descriptionTypeNotPopover,
        trigger: <TriggerContainer><IconContainer size={14}><PencilIcon
          className={'disable-grab'}/></IconContainer></TriggerContainer>,
        formType: {type: conf.key},
        metaModel,
        workspace,
        dashboardId: conf.data.dashboard.id,
        onChange: (value: DashboardDto) => {
          onAction({
            eventKey: conf.key,
            value,
            specificId: dashboard.id,
          })
        },
        value: {
          ...dashboard,
          [FormItemKeys.PARENT_MENU]: parentMenuId,
          [FormItemKeys.POSITION_DASHBOARD]: `${conf.data.dashboardIndex}`,
        },
        name: dashboard.title,
        title: Language.get('navigation-tools-edit-dashboard'),
        altSubmit: {
          title: Language.get('navigation-tools-delete-dashboard'),
          confirm: {
            title: Language.get('navigation-tools-delete-dashboard-confirmation'),
            on: {
              open: () => {
              },
              ok: () => {
                dispatch.workspace.deleteDashboard({
                  dashboardId: dashboard.id,
                  menuId: parentMenuId,
                })
              },
              cancel: () => {
              },
            },
          },
        },
      }}/>
    } else if (conf.key === FormKeys.CLONE_DASHBOARD) {
      const parentMenuId = conf.data.menu.id
      return <FormTriggerable {...{
        descriptionType: descriptionTypeNotPopover,
        trigger: <TriggerContainer><IconContainer size={14}><DuplicateIcon
          className={'disable-grab'}/></IconContainer></TriggerContainer>,
        formType: {type: conf.key},
        metaModel,
        workspace,
        dashboardId: conf.data.dashboard.id,
        onChange: (value: DashboardDto) => {
          onAction({
            eventKey: FormKeys.CLONE_DASHBOARD,
            value,
            specificId: conf.data.dashboard.id,
          })
        },
        value: {
          id: conf.data.dashboard.id,
          [FormItemKeys.PARENT_MENU]: parentMenuId,
          [FormItemKeys.TITLE]: Language.get('form-title-copy-of-xxx', {ref: conf.data.dashboard.title}),
          [FormItemKeys.POSITION_DASHBOARD]: new WorkspaceModel(workspace).getDashboardsPositions(parentMenuId)[1].value,
        },
        name: Language.get('form-title-copy-of-xxx', {ref: conf.data.dashboard.title}),
        title: Language.get('navigation-tools-clone-dashboard'),
      }}/>
    } else {
      throw new Error(`unsupported key ${conf.key}`)
    }
  },
)

export default MainLayoutExtraAction

const TriggerContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  margin-left: 5px;
  margin-right: 5px;
  `