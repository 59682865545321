import React, {useCallback, useMemo, useRef, useState} from "react"
import {
  AdministrationLayout, BironBreadcrumb, BironTabs, ColorizedTag,
  ContentContainer,
  IconContainer,
  InformationsContainer,
  BironList,
  BironBreadCrumbTitle,
  ContentCard,
  TableLink,
  JustifyContentProperty,
} from "@biron-data/react-components";
import DetailsInformations from "../dataSourceDoc/details/Details.Informations";
import Language from "../../language";
import {useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {getCurrentMetaModel, getDataModels} from "../../redux/environment.selector";
import {DataSourceObjectType} from "../dataSourceDoc/DataSource.types";
import {useTypeRepresentationColor} from "../dataSourceDoc/DataSource.hooks";
import {getAvailables, getAvailablesForAce, getCurrentWorkspaceExpanded} from "../../redux/workspace.selector";
import {ExternalLinkIcon} from "@heroicons/react/solid";
import {buildWorkspaceUri} from "../../commons/buildWorkspaceUri";
import {filterData, Search, SearchRef} from "../search/Search";
import {ConsolidatedDimension, MetricDef} from "@biron-data/bqconf";
import {buildWorkspaceAdminWorkspaceUri} from "../../commons/buildWorkspaceAdminUri";
import {ExpandedWorkspace} from "../../redux/models/workspace";
import {ViewDto} from "@biron-data/bqconf/dist/MetaModel";
import AdminBreadcrumb from "../admin/Admin.Breadcrumb";

const DatamodelDetail = () => {
  const params = useParams()
  const {id} = params
  const getTypeRepresentationColor = useTypeRepresentationColor()
  const datamodels = useSelector(getDataModels)
  const workspacesForAce = useSelector(getAvailablesForAce)
  const workspaces = useSelector(getAvailables)
  const currentWorkspace = useSelector(getCurrentWorkspaceExpanded) as ExpandedWorkspace
  const metamodel = useSelector(getCurrentMetaModel)
  const getMetric = useCallback((viewcode: string, metricCode: string) => {
    return metamodel?.getView(viewcode)?.metrics.find(m => m.code === metricCode)
  }, [metamodel])

  const getDimension = useCallback((viewcode: string, dimensionCode: string) => {
    return metamodel?.getView(viewcode)?.dimensions.find(d => d.code === dimensionCode)
  }, [metamodel])

  const datamodel = datamodels.find(d => d.code === id)
  const searchRef = useRef<SearchRef>(null)

  const getOriginalData = useCallback(<T extends {
    code: string
  }, >(keyOfViewDto: keyof ViewDto, getter: (viewCode: string, code: string) => T | undefined) => {
    const map = new Map<string, T>();

    for (const restrictedView of datamodel?.restrictedViews ?? []) {
      for (const code of restrictedView[keyOfViewDto]) {
        const element = getter(restrictedView.code, code);
        if (element && !map.has(element.code)) {
          map.set(element.code, element);
        }
      }
    }

    return Array.from(map.values());
  }, [datamodel?.restrictedViews])

  const originalMetrics = useMemo(() => {
    return getOriginalData<MetricDef>("metricCodes", getMetric)
  }, [getMetric, getOriginalData])

  const originalDimensions = useMemo(() => {
    return getOriginalData<ConsolidatedDimension>("dimensionCodes", getDimension)
  }, [getDimension, getOriginalData])

  const [metrics, setMetrics] = useState<(MetricDef | undefined)[]>(originalMetrics)
  const [dimensions, setDimensions] = useState<(ConsolidatedDimension | undefined)[]>(originalDimensions)

  const options = useMemo(() => {
    return [...metrics.map((metric) => ({
      key: metric?.code ?? '',
      elements: [
        {
          content: <div style={{display: "flex", flexDirection: 'column'}}>
            <div style={{color: "var(--primary)"}}>{metric?.alias}</div>
            <div>{metric?.description}</div>
          </div>,
          span: 22,
        },
        {
          content: <div>
            <ColorizedTag
              text={Language.get("admin.dataDoc.details.type", DataSourceObjectType.METRIC)}
              color={getTypeRepresentationColor(DataSourceObjectType.METRIC)}/>
          </div>,
          span: 2,
          align: JustifyContentProperty.end
        }
      ],
      actions: []
    })), ...dimensions.map((dimension) => ({
      key: dimension?.code ?? '',
      elements: [
        {
          content: <div style={{display: "flex", flexDirection: 'column'}}>
            <div style={{color: "var(--primary)"}}>{dimension?.alias}</div>
            <div>{dimension?.description}</div>
          </div>,
          span: 22,
        },
        {
          content: <div>
            <ColorizedTag
              text={Language.get("admin.dataDoc.details.type", DataSourceObjectType.DIMENSION)}
              color={getTypeRepresentationColor(DataSourceObjectType.DIMENSION)}/>
          </div>,
          span: 2,
          align: JustifyContentProperty.end
        }
      ],
      actions: []
    }))]
  }, [dimensions, getTypeRepresentationColor, metrics])

  const [pagination, setPagination] = useState({
    offset: 0,
    pageSize: 150
  })

  return <AdministrationLayout breadcrumb={
    <AdminBreadcrumb
      category={{
        title: Language.get("admin.datamodels.breadcrumb.title"),
        link: 'admin/datamodels'
      }} detail={{
      title: datamodel?.alias ?? ""
    }}/>
  }>
    <InformationsContainer>
      <DetailsInformations
        description={datamodel?.description ?? ""}
        id={datamodel?.code ?? ""}/>
    </InformationsContainer>
    <ContentContainer>
      <BironTabs type="card" items={[{
        key: "1",
        label: Language.get("admin.datamodels.content.objects"),
        children: <ContentCard contentBgColor={'#F6F6F8'}>
                <Search ref={searchRef} onChange={(newValues) => {
                  setMetrics(filterData(originalMetrics, (record) => [
                    record?.alias,
                  ], newValues))
                  setDimensions(filterData(originalDimensions, (record) => [
                    record?.alias,
                  ], newValues))
                }} width={'calc(100% - 25px)'} searchedFieldKey={"admin.user.columns.user"}/>
            <BironList options={options.slice(0, pagination.pageSize + pagination.offset)} onPaginationChange={(pa) => setPagination(pa)} />
        </ContentCard>,
      }, {
        key: "2",
        label: Language.get("admin.datamodels.content.workspace"),
        children: <ContentCard contentBgColor={'#F6F6F8'}>
              <BironList options={workspaces.filter(w => w.datamodelCode === id && workspacesForAce.find(wForAce => wForAce.id === w.id)).map(w => {
                return ({
                  key: "1",
                  elements: [
                    {
                      content: <TableLink link={buildWorkspaceAdminWorkspaceUri(currentWorkspace, `${w.id}`)}>{w.name}</TableLink>,
                      span: 22,
                    },
                    {
                      content: <IconContainer key={`${w.id}-open`} clickable={true} onClick={() => {
                        window.open(buildWorkspaceUri(w), '_blank')
                      }}>
                        <ExternalLinkIcon/>
                      </IconContainer>,
                      span: 2,
                      align: JustifyContentProperty.end
                    }
                  ],
                  actions: []
                })
              })}/>
        </ContentCard>,
      }]}>
      </BironTabs>
    </ContentContainer>
  </AdministrationLayout>
}

export default DatamodelDetail
