/* eslint-disable max-lines */
import React, {memo, useMemo} from 'react'
import Language from 'language'
import styled from "styled-components"
import {Col, Row} from 'antd'
import {dayjs} from "@biron-data/period-resolver"
import {MdView, ValueDescription, IconContainer} from "@biron-data/react-components"
import {ChartDetailWithoutLayout} from 'components/widgetContainer/WidgetContainer'
import {HelpPopoverFilters} from 'components/widgets/topper/help-popover/HelpPopoverFilters'
import {
  extractSlicerDateWithSource,
  extractSlicersDimensionWithDimensionObject,
  Source,
  SlicerWithDimension
} from "@biron-data/bqconf"
import {
  dateOption,
  DimensionDescription,
} from "@biron-data/react-bqconf"
import {
  AdjustmentsIcon,
  ArrowDownIcon,
  ArrowUpIcon,
  CalendarIcon,
  ChartBarIcon,
  ClockIcon,
  FilterIcon,
  ScissorsIcon,
  SortDescendingIcon,
} from "@heroicons/react/outline"
import MetricRepresentation from "components/widgets/topper/help-popover/MetricRepresentation"
import {ColorizedSourceItem} from "components/colorizedSourceItem/ColorizedSourceItem"
import {EffectiveConfTypes} from "types/charts"
import {
  extractFiltersFromEffectiveConf,
  extractMetricFiltersFromEffectiveConf,
  extractOrderByFromEffectiveConf, extractSlicersFromEffectiveConf,
} from "commons/configuration"
import {useDataDocDimensionLink} from "components/dataSourceDoc/DataSource.hooks"

interface Props {
  effectiveConf: EffectiveConfTypes,
  chart: ChartDetailWithoutLayout
}

const HelpPopoverBody = memo<Props>(function HelpPopoverBody({effectiveConf}) {
  const {timeInterval, metrics} = effectiveConf

  const filters = useMemo(() => extractFiltersFromEffectiveConf(effectiveConf), [effectiveConf])
  const metricFilters = useMemo(() => extractMetricFiltersFromEffectiveConf(effectiveConf), [effectiveConf])


  const slicers = useMemo(() => extractSlicersFromEffectiveConf(effectiveConf) ?? [], [effectiveConf])

  const dateSlicer = useMemo(() => slicers ? extractSlicerDateWithSource(slicers) : undefined, [slicers])

  const dimensionSlicers: SlicerWithDimension[] = useMemo(() => slicers ? extractSlicersDimensionWithDimensionObject(slicers) : [], [slicers])

  const orderBys = useMemo(() => extractOrderByFromEffectiveConf(effectiveConf), [effectiveConf])

  const consolidatedOrderBys = useMemo(() => orderBys.map(orderBy => ({
    value: [
      ...slicers.map(slicer => {
        switch (slicer.type) {
          case "dimension":
            return ({
              code: slicer.dimensionCode,
              label: slicer.dimension.alias,
              description: slicer.dimension.description,
            })
          case "date":
            return {
              code: dateOption.label,
              label: dateOption.label,
              description: dateOption.label,
            }
          default: {
            const exhaustiveCheck: never = slicer
            return exhaustiveCheck
          }
        }
      }),
      ...metrics.map(metric => ({
        code: metric.metricCode,
        label: metric.metricAlias,
        description: metric.metricDef.description,
      })),
    ][orderBy.column],
    asc: orderBy.asc,
  })), [orderBys, metrics, slicers])

  const getDatadocLink = useDataDocDimensionLink()

  return <div>
    <Section>
      <LeadingRows>
        <Row>
          <Col span={6}>
            <FlexDiv>
              <IconContainer margin={10}><CalendarIcon/></IconContainer>
              {Language.get('chart-definition-period')}
            </FlexDiv>
          </Col>
          <Col span={18}>
            <ColorizedSourceItem value={timeInterval}>
              {timeInterval.period.code && (
                <React.Fragment>{Language.getTranslatedName(timeInterval.period)} : </React.Fragment>
              )}
              {dayjs(timeInterval.start, 'YYYY-MM-DD').format('L')} ⇀ {dayjs(timeInterval.end, 'YYYY-MM-DD').format('L')}
            </ColorizedSourceItem>
          </Col>
        </Row>
        {
          dateSlicer && dateSlicer.granularity && <Row>
            <Col span={6}>
              <FlexDiv>
                <IconContainer margin={10}><ClockIcon/></IconContainer>
                {Language.get("granularity")}
              </FlexDiv>
            </Col>
            <Col span={18}>
              <ColorizedSourceItem value={dateSlicer}>
                <React.Fragment>{`${Language.get("by")} ${Language.get(`calendar-granularity-${dateSlicer.granularity.toLowerCase()}`)}`}</React.Fragment>
              </ColorizedSourceItem>
            </Col>
          </Row>
        }
      </LeadingRows>
    </Section>
    <Section>
      <Row>
        <Col span={6}>
          <FlexDiv>
            <IconContainer margin={10}><ChartBarIcon/></IconContainer>
            {Language.get('chart-definition-metrics')}
          </FlexDiv>
        </Col>
        <Col span={18}>
          {metrics.map((metric, index) => <MetricRepresentation key={index} {...{metric, timeInterval: effectiveConf.timeInterval}}/>)}
        </Col>
      </Row>
    </Section>
    {filters && filters.length > 0 && <Section>
      <Row>
        <Col span={6}>
          <FlexDiv>
            <IconContainer margin={10}><FilterIcon/></IconContainer>
            {Language.get("chart-definition-filters.title")}
          </FlexDiv>
        </Col>
        <Col span={18}>
          <HelpPopoverFilters filters={filters}/>
        </Col>
      </Row>
    </Section>}
    {metricFilters && metricFilters.length > 0 && <Section>
      <Row>
        <Col span={6}>
          <FlexDiv>
            <IconContainer margin={10}><AdjustmentsIcon/></IconContainer>
            {Language.get("configuration-label-metrics-filters")}
          </FlexDiv>
        </Col>
        <Col span={18}>
          <HelpPopoverFilters filters={metricFilters}/>
        </Col>
      </Row>
    </Section>}
    {dimensionSlicers.length > 0 && <Section>
      <Row>
        <Col span={6}>
          <FlexDiv>
            <IconContainer margin={10}><ScissorsIcon/></IconContainer>
            {Language.get("chart-definition-slicers")}
          </FlexDiv>
        </Col>
        <Col span={18}>
          {dimensionSlicers && dimensionSlicers.map(slicer => slicer && <Row key={slicer.dimension.alias}>
            <Col span={24}>
              <FlexDiv>
                <ImportantText translate="no">{slicer.dimension.alias}</ImportantText>
                <StyledDimensionDescription dimension={slicer.dimension} getMoreInformationLink={getDatadocLink}/>
              </FlexDiv>
            </Col>
          </Row>)}
        </Col>
      </Row>
    </Section>}
    {consolidatedOrderBys && consolidatedOrderBys.length > 0 && <Section>
      <Row>
        <Col span={6}>
          <FlexDiv>
            <IconContainer margin={10}><SortDescendingIcon/></IconContainer>
            {Language.get('chart-definition-sort')}
          </FlexDiv>
        </Col>
        <Col span={18}>
          {consolidatedOrderBys.map(sort => <Row key={sort.value.code}>
              <Col span={14}>
                <ImportantText translate="no">{sort.value.label}</ImportantText>
                <DivWithRightMargin>
                  {sort.value.description && <ValueDescription description={sort.value.description}/>}
                </DivWithRightMargin>
              </Col>
              <Col span={8}>
                <FlexDiv>
                  <IconContainer>{sort.asc ? <ArrowUpIcon/> : <ArrowDownIcon/>}</IconContainer>
                  {Language.get(`configuration-sort-types.${sort.asc ? "asc" : "desc"}`)}
                </FlexDiv>
              </Col>
            </Row>,
          )}
        </Col>
      </Row>
    </Section>}
    <SourceLegend>
      <ColorizedSourceItem
        value={{source: Source.CHART}}>{Language.get('chart-definition-from-chart')}</ColorizedSourceItem>, {Language.get("chart-definition-from-dashboard")}
    </SourceLegend>
    <DefinitionLink><MdView description={Language.get('configuration-footer-help')}/></DefinitionLink>
  </div>
})

export default HelpPopoverBody

const FlexDiv = styled.div`
    display: flex;
    align-items: center;
`

export const StyledDimensionDescription = styled(DimensionDescription)`
    color: rgba(0, 0, 0, 0.3);
`

const LeadingRows = styled(Row)`
    display: flex;
    flex-direction: column;
`

export const DescriptionCol = styled(Col)`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    gap: 4px;
`

const ImportantText = styled.span`
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 104.68%;
    color: var(--light-text);
    margin-right: 4px;
`

const DivWithRightMargin = styled.div`
    display: inline-block;
    margin-right: 10px;
`

const SourceLegend = styled.div`
    margin-right: 16px;
    text-align: right;
    font-size: 12px;
    color: var(--light-text);
`

const DefinitionLink = styled.div`
    padding: 12px 16px;

    p {
        margin-bottom: 0;
    }

    a {
        color: var(--primary)
    }
`

const Section = styled.div`
    padding: 16px 16px 16px 16px;
    border-bottom: 1px solid var(--border-color-base);
    line-height: 20px;
`
