import {WorkspaceRoles} from "./UserManager.types";
import {Tooltip} from "antd";
import {IconContainer} from "@biron-data/react-components";
import React, {ReactNode} from "react";
import {
  HandIcon,
  CalendarIcon,
  ChartBarIcon,
  EyeIcon,
  FilterIcon,
  FolderIcon,
  KeyIcon,
  PlusCircleIcon, UsersIcon
} from "@heroicons/react/outline";
import styled from "styled-components";
import Language from "../../../language";
import {TooltipPlacement} from "antd/es/tooltip";

export const wrapWithTooltip = (
  element: ReactNode,
  role: WorkspaceRoles,
  summary: string,
  details: ReactNode[],
  placement: TooltipPlacement) => {
  return <Tooltip {...{
    placement,
    title: <WorkspaceRightsTooltip>
      <RoleContainer>{Language.get(`admin.user.roles.${role}`)}</RoleContainer>
      <Required><strong>{Language.get("admin.user.tooltips.required.title")}</strong> : {Language.get("admin.user.tooltips.required.description")}</Required>
      <Summary>{summary}</Summary>
      {details.map((detail, i) => {
        return <DetailLine key={i} $includeBorder={i !== 0}>
        {detail}
        </DetailLine>
      })}
      <br/>
      <div>
        <FlexDiv><PrimaryDiv>*</PrimaryDiv> {Language.get("admin.user.tooltips.footer.oneStar")}</FlexDiv>
        <FlexDiv><PrimaryDiv>**</PrimaryDiv> {Language.get("admin.user.tooltips.footer.twoStar")}</FlexDiv>
      </div>
    </WorkspaceRightsTooltip>,
    mouseEnterDelay: 0.5,
    arrow: {pointAtCenter: true},
    autoAdjustOverflow: true,
  }}>{
    element
  }</Tooltip>
}
const RoleContainer = styled.div`
    display: flex;
    padding-bottom: 10px;
gap: 10px;
    font-size: 19px;
font-weight: 500;
    justify-content: center;
    width: 100%;
`
const WorkspaceRightsTooltip = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px;
    gap: 10px;
`

const DetailLine = styled.div<{$includeBorder: boolean}>`
    ${({$includeBorder}) => $includeBorder ? 'border-top: 1px solid var(--border-color-base);' : ''}
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
`

const PrimaryDiv = styled.div`
    color: var(--primary);
`
const Required = styled.div`
    font-weight: 600;
    font-size: 12px;
    line-height: 23px;
    text-align: center;
    vertical-align: middle;
    color: var(--primary);
`
const Summary = styled.div`
    font-weight: 400;
    font-style: italic;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    vertical-align: middle;
`
const FlexDiv = styled.div`
  
  display: flex;
  flex-direction: row;   
  align-items: center;
  gap: 10px;
`
export const workspaceReaderDetails = <>
    <FlexDiv><IconContainer><KeyIcon/></IconContainer>{Language.get("admin.user.tooltips.reader.key")}</FlexDiv>
    <FlexDiv><IconContainer><EyeIcon/></IconContainer>{Language.get("admin.user.tooltips.reader.eye")}</FlexDiv>
    <FlexDiv><IconContainer><CalendarIcon/></IconContainer>{Language.get("admin.user.tooltips.reader.calendar")}<PrimaryDiv>*</PrimaryDiv></FlexDiv>
    <FlexDiv><IconContainer><FilterIcon/></IconContainer>{Language.get("admin.user.tooltips.reader.filter")}<PrimaryDiv>**</PrimaryDiv></FlexDiv>
  </>

export const workspaceWriterDetails = <>
  <FlexDiv><IconContainer><PlusCircleIcon/></IconContainer>{Language.get("admin.user.tooltips.reader.key")}</FlexDiv>
  <FlexDiv><IconContainer><ChartBarIcon/></IconContainer>{Language.get("admin.user.tooltips.reader.eye")}</FlexDiv>
  <FlexDiv><IconContainer><FolderIcon/></IconContainer>{Language.get("admin.user.tooltips.reader.calendar")}<PrimaryDiv>*</PrimaryDiv></FlexDiv>
  <FlexDiv><IconContainer><HandIcon/></IconContainer>{Language.get("admin.user.tooltips.reader.filter")}<PrimaryDiv>**</PrimaryDiv></FlexDiv>
</>

export const workspaceManagerDetails = <>
  <FlexDiv><IconContainer><UsersIcon/></IconContainer>{Language.get("admin.user.tooltips.manager.users")}</FlexDiv>
</>

