import {useCallback, useMemo} from "react";
import useHandleUserAcesModification from "../../../hooks/useHandleUserAcesModification";
import {UACWithAces} from "../../../hooks/useUACWithAces";
import {DatamodelDto} from "@biron-data/bqconf";
import {ClientWorkspace} from "../../../redux/models/appEnvironment";
import {NormalizedWorkspaceDetail} from "../../../schemas/workspace";
import {ACES, DatamodelRoles, WorkspaceRoles} from "./UserManager.types";
import {isDatamodelRole, isWorkspaceRole} from "./UserManager.utils";

export const useDatamodelsWithWorkspace = (
  datamodels: DatamodelDto[],
  uac: UACWithAces | undefined,
  availableWorkspacesForAce: Pick<ClientWorkspace, "id" | "name">[],
  workspaceswithDatamodelCode: Pick<NormalizedWorkspaceDetail, "id" | "datamodelCode">[]
  ) => {
  return useMemo(() => datamodels.map(datamodel => ({
    ...datamodel,
    roles: uac?.user.aces.find(e => e.target.split(":")[e.target.split(":").length - 1] === datamodel.code)?.roles,
    workspaces: availableWorkspacesForAce.filter(availableWorkspaceForAce => workspaceswithDatamodelCode.find(workspacewithDatamodelCode => workspacewithDatamodelCode.id === availableWorkspaceForAce.id)?.datamodelCode === datamodel.code).map(w => ({
      ...w,
      roles: uac?.user.aces.find(e => Number(e.target.split(":")[e.target.split(":").length - 1]) === w.id)?.roles,
    }))
  })), [datamodels, uac?.user, availableWorkspacesForAce, workspaceswithDatamodelCode])
}

export const useUpdateWorkspaceRights = (uac: UACWithAces | undefined) => {
  const handleOnConfirm = useHandleUserAcesModification()

  return useCallback((newRoles: WorkspaceRoles[], workspaceId: number) => {
    if (uac) {
      return handleOnConfirm(uac, {
        [`workspace:${workspaceId}`]: newRoles
      })
    }
    return undefined
  }, [handleOnConfirm, uac])
}

export const useUpdateDatamodelRights = (uac: UACWithAces | undefined, environmentId: number, workspaceswithDatamodelCode: NormalizedWorkspaceDetail[]) => {
  const handleOnConfirm = useHandleUserAcesModification()

  return useCallback((newRoles: DatamodelRoles[], datamodelCode: string) => {
    if (uac) {
      const workspaceIdsWithModifiedDatamodelCode = workspaceswithDatamodelCode.filter(w => w.datamodelCode === datamodelCode).map(w => `${w.id}`)
      return handleOnConfirm({
        ...uac,
        user: {
          ...uac.user,
          aces: uac.user.aces.map(ace => {
            if (workspaceIdsWithModifiedDatamodelCode.includes(ace.targetId)) {
              if (newRoles.length === 0){
                return {
                  ...ace,
                  roles: []
                }
              }else if (newRoles.includes(DatamodelRoles.DATAMODEL_VIEWER)) {
                if (newRoles.includes(DatamodelRoles.DATAMODEL_EXPLORER)) {
                  return ace
                } else {
                  return {
                    ...ace,
                    roles: ace.roles.filter((role) => role !== WorkspaceRoles.WORKSPACE_MANAGER
                      && role !== WorkspaceRoles.WORKSPACE_WRITER)
                  }
                }
              }
              return ace
            }
            return ace
          })
        }
      }, {
        [`datamodel:${environmentId}:${datamodelCode}`]: newRoles
      })
    }
    return undefined
  }, [environmentId, handleOnConfirm, uac, workspaceswithDatamodelCode])
}

export const useGetWorkspaceRolesSynthesis = () => useCallback((aces: ACES[]) =>
  [...new Set(aces.flatMap(e => e.roles.filter(isWorkspaceRole)))]
, [])


export const useGetDatamodelRolesSynthesis = () => useCallback((aces: ACES[]) =>
  [...new Set(aces.flatMap(e => e.roles.filter(isDatamodelRole)))]
  , [])