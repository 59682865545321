import {isSlicerDate, SlicerDtoDetailTypes} from "@biron-data/bqconf"
import {isEmpty} from "@biron-data/react-components"
import {ChartDividerDtoDetail, ChartGenericDtoDetail, ChartTargetDtoDetail} from "types/charts"
import {WidgetTypes} from "commons/dashboard/dashboard.types"
import {PeriodTypes} from "@biron-data/period-resolver"

export const getWhenGeneric = (chart: Omit<ChartGenericDtoDetail, "x" | "y" | "h" | "w" | "extraConf" | "title" | "viewCode">
  | Omit<ChartTargetDtoDetail, "x" | "y" | "h" | "w" | "extraConf" | "metricCode" | "metricAlias" | "title" | "viewCode">
  | Omit<ChartDividerDtoDetail, "x" | "y" | "h" | "w" | "extraConf" | "title">) => {
  if (chart.type === WidgetTypes.GENERIC) {
    return chart
  } else {
    return undefined
  }
}

export const isPeriodOverridden = (chart: { period?: PeriodTypes, slicers: SlicerDtoDetailTypes[] } | undefined) => chart && (chart.period !== undefined
  || chart.slicers.filter(isSlicerDate).filter(slicer => !isEmpty(slicer.granularity)).length > 0)