import {IconContainer, TooltipPopover} from "@biron-data/react-components"
import {BookmarkIcon, DocumentIcon, PlusIcon} from "@heroicons/react/outline"
import React, {forwardRef, useImperativeHandle, useMemo, useState} from "react"
import {getPersonalDashboards} from "redux/personalDashboard.selector"
import {useSelector} from "react-redux"
import {Folder} from "types/dashboards"
import useDispatch from "hooks/useDispatch"
import {useNavigate} from "react-router-dom"
import {getCurrentEnvironmentId} from "redux/environment.selector"
import {NormalizedDashboardTypes} from "schemas/dashboard"
import {
  useCurrentUid, useDashboardsSort, useDefaultRedirect,
  useGetDraftCategory, useLastSevenDaysDrafts, useLastThirtyDaysDrafts, useLastUpdatedDraft, useMenuAutomaticOpening, useOlderDrafts,
  useRestore, useStarredDrafts,
  useTodayDrafts, useTrashedDrafts,
} from "components/mainLayout/menu/PersonalDashboardMenu.hooks"
import {
  Container,
  DashboardTitle,
  FlexContainer,
  StyledMenu,
  StyledMenuWithTopBorder,
} from "components/mainLayout/menu/PersonalDashboardMenu.style"
import AnimateHeight from "react-animate-height"
import Language from "language"
import {getCurrentWorkspaceDatamodel} from "../../../redux/workspace.selector";

interface PersonalDashboardMenuProps {
  workspaceUriBuilder: (pathOrDashboard?: string | NormalizedDashboardTypes) => string
}

export interface PersonalDashboardMenuRef {
  close: () => void
}

// eslint-disable-next-line react/display-name
const PersonalDashboardMenu = forwardRef<PersonalDashboardMenuRef, PersonalDashboardMenuProps>(({workspaceUriBuilder}, ref) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const environmentId = useSelector(getCurrentEnvironmentId)
  const datamodel = useSelector(getCurrentWorkspaceDatamodel)
  const personalDashboards = useSelector(getPersonalDashboards)

  const currentUid = useCurrentUid()
  const sortDashboards = useDashboardsSort()
  const draftDashboards = useMemo(() => personalDashboards.filter(personalDashboard => personalDashboard.folder === Folder.DRAFT).sort(sortDashboards), [personalDashboards, sortDashboards])
  const starredDashboards = useMemo(() => personalDashboards.filter(personalDashboard => personalDashboard.folder === Folder.STARRED).sort(sortDashboards), [personalDashboards, sortDashboards])
  const trashedDashboards = useMemo(() => personalDashboards.filter(personalDashboard => personalDashboard.folder === Folder.TRASH).sort(sortDashboards), [personalDashboards, sortDashboards])

  const [openDraftKeys, setOpenDraftKeys] = useState<string[]>([])

  useImperativeHandle(ref, () => ({
    close: () => setOpenDraftKeys([]),
  }), [])

  useMenuAutomaticOpening(currentUid, draftDashboards, starredDashboards, trashedDashboards, setOpenDraftKeys)
  const redirect = useDefaultRedirect(
    currentUid,
    environmentId,
    draftDashboards,
    navigate,
    workspaceUriBuilder,
    sortDashboards
  )
  const restore = useRestore(dispatch.personalDashboards.updatePersonalDashboardFolder)

  const getDraftCategory = useGetDraftCategory(draftDashboards, dispatch.personalDashboards.updatePersonalDashboardFolder, restore, redirect)
  const todayDrafts = useTodayDrafts(getDraftCategory)
  const lastSevenDaysDrafts = useLastSevenDaysDrafts(getDraftCategory)
  const lastThirtyDaysDrafts = useLastThirtyDaysDrafts(getDraftCategory)
  const olderDrafts = useOlderDrafts(getDraftCategory)

  const starredDrafts = useStarredDrafts(starredDashboards, dispatch.personalDashboards.updatePersonalDashboardFolder)
  const trashedDrafts = useTrashedDrafts(trashedDashboards, restore)

  const lastModifiedDraft = useLastUpdatedDraft(currentUid, environmentId)

  return <Container>
    <StyledMenuWithTopBorder
      theme={"biron" as any /* Menu types does not register additional themes */}
      mode="inline"
      inlineIndent={16}
      subMenuOpenDelay={.5}
      triggerSubMenuAction={"click"}
      selectedKeys={[currentUid]}
      openKeys={openDraftKeys}
      expandIcon={(props: any) => {
        if (props.eventKey === "trash") {
          return <i className="ant-menu-submenu-arrow"></i>
        }
        return <i className="ant-menu-submenu-arrow" style={{transform: props.isOpen ? 'rotate(180deg) translateY(-2px)' : 'rotate(180deg)', marginRight: 2}}></i>
      }}
      onOpenChange={(keys) => {
        setOpenDraftKeys(keys)
      }}
      onClick={(e) => {
        if (e.key === "draft") {
          return
        }
        navigate(`/app/personal/${environmentId}/${e.key}`)
      }}
      items={[
        {
          key: 'draft',
          label: <FlexContainer>
            {Language.get(`personal-dashboard.title`)}
            <TooltipPopover size={'small'} mouseEnterDelay={0.4} content={Language.get('personal-dashboard.tooltip.add')}>
              <FlexContainer><IconContainer clickable={true} onClick={async (e) => {
                e.stopPropagation()
                if (datamodel?.code) {
                  const dashboard = await dispatch.personalDashboards.addPersonalDashboard(datamodel.code)
                  navigate(`/app/personal/${environmentId}/${dashboard.uid}`)
                }
              }}><PlusIcon/></IconContainer>
              </FlexContainer>
            </TooltipPopover>
          </FlexContainer>,
          children: [
            ...starredDrafts,
            ...todayDrafts,
            ...lastSevenDaysDrafts,
            ...lastThirtyDaysDrafts,
            ...olderDrafts,
            ...trashedDrafts,
          ],
        },
      ]}
    />
    <AnimateHeight
      duration={500}
      height={openDraftKeys.find(key => key === "draft") ? 0 : 'auto'}
    >
      <StyledMenu
        theme={"biron" as any /* Menu types does not register additional themes */}
        mode="inline"
        inlineIndent={16}
        selectedKeys={[currentUid]}
        subMenuOpenDelay={.5}
        onClick={(e) => {
          if (lastModifiedDraft) {
            navigate(`/app/personal/${lastModifiedDraft.environmentId}/${e.key}`)
          }
        }}
        items={lastModifiedDraft?.title ? [
          {
            key: lastModifiedDraft.uid,
            label: <FlexContainer>
              <DashboardTitle>{lastModifiedDraft.title}</DashboardTitle>
            </FlexContainer>,
            icon: <IconContainer size={14} margin={10}>
              {lastModifiedDraft.folder === Folder.STARRED ? <BookmarkIcon/> : <DocumentIcon/>}
            </IconContainer>,
          },
        ] : []}
      />
    </AnimateHeight>
  </Container>
})

export default PersonalDashboardMenu
