import React, {useMemo, useRef, useState} from 'react'
import WidgetTopper from 'components/widgets/topper/WidgetTopper'
import styled from "styled-components"
import {
  ChartDetailWithoutLayout,
  ChartWithMetricDefLayoutTypes,
  OnSelectionChange,
  OnSnapshot,
} from 'components/widgetContainer/WidgetContainer'
import {textColor} from "components/charts/Chart.Theme"
import {CHART_PADDING_X, CHART_PADDING_Y, DIVIDER_BORDER_TOP} from "components/charts/Chart.constants"
import {isEmpty} from "@biron-data/react-components"
import {ChartSelection} from "classes/workflows/query-workflows/QueryWorkflow"
import EditableTitle from "components/editableTitle/EditableTitle"
import {Styles} from "components/forms/confItems/StylesSelector"
import {UseGetChartLayoutForAddType} from "hooks/useGetLayoutForAddChart"
import {ColorName, GenericChartTypes, strToColor} from "@biron-data/bqconf"
import {EffectiveConfTypes} from "types/charts"
import {WidgetTypes} from "commons/dashboard/dashboard.types"
import {TitleGenerator} from "components/charts/Chart.TitleGenerator"
import {useSelector} from "react-redux"
import {getPermission} from "redux/appEnvironment.selector"
import {PermissionsEnum} from "redux/models/appEnvironment"
import {captureEvent} from "services/GoogleAnalyticsService"
import {useResizeDetector} from "@biron-data/react-hooks"

interface Props {
  chart: ChartWithMetricDefLayoutTypes,
  isLinkedTo?: boolean,
  editable?: boolean,
  selection?: ChartSelection,
  meta?: {
    effectiveConf: EffectiveConfTypes
  },
  getBqlRequest: () => void
  onSelectionChange: OnSelectionChange
  onDownload?: () => any
  onConfirm: (newConf: ChartDetailWithoutLayout) => Promise<any>
  onSnapshot?: OnSnapshot
  onConfEdit?: () => any
  onLinkCopy: () => any
  onClose?: () => Promise<void>
  getChartLayoutForAdd: ReturnType<UseGetChartLayoutForAddType>
  scrollToChart: (chartId: number) => void
  handleChartDuplicate: () => void,
  onConfCopy: () => void,
}

const availableSnapshotWidgetType: GenericChartTypes[] = [
  GenericChartTypes.LINE,
  GenericChartTypes.AREA,
  GenericChartTypes.BARS,
  GenericChartTypes.PIE,
  GenericChartTypes.SCATTER,
]

const WidgetHeader: (props: Props) => JSX.Element = ({
                                                       chart,
                                                       editable,
                                                       selection,
                                                       meta,
                                                       getBqlRequest,
                                                       onSelectionChange,
                                                       onDownload,
                                                       onConfirm,
                                                       onSnapshot,
                                                       onConfEdit,
                                                       onLinkCopy,
                                                       onClose,
                                                       isLinkedTo,
                                                       getChartLayoutForAdd,
                                                       scrollToChart,
                                                       handleChartDuplicate,
                                                       onConfCopy,
                                                     }) => {
  const {type} = chart
  const {cssClass, style} = chart.extraConf
  const canGenerateAiTitle = useSelector(getPermission)(PermissionsEnum.chartCanGenerateTitle)

  const hasColoredHeader = useMemo(() => !isEmpty(cssClass), [cssClass])

  const isFilled = useMemo(() => {
    if (style === undefined) {
      return cssClass?.includes("border") ? 0 : 1
    }
    return style === Styles.filled ? 1 : 0
  }, [cssClass, style])

  const iconColor = useMemo(() => isFilled && hasColoredHeader ? "white" : undefined, [hasColoredHeader, isFilled])

  const widgetTopperRef = useRef<any>()
  const [widgetTopperDimension, setWidgetTopperDimension] = useState({
    width: 0,
    height: 0,
  })

  useResizeDetector(widgetTopperRef, widgetTopperDimension, (newWidth, newHeight) => {
    setWidgetTopperDimension({
      width: newWidth,
      height: newHeight,
    })
  })

  return <StyledDivHeader $islinkedto={isLinkedTo ? 1 : 0} $cssClass={cssClass} $isfilled={isFilled} $canmove={editable ? 1 : 0}>
    <EditableTitle
      title={chart.title}
      onConfirm={(newTitle) => onConfirm({
        ...chart,
        title: newTitle
      })}
      editable={editable}
      iconColor={iconColor}
      triggerGAEvent={() => captureEvent({
        category: 'charts',
        action: 'dashboard_chart_title_update',
        widgetType: chart?.type,
      })}
      width={`calc(100% - ${widgetTopperDimension.width}px)`}>
      {canGenerateAiTitle && editable && [WidgetTypes.TARGET, WidgetTypes.GENERIC].includes(chart.type) && <TitleGenerator chart={chart} iconColor={iconColor} onConfirm={onConfirm}/>}
    </EditableTitle>
    {selection && <WidgetTopperContainer ref={widgetTopperRef}><WidgetTopper {...{
      chart,
      widgetType: type,
      selection,
      onSelectionChange,
      effectiveConf: meta?.effectiveConf,
      getBqlRequest,
      onDownload: meta && meta.effectiveConf.type === 'generic' ? onDownload : undefined,
      onSnapshot: chart.extraConf.displayType && availableSnapshotWidgetType.includes(chart.extraConf.displayType) ? onSnapshot : undefined,
      onLinkCopy,
      onConfEdit,
      onClose,
      iconColor,
      isLinkedTo,
      getChartLayoutForAdd,
      scrollToChart,
      editable,
      onConfCopy,
      handleChartDuplicate,
    }}
    /></WidgetTopperContainer>}
  </StyledDivHeader>
}

export default WidgetHeader

const WidgetTopperContainer = styled.div``

const StyledDivHeader = styled.div<{ $islinkedto?: number, $cssClass?: ColorName, $isfilled?: number, $canmove?: number }>`
  @keyframes highlight {
    from {
      background-color: none;
    }
    to {
      background-color: rgba(0, 123, 195, 0.4);
    }
  }
  display: flex;
  display: webkit-flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-items: stretch;
  align-items: stretch;
  -webkit-align-content: stretch;
  align-content: stretch;

  padding: ${props => {
  if (!isEmpty(props.$cssClass)) {
    if (props.$isfilled) {
      return `${CHART_PADDING_Y}px ${CHART_PADDING_X}px ${CHART_PADDING_Y}px ${CHART_PADDING_X}px`
    } else {
      return `${CHART_PADDING_Y - DIVIDER_BORDER_TOP}px ${CHART_PADDING_X}px ${CHART_PADDING_Y}px ${CHART_PADDING_X}px`
    }
  }
  return `${CHART_PADDING_Y}px ${CHART_PADDING_X}px 0 ${CHART_PADDING_X}px`
}};

  ${props => {
  if (props.$cssClass) {
    if (props.$isfilled) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return `background-color: ${strToColor.get(props.$cssClass!)}; color: ${textColor.get(props.$cssClass!)};`
    } else {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return `border-top: ${DIVIDER_BORDER_TOP}px solid ${strToColor.get(props.$cssClass!)}; color: ${strToColor.get(props.$cssClass!)}; background-color: white;`
    }
  }
  return ""
}};

  flex-shrink: 0;

    ${props => props.$islinkedto && "animation: 3s linear 2 alternate highlight;"};

    ${props => {
        if (props.$canmove) {
            return `&:not(i):not(.disable-grab) {
        cursor: grab;
      }
      
      &:not(i):not(.disable-grab):active {
        cursor: grabbing;
      }`
        }
        return ``
    }}
`
