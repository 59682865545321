import {Row} from "antd"
import React, {memo} from "react"
import {DescriptionCol} from "components/widgets/topper/help-popover/HelpPopoverBody"
import {EffectiveConfMetric} from "components/charts/line/LineChart.types"
import styled from "styled-components"
import {MetricDescription, MetricOptionsResume, MetricDisplay, ViewDescription} from "@biron-data/react-bqconf"
import {TimeIntervalWithSource} from "@biron-data/bqconf"
import {useDayDifference} from "@biron-data/period-resolver"
import {useDataDocMetricLink, useDataDocViewLink} from "components/dataSourceDoc/DataSource.hooks"

interface Props {
  metric: EffectiveConfMetric
  timeInterval: TimeIntervalWithSource
}

// eslint-disable-next-line react/display-name
const MetricRepresentation = memo<Props>(({metric, timeInterval}) => {
  const dayDifference = useDayDifference(timeInterval)

  const getDatadocViewLink = useDataDocViewLink()
  const getMetricDatadocLink = useDataDocMetricLink()
  return <div>
    <Row>
      <DescriptionCol span={24}>
        <span translate="no">{metric.viewAlias}</span>
        <StyledViewDescription description={metric.view.description} title={metric.view.alias} code={metric.view.code} getMoreInformationLink={getDatadocViewLink}/>
        <span>{" - "}</span>
        <ImportantText>
          <MetricDisplay metric={metric}/>
        </ImportantText>
        <MetricDescriptionWithoutLeftMargin metric={metric} timeInterval={timeInterval} getMoreInformationLink={getMetricDatadocLink}/>
      </DescriptionCol>
    </Row>
    <MetricResumeContainer>
      <MetricOptionsResume iconSize={14} value={metric} timeInterval={timeInterval} dayDifference={dayDifference}/>
    </MetricResumeContainer>
  </div>
})

export default MetricRepresentation

export const StyledViewDescription = styled(ViewDescription)`
  color: rgba(0, 0, 0, 0.3);
`
export const StyledMetricDescription = styled(MetricDescription)`
  color: rgba(0, 0, 0, 0.3);
`

const MetricDescriptionWithoutLeftMargin = styled(StyledMetricDescription)`
    margin-left: 0 !important;
`

const MetricResumeContainer = styled.div`
    margin-left: 20px;
    width: 70%;
`

const ImportantText = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 104.68%;
  color: var(--light-text);
  margin-right: 4px;
`
