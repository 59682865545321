import React, {memo, useEffect, useMemo} from 'react'
import {useSelector} from "react-redux"
import {getCurrentMetaModel} from "redux/environment.selector"
import {useLocation, useParams} from "react-router-dom"
import useDispatch from "hooks/useDispatch"
import {MetaModel} from "@biron-data/bqconf"
import PersonalDashboardContainer from "components/personalDashboard/PersonalDashboard.Container"


const PersonalDashboardBridge = memo<any>(function PersonalDashboardBridge() {
  const params = useParams()
  const {uid, environmentId} = params
  const dispatch = useDispatch()
  const metaModel = useSelector(getCurrentMetaModel) as MetaModel
  const location = useLocation()
  // the location object change each time a user (re-)navigate on a dashboard
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const refreshTimestamp = useMemo(() => Date.now(), [location])

  useEffect(() => {
    if (uid && !Number.isNaN(environmentId)) {
      dispatch.lastEditedDashboard.update({
        uid,
        environmentId: Number(environmentId),
      })
      dispatch.currentDashboard.setFromUid({
        uid,
        timestamp: refreshTimestamp,
        chartHashLink: location.hash,
        environmentId: Number(environmentId),
      })
    }
    return () => {
      dispatch.currentDashboard.clear()
    }
  }, [dispatch, refreshTimestamp, location, uid, environmentId])


  if (uid) {
    return <PersonalDashboardContainer metaModel={metaModel}/>
  } else {
    return null
  }
})

export default PersonalDashboardBridge
