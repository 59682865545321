import {SerieInfo} from "components/charts/Chart.utils"
import {GenericEffectiveConf, Param, SerieType} from "components/charts/line/LineChart.types"
import {Hoverdata} from "components/charts/bar/BarChart"
import {asArray} from "commons/asArray"
import {tooltipBaseParameters, tooltipFormatter} from "components/charts/Chart.tooltip"
import Tooltip = echarts.EChartOption.Tooltip
import {Format} from "@biron-data/bqconf"

export const lineChartTooltip: (yAxisLength: number, renderedSeries: Pick<SerieInfo<SerieType>, "format" | "axisIndex">[], effectiveConf: Pick<GenericEffectiveConf, "slicers" | "metrics">, formats: Format[], alternativeFormats: Format[], hoverData?: Hoverdata) => Tooltip = (yAxisLength, renderedSeries, effectiveConf, formats, alternativeFormats, hoverData) => ({
  ...tooltipBaseParameters(),
  formatter: (params) => {
    const result = asArray(params) as Param[]
    return tooltipFormatter(yAxisLength, renderedSeries, effectiveConf, formats, alternativeFormats, result, undefined, hoverData)
  },
})
